import React, { useEffect, useReducer, useState } from "react";
import ButtonWithLoader from "../../../UI/ButtonWithLoader/ButtonWithLoader";
import Modal from "../../../UI/Modal/Modal";
import ModalBodyComponent from "../../../UI/Modal/ModalBodyComponent";
import Preloader from "../../../UI/Preloader/Preloader";
import ReviewListItemModal from "../../../UI/ReviewersList/ReviewListItemModal";
import {
  ApproveArticleFromModal,
  GetLastRoundReviewsList,
} from "../../../endpoints";
import {
  changeArticleStatus,
  errorToast,
  successToast,
} from "../../../UI/HelperFunctions/HelperFunctions";
import { ArticleStatus } from "../../../Enums";

function getCurrentRound(status) {
  if (status == ArticleStatus.ReviewedR1) return "1";
  if (status == ArticleStatus.ReviewedR2) return "2";
  if (status == ArticleStatus.ReviewedR3) return "3";
}

function reviewsOfLastRoundDispatcher(state, action) {
  if (action.type === "INITIAL_STATE") {
    return action.data;
  }
  if (action.type === "EDIT_REVIEW_STATUS") {
    const findEditedField = state.find((el) => el.id === action.id);
    if (findEditedField) {
      const indexOfEditedField = state.indexOf(findEditedField);
      const editedObj = state[indexOfEditedField];
      editedObj.statusId = action.reviewStatus;
      return [...state];
    }
  }
  return state;
}

function ApproveReviewsButton({ setData, status, articleId }) {
  const [showModal, setShowModal] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [reviewsOfLastRound, dispatchReviewsOfLastRound] = useReducer(
    reviewsOfLastRoundDispatcher,
    []
  );

  useEffect(() => {
    GetLastRoundReviewsList(articleId)
      .then((res) => {
        dispatchReviewsOfLastRound({
          type: "INITIAL_STATE",
          data: res.data.lastRoundReviews,
        });
      })
      .finally(() => setIsReady(true));
  }, []);

  function handleToggleModal() {
    setShowModal((prev) => !prev);
  }

  function handleSave() {
    setIsSaving(true);
    const changedStatus = changeArticleStatus(status);
    ApproveArticleFromModal(articleId, changedStatus, reviewsOfLastRound)
      .then((res) => {
        if (res.data.success) {
          setData((prev) => ({ ...prev, statusId: changedStatus }));
          successToast(res.data.message);
        } else {
          errorToast(res.data.message);
        }
      })
      .finally((res) => {
        setIsSaving(false);
        handleToggleModal();
      });
  }

  return (
    <>
      <ButtonWithLoader onClick={handleToggleModal}>
        Approve Reviews
      </ButtonWithLoader>

      <Modal opened={showModal} onClose={handleToggleModal}>
        <Preloader isReady={isReady}>
          <ModalBodyComponent
            title={`Approve Reviews for Round ${getCurrentRound(status)}`}
          >
            <div
              className="inputContainer flex-column align-items-start pb-3"
              style={{ marginTop: "0px" }}
            >
              <ul
                className="w-100 text-start mb-0 circleUl"
                style={{ fontSize: "11px" }}
              >
                <li>All reviews are accepted by default.</li>
                <li>
                  The accepted reviews will be displayed and the authors be able
                  to see them.
                </li>
                <li>The rejected reviews won't be displayed.</li>
                <li>
                  After the reviews have been approved, the article's authors
                  will be notified to resubmit the article and implement the
                  provided feedback.
                </li>
                <li style={{ listStyleType: "none" }}>
                  <span className="d-flex align-items-center gap10 justify-content-end">
                    <span className="d-flex align-items-center">
                      <span className="me-1 d-block">Accepted:</span>
                      <span className="accpetedReviewsColor"></span>
                    </span>
                    <span className="d-flex align-items-center">
                      <span className="me-1 d-block">Rejected:</span>
                      <span className="rejectedReviewsColor"></span>
                    </span>
                  </span>
                </li>
              </ul>
              <div></div>
              <div className="reviewsMainWrapper">
                {reviewsOfLastRound?.map((review, index) => {
                  return (
                    <ReviewListItemModal
                      key={review.id}
                      review={review}
                      index={index}
                      articleStatus={status}
                      dispatchReviewsOfLastRound={dispatchReviewsOfLastRound}
                    />
                  );
                })}
              </div>
            </div>

            <div
              className="modal-footer d-flex align-items-center justify-content-end gap10"
              style={{ paddingBottom: "0px" }}
            >
              <ButtonWithLoader
                onClick={() => handleSave()}
                isLoading={isSaving}
              >
                Approve
              </ButtonWithLoader>
              <ButtonWithLoader
                onClick={handleToggleModal}
                extraClasses={["negative"]}
              >
                Cancel
              </ButtonWithLoader>
            </div>
          </ModalBodyComponent>
        </Preloader>
      </Modal>
    </>
  );
}

export default ApproveReviewsButton;
