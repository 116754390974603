import React from "react";
import { NavLink } from "react-router-dom";

function ReviewersMenu() {
  return (
    <div className="card journalMenuContainer mt-3 mb-2">
      <h2>Reviewer's Menu</h2>
      <nav>
        <ul className="d-flex flex-column journalMenu">
          <li>
            <NavLink to={"/pending-evaluations-requests"}>
              Pending Evaluations Requests
            </NavLink>
          </li>
          <li>
            <NavLink to={"/completed-evaluations-requests"}>
              Completed Evaluations Requests
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default ReviewersMenu;
