import React, { useState } from "react";
import CharacterCounter from "./CharacterCounter";
import { errorToast } from "../../UI/HelperFunctions/HelperFunctions";

function Input({
  inputProps,
  label,
  onChange,
  errorMessage,
  value,
  validateWordCount = false,
  maxWords,
  splitWith=";"
}) {
  const [focus, setFocus] = useState(false);

  function validateOnChange(e) {
    if (validateWordCount) {
      let enteredValue = e.target.value
      let valueWordLength = enteredValue.trim().split(splitWith).length
      if (valueWordLength > maxWords) {
        errorToast(`Max number of keywords is ${maxWords}!`);
        if(enteredValue.length > value.length)  return;
         
      }
    }
    onChange(e)
  }


  return (
    <div
      className={`inputWrapper ${
        validateWordCount ? "position-relative" : ""
      }`}
    >
      {label && (
        <label htmlFor={inputProps.id}>
          {label}
          {inputProps.required === true ? <span>*</span> : ""}
        </label>
      )}
      <input
        {...inputProps}
        value={value || ""}
        autoComplete="off"
        onChange={(e) => validateOnChange(e)}
        onBlur={() => {
          setFocus(true);
        }}
        focus={focus.toString()}
      />
      {validateWordCount && (
        <CharacterCounter value={value.trim() != "" ? value.trim().split(splitWith).length  : 0} maxWords={maxWords} />
      )}
      <span>{errorMessage}</span>
    </div>
  );
}

export default Input;
