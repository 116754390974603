import React, { useEffect, useState } from "react";
import ArticleDetails from "../Modals/ArticleDetails";
import ButtonWithLoader from "../../UI/ButtonWithLoader/ButtonWithLoader";
import { GetSubmittedArticleById } from "../../endpoints";
import { useNavigate, useParams } from "react-router-dom";
import { ArticleStatus } from "../../Enums";
import Preloader from "../../UI/Preloader/Preloader";

function SubmittedArticlesViewArticle() {
  const { articleId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    GetSubmittedArticleById(articleId, false)
      .then((res) => {
        if (res.data.success) {
          setData(res.data);
        }
      })
      .finally((res) => setIsReady(true));
  }, []);

  function backHandler() {
    navigate("/submitted-articles");
  }

  function resubmitHandler() {
    navigate(`/submit-article/${articleId}/article-information`);
  }

  return (
    <Preloader isReady={isReady}>
      <ArticleDetails tableData={data} />
      <div className="modal-footer justify-content-between mt-4">
        <div>
          <ButtonWithLoader onClick={backHandler}>
            {"<"} Go Back
          </ButtonWithLoader>
        </div>
        {[
          ArticleStatus.ApprovedR1,
          ArticleStatus.ApprovedR2,
          ArticleStatus.ApprovedR3,
        ].includes(data.statusId) && (
          <ButtonWithLoader onClick={resubmitHandler}>
            Resubmit
          </ButtonWithLoader>
        )}
      </div>
    </Preloader>
  );
}

export default SubmittedArticlesViewArticle;
