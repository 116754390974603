import React, { useState } from "react";
import ReviewerComments from "./ReviewerComments";
import Ranking from "./Ranking";

function ReviewRoundCreater({
  onChange,
  values,
  setValues,
  initialOpenState = false,
  readOnly,
  round,
}) {
  const [open, setIsOpen] = useState(initialOpenState);

  return (
    <div
      className={`stepDividerContainer ${open ? "open" : ""} ${
        readOnly ? "readonly" : ""
      }`}
    >
      <button className="toggleStep" onClick={() => setIsOpen((prev) => !prev)}>
        <h2 className="reviewDividerHeader ">
          <i className="bi bi-chevron-right"></i>Review Round {round}
        </h2>
      </button>
      <div className="stepDivider">
        <div className="reviewDividerWrapper">
          <div className="px-4">
            <Ranking
              onChange={onChange}
              reviewRank={values[`reviewRank${round}`]}
              canEdit={readOnly}
              round={round}
            />
            <ReviewerComments
              reviewerComments={values}
              setReviewerComments={setValues}
              canEdit={readOnly}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewRoundCreater;
