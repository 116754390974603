import React from "react";

function ModalBodyComponent({children,title,description}) {
  return (
    <div
      className="modalContent auxModal"
      onClick={(e) => e.stopPropagation()}
    >
      <h5 className="modalHeader d-flex align-items-center justify-content-between text-center">{title}</h5>
      <div
        className="d-flex gap10 flex-wrap text-start auxModalBody my-3"
        style={{ height: "auto" }}
      >{children}</div>
    </div>
  );
}

export default ModalBodyComponent;
