import React, { useState } from "react";
import ButtonWithLoader from "../../../UI/ButtonWithLoader/ButtonWithLoader";
import ModalBodyComponent from "../../../UI/Modal/ModalBodyComponent";
import { SendArticleAlert } from "../../../endpoints";
import {
  errorToast,
  successToast,
} from "../../../UI/HelperFunctions/HelperFunctions";
import Modal from "../../../UI/Modal/Modal";
import { useParams } from "react-router-dom";

function SendAlertsButton() {
  const { articleId } = useParams();
  const defaultState = {
    alertMessage: "",
    alertAuthors: false,
    alertChiefEditor: false,
    alertReviewers: false,
    alertEditors: false,
    articleId: articleId,
  };
  const [showModal, setShowModal] = useState(false);

  const [values, setValues] = useState(defaultState);
  const [isSaving, setIsSaving] = useState(false);

  const handleSend = () => {
    setIsSaving(true);
    SendArticleAlert(values)
      .then((res) => {
        setIsSaving(true);
        if (res.data.success) {
          successToast(res.data.message);
          setValues(defaultState);
          setShowModal(false);
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsSaving(false));
  };

  function onClose() {
    setShowModal(false);
  }
  function openModal() {
    setShowModal(true);
  }

  return (
    <>
      <ButtonWithLoader onClick={openModal} isLoading={isSaving}>
        <i className="bi bi-exclamation-circle"></i> Send Alerts
      </ButtonWithLoader>
      <Modal opened={showModal} onClose={onClose}>
        <ModalBodyComponent title={"Send Alerts"}>
          <div className="inputContainer d-block align-items-start mt-0">
            <label htmlFor="alertComment">Alert message</label>
            <textarea
              value={values.alertMessage}
              rows={5}
              className="w-100"
              style={{ flex: "auto !important" }}
              id="alertComment"
              onChange={(e) =>
                setValues((prev) => ({
                  ...prev,
                  alertMessage: e.target.value,
                }))
              }
            />
          </div>
          <div className="d-block inputContainer mt-0">
            <label htmlFor="">Send alert to: </label>
            <div className="">
              <label className="d-flex">
                <input
                  className="me-1"
                  type="checkbox"
                  value="Authors"
                  id="Authors"
                  checked={values.alertAuthors}
                  onChange={() => {
                    setValues((prev) => ({
                      ...prev,
                      alertAuthors: !prev.alertAuthors,
                    }));
                  }}
                />
                Authors
              </label>
              <label className="d-flex">
                <input
                  className="me-1"
                  type="checkbox"
                  value="Authors"
                  id="Reviewers"
                  checked={values.alertReviewers}
                  onChange={() => {
                    setValues((prev) => ({
                      ...prev,
                      alertReviewers: !prev.alertReviewers,
                    }));
                  }}
                />
                Reviewers
              </label>

              <label className="d-flex">
                <input
                  className="me-1"
                  type="checkbox"
                  value="AuthorsChiefEditors"
                  id="AuthorsChiefEditors"
                  checked={values.alertEditors}
                  onChange={() => {
                    setValues((prev) => ({
                      ...prev,
                      alertEditors: !prev.alertEditors,
                    }));
                  }}
                />
                Editors
              </label>
              <label className="d-flex">
                <input
                  className="me-1"
                  type="checkbox"
                  value="ChiefEditors"
                  id="ChiefEditors"
                  checked={values.alertChiefEditor}
                  onChange={() => {
                    setValues((prev) => ({
                      ...prev,
                      alertChiefEditor: !prev.alertChiefEditor,
                    }));
                  }}
                />
                Editors-in-Chief
              </label>
            </div>
          </div>
          <div className="modal-footer d-flex align-items-center justify-content-end gap10">
            <ButtonWithLoader onClick={handleSend} isLoading={isSaving}>
              Send Alerts
            </ButtonWithLoader>
            <ButtonWithLoader onClick={onClose} extraClasses={["negative"]}>
              Cancel
            </ButtonWithLoader>
          </div>
        </ModalBodyComponent>
      </Modal>
    </>
  );
}

export default SendAlertsButton;
