import React, { useEffect, useState } from "react";
import Input from "../Inputs/Input";
import { GetCountries, RegisterUser } from "../../endpoints";
import Select from "../Inputs/Select";
import { Link, useNavigate } from "react-router-dom";
import "./Auth.css";
import {
  errorToast,
  successToast,
} from "../../UI/HelperFunctions/HelperFunctions";
import ButtonWithLoader from "../../UI/ButtonWithLoader/ButtonWithLoader";

function Register() {
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);
  useEffect(() => {
    GetCountries().then((res) => {
      setCountries(res.data);
    });
  }, []);
  const [values, setValues] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    title: "",
    password: "",
    confirmPassword: "",
    email: "",
    universityOrOrganization: "",
    department: "",
    addressLine1: "",
    addressLine2: "",
    zipCode: "",
    city: "",
    countryId: "",
  });
  function handleChange(e) {
    setValues((prev) => {
      const name = e.target.name || e.target.title;
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsRequestBeingMade(true);
    RegisterUser(values)
      .then((res) => {
        if (res.data.success) {
          navigate("/auth/login");
          setIsRequestBeingMade(false);
          successToast("You account has been successfully created");
        }
        if (!res.data.success) {
          errorToast(res.data.message);
          setIsRequestBeingMade(false);
        }
      })
      .catch((err) => setIsRequestBeingMade(false));
  }

  return (
    <div className="authContainer w-75 card">
      <h2 className="boardHeader">Register</h2>
      <div className="d-flex align-items-center justify-content-center">
        <form
          className="authForm d-flex flex-column gap15"
          onSubmit={(e) => handleSubmit(e)}
        >
          <Input
            key={"email"}
            inputProps={{
              type: "email",
              name: "email",
              id: "email",
              required: true,
              pattern: "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$",
              maxLength: "80",
            }}
            value={values.email}
            onChange={handleChange}
            label={"Email Address"}
          />

          <div className="d-flex align-items-start gap10 flex-wrap">
            <Input
              key={"password"}
              inputProps={{
                type: "password",
                name: "password",
                id: "password",
                pattern: "^.{7,}$",
                required: true,
              }}
              value={values.password}
              onChange={handleChange}
              label={"Password"}
              errorMessage={"Password must contain at least 7 characters"}
            />
            <Input
              key={"confirmPassword"}
              inputProps={{
                type: "password",
                name: "confirmPassword",
                id: "confirmPassword",
                pattern: `^${values.password?.replace(
                  /[.*+?^${}()|[\]\\]/g,
                  "\\$&"
                )}$`,
                required: true,
              }}
              value={values.confirmPassword}
              onChange={handleChange}
              label={"Confirm Password"}
              errorMessage={"Passwords do not match"}
            />
          </div>

          <div className="d-flex align-items-start gap10 flex-wrap">
            <Input
              key={"firstName"}
              inputProps={{
                type: "text",
                name: "firstName",
                id: "firstName",
                required: true,
                maxLength: "150",
              }}
              value={values.firstName}
              onChange={handleChange}
              label={"First Name"}
            />
            <Input
              key={"middleName"}
              inputProps={{
                type: "text",
                name: "middleName",
                id: "middleName",
                required: false,
                maxLength: "150",
              }}
              value={values.middleName}
              onChange={handleChange}
              label={"Middle Name"}
            />
            <Input
              key={"lastName"}
              inputProps={{
                type: "text",
                name: "lastName",
                id: "lastName",
                required: true,
                maxLength: "150",
              }}
              value={values.lastName}
              onChange={handleChange}
              label={"Last Name"}
            />
          </div>
          <div className="d-flex align-items-start gap10 flex-wrap">
            <Input
              key={"title"}
              inputProps={{
                type: "text",
                name: "title",
                id: "title",
                required: false,
                maxLength: "150",
              }}
              value={values.title}
              onChange={handleChange}
              label={"Title"}
            />
            <Input
              key={"universityOrOrganization"}
              inputProps={{
                type: "text",
                name: "universityOrOrganization",
                id: "universityOrOrganization",
                required: true,
                maxLength: "350",
              }}
              value={values.universityOrOrganization}
              onChange={handleChange}
              label={"University/Organization"}
            />
            <Input
              key={"department"}
              inputProps={{
                type: "text",
                name: "department",
                id: "department",
                required: false,
                maxLength: "350",
              }}
              value={values.department}
              onChange={handleChange}
              label={"Department"}
            />
          </div>
          <Input
            key={"addressLine1"}
            inputProps={{
              type: "text",
              name: "addressLine1",
              id: "addressLine1",
              required: true,
            }}
            value={values.addressLine1}
            onChange={handleChange}
            label={"Address Line 1"}
          />
          <Input
            key={"addressLine2"}
            inputProps={{
              type: "text",
              name: "addressLine2",
              id: "addressLine2",
              required: false,
            }}
            value={values.addressLine2}
            onChange={handleChange}
            label={"Address Line 2"}
          />
          <div className="d-flex gap10 flex-wrap">
            <Select
              key={"countryId"}
              inputProps={{
                type: "text",
                title: "countryId",
                id: "countryId",
                required: true,
              }}
              defaultValue={"Select Country"}
              options={countries}
              onChange={handleChange}
              label={"Country"}
              value={values.countryId}
            />
            <Input
              key={"city"}
              inputProps={{
                type: "text",
                name: "city",
                id: "city",
                required: true,
                maxLength: "150",
              }}
              onChange={handleChange}
              label={"City"}
              value={values.city}
            />
            <Input
              key={"zipCode"}
              inputProps={{
                type: "number",
                name: "zipCode",
                id: "zipCode",
                required: true,
                maxLength: "20",
              }}
              onChange={handleChange}
              label={"Zip Code"}
              value={values.zipCode}
            />
          </div>
          <ButtonWithLoader isLoading={isRequestBeingMade}>
            Register
          </ButtonWithLoader>
          <Link to={"/auth/login"} className="mt-1 text-center">
            Already have an Account? <b>Log In</b>{" "}
          </Link>
        </form>
      </div>
    </div>
  );
}

export default Register;
