import React, { useContext, useEffect, useState } from "react";
import Protector from "../../UI/Protector";
import Preloader from "../../UI/Preloader/Preloader";
import { AuthContext } from "../../store/AuthContext";
import { UserRole } from "../../Enums";
import { GetUsersByRoleList } from "../../endpoints";
import Table from "../../UI/Tables/Table";

const Reviewers = () => {
  const [isReady, setIsReady] = useState(false);
  const [tableData, setTableData] = useState();
  const { userObj } = useContext(AuthContext);

  const tableHeaders = [
    { value: "First Name" },
    { value: "Last Name" },
    { value: "Title" },
    { value: "University/Organization" },
    { value: "Department" },
    { value: "Country" },
  ];

  useEffect(() => {
    GetUsersByRoleList([UserRole.Reviewer]).then((res) => {
      const copiedArray = res.data.users?.map((obj) => ({
        firstName: obj.firstName,
        lastName: obj.lastName,
        title: obj.title,
        universityOrOrganization: obj.universityOrOrganization,
        department: obj.department,
        country: obj.country?.name,
        buttonId: obj.id,
      }));
      setTableData(copiedArray?.map((obj) => Object.values(obj)));
      setIsReady(true);
    });
  }, []);

  return (
    <Protector
      isAuthorized={
        userObj.role === UserRole.EditorInChief ||
        userObj.role === UserRole.Editor
      }
    >
      <Preloader isReady={isReady}>
        <h2 className="boardHeader">Reviewers List</h2>
        <Table
          tableHeaders={tableHeaders}
          tableData={tableData}
          btnText={"View"}
          btnLink={"/reviewers"}
        />
      </Preloader>
    </Protector>
  );
};

export default Reviewers;
