import React, { useContext } from "react";
import { AuthContext } from "../../store/AuthContext";
import { UserRole } from "../../Enums";
import { getReviewerRecomendation } from "../HelperFunctions/HelperFunctions";

function ReviewRound({ review, round }) {
  const { userObj } = useContext(AuthContext);
  return (
    <div>
      <h3 className="reviewsAccordionH3">
        Review {round}
        {[UserRole.Editor, UserRole.EditorInChief].includes(userObj.role) && (
          <span>
            : {review.user.firstName} {review.user.lastName}
          </span>
        )}
      </h3>
      <div className="d-flex">
        <b>Recommendation:</b>
        <span>{getReviewerRecomendation(review.reviewedRank)}</span>
        {/* <Ratings rating={review.reviewedRank} /> */}
      </div>
      <div className="d-flex">
        <b>Comment:</b>
        <div
          dangerouslySetInnerHTML={{
            __html: review.commentsToAuthors,
          }}
        ></div>
      </div>
      {[UserRole.Editor, UserRole.EditorInChief].includes(userObj.role) && (
        <div className="d-flex editorComment">
          <b>Comment to Editors:</b>
          <div
            dangerouslySetInnerHTML={{
              __html: review.commentsToEditor,
            }}
          ></div>
        </div>
      )}
    </div>
  );
}

export default ReviewRound;
