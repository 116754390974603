import React from "react";
import "./Footer.css"

import { journalName } from "../JournalSettings";

const Footer = () => {
  return (
    <>
      <footer className="d-flex align-items-center">
        <div className="copyright">
          © 2023 {journalName.toUpperCase()} unless otherwise stated
        </div>
      </footer>
    </>
  );
};

export default Footer;
