import React, { useContext } from "react";
import Protector from "../../UI/Protector";
import { AuthContext } from "../../store/AuthContext";
import { UserRole } from "../../Enums";
import CustomizableTable from "../../UI/Tables/CustomizableTable";
import { SettingsContext } from "../../store/SettingsContext";

const ManageArticlesDashboard = ({
  tableData,
  paginationData,
  setNewPageNumber,
}) => {
  const { userObj } = useContext(AuthContext);
  const { hasArticleTypes, hasTopics } = useContext(SettingsContext);

  const tableHeaders = [
    { value: "Article Title" },
    { value: "Author/s" },
    ...(hasArticleTypes ? [{ value: "Type" }] : []),
    ...(hasTopics ? [{ value: "Topic" }] : []),
    { value: "Submitted Date" },
    { value: "Status" },
  ];

  return (
    <Protector
      isAuthorized={[UserRole.EditorInChief, UserRole.Editor].includes(
        userObj.role
      )}
    >
      <div className="submittedArticleTable">
        {tableData.length !== 0 && (
          <>
            <p>Submitted Articles</p>
            <CustomizableTable
              tableData={tableData}
              tableHeaders={tableHeaders}
              setNewPageNumber={setNewPageNumber}
              paginationData={paginationData}
            />
          </>
        )}
      </div>
    </Protector>
  );
};

export default ManageArticlesDashboard;
