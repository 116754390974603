import React from "react";
import UnauthorizedResponse from "./UnauthorizedResponse/UnauthorizedResponse";

function Protector({ isAuthorized, children,isReviewer=false,requiredRole="" }) {

 
  return (
    <div className="">
      {isAuthorized && children}
      {!isAuthorized && <UnauthorizedResponse isReviewer={isReviewer} requiredRole={requiredRole}/>}
    </div>
  );
}

export default Protector;
