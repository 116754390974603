import React, { useState, useEffect, useContext } from "react";
import Input from "../components/Inputs/Input";
import Select from "../components/Inputs/Select";
import { GetCountries, EditUser } from "../endpoints";
import Textarea from "../components/Inputs/Textarea";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../store/AuthContext";
import {
  errorToast,
  successToast,
} from "../UI/HelperFunctions/HelperFunctions";
import ButtonWithLoader from "../UI/ButtonWithLoader/ButtonWithLoader";

const EditProfile = () => {
  const [countries, setCountries] = useState([]);
  const { userObj, setUserObj } = useContext(AuthContext);
  const [values, setValues] = useState({
    firstName: userObj.firstName,
    middleName: userObj.middleName,
    lastName: userObj.lastName,
    title: userObj.title,
    email: userObj.email,
    universityOrOrganization: userObj.universityOrOrganization,
    department: userObj.department,
    addressLine1: userObj.addressLine1,
    addressLine2: userObj.addressLine2,
    zipCode: userObj.zipCode,
    city: userObj.city,
    countryId: userObj.countryId,
    interests: userObj.interests,
    website: userObj.website,
    phoneNo: userObj.phoneNo,
    biography: userObj.biography,
    status: userObj.statusId,
    profilePhoto: userObj.profilePhoto?.split(",")[1],
  });
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    GetCountries().then((res) => {
      setCountries(res.data);
    });
  }, []);

  function handleChange(e) {
    setValues((prev) => {
      const name = e.target.name || e.target.title;
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsSaving(true);
    EditUser(values)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          setUserObj({
            ...userObj,
            ...values,
            profilePhoto:
              values.profilePhoto != null
                ? `data:image/*;base64,${values.profilePhoto}`
                : values.profilePhoto,
          });
        } else {
          errorToast(res.data.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsSaving(false));
  }

  function handleImageUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const imageData = reader.result.split(",")[1];
      setValues({ ...values, profilePhoto: imageData });
    };
  }

  return (
    <>
      <div>
        <h2 className="boardHeader">Edit Profile</h2>
        <div className="d-flex align-items-center justify-content-center">
          <form
            className="editProfileForm  d-flex flex-column gap15 w-100 p-2"
            onSubmit={(e) => handleSubmit(e)}
          >
            <Input
              key={"email"}
              inputProps={{
                type: "email",
                name: "email",
                id: "email",
                required: true,
                maxLength: "80",
                pattern: "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$",
              }}
              value={values.email}
              onChange={handleChange}
              label={"Email Address"}
              errorMessage={"Invalid Email"}
            />
            <div className="d-flex align-items-start gap10 flex-wrap">
              <Input
                key={"firstName"}
                inputProps={{
                  type: "text",
                  name: "firstName",
                  id: "firstName",
                  required: true,
                  maxLength: "150",
                }}
                value={values.firstName}
                onChange={handleChange}
                label={"First Name"}
                errorMessage={"Field Cannot be Empty"}
              />
              <Input
                key={"middleName"}
                inputProps={{
                  type: "text",
                  name: "middleName",
                  id: "middleName",
                  required: false,
                  maxLength: "150",
                }}
                value={values.middleName}
                onChange={handleChange}
                label={"Middle Name"}
              />
              <Input
                key={"lastName"}
                inputProps={{
                  type: "text",
                  name: "lastName",
                  id: "lastName",
                  required: true,
                  maxLength: "150",
                }}
                value={values.lastName}
                onChange={handleChange}
                label={"Last Name"}
                errorMessage={"Field Cannot be Empty"}
              />
            </div>
            <div className="d-flex align-items-start gap10 flex-wrap">
              <Input
                key={"title"}
                inputProps={{
                  type: "text",
                  name: "title",
                  id: "title",
                  maxLength: "150",
                  required: false,
                }}
                value={values.title}
                onChange={handleChange}
                label={"Title"}
              />
              <Input
                key={"universityOrOrganization"}
                inputProps={{
                  type: "text",
                  name: "universityOrOrganization",
                  id: "universityOrOrganization",
                  maxLength: "350",
                  required: true,
                }}
                value={values.universityOrOrganization}
                onChange={handleChange}
                label={"University/Organization"}
              />

              <Input
                key={"department"}
                inputProps={{
                  type: "text",
                  name: "department",
                  id: "department",
                  maxLength: "350",
                  required: false,
                }}
                value={values.department}
                onChange={handleChange}
                label={"Department"}
              />
            </div>
            <Input
              key={"addressLine1"}
              inputProps={{
                type: "text",
                name: "addressLine1",
                id: "addressLine1",
                required: true,
              }}
              value={values.addressLine1}
              onChange={handleChange}
              label={"Address Line 1"}
              errorMessage={"Field Cannot be Empty"}
            />
            <Input
              key={"addressLine2"}
              inputProps={{
                type: "text",
                name: "addressLine2",
                id: "addressLine2",
                required: false,
              }}
              value={values.addressLine2}
              onChange={handleChange}
              label={"Address Line 2"}
            />
            <div className="d-flex gap10 flex-wrap">
              <Select
                key={"countryId"}
                inputProps={{
                  type: "text",
                  title: "countryId",
                  id: "countryId",
                  required: true,
                }}
                defaultValue={"Select Country"}
                options={countries}
                onChange={handleChange}
                label={"Country"}
                value={values.countryId}
                errorMessage={"Please Select a Country"}
              />
              <Input
                key={"city"}
                inputProps={{
                  type: "text",
                  name: "city",
                  id: "city",
                  required: true,
                  maxLength: "150",
                }}
                onChange={handleChange}
                label={"City"}
                value={values.city}
                errorMessage={"Please Enter a City"}
              />
              <Input
                key={"zipCode"}
                inputProps={{
                  type: "number",
                  name: "zipCode",
                  id: "zipCode",
                  required: true,
                  maxLength: "20",
                }}
                onChange={handleChange}
                label={"Zip Code"}
                value={values.zipCode}
                errorMessage={"Please Enter Zip Code"}
              />
            </div>
            <div className="d-flex gap10 flex-wrap">
              <Input
                key={"phoneNo"}
                inputProps={{
                  type: "text",
                  name: "phoneNo",
                  id: "phoneNo",
                  maxLength: "25",
                  required: false,
                }}
                value={values.phoneNo}
                onChange={handleChange}
                label={"Phone Number"}
              />
              <Input
                key={"website"}
                inputProps={{
                  type: "text",
                  name: "website",
                  id: "website",
                  required: false,
                }}
                value={values.website}
                onChange={handleChange}
                label={"Website"}
              />
            </div>
            <div className="d-flex gap10 flex-wrap">
              <Textarea
                key={"biography"}
                inputProps={{
                  type: "text",
                  placeholder: "",
                  name: "biography",
                  id: "biography",
                  required: false,
                }}
                value={values.biography}
                onChange={handleChange}
                label={"Biography"}
              />
              <Textarea
                key={"interests"}
                inputProps={{
                  type: "text",
                  placeholder: "",
                  name: "interests",
                  id: "interests",
                  maxLength: "255",
                  required: false,
                }}
                value={values.interests}
                onChange={handleChange}
                label={"Interests"}
              />
            </div>
            <div className="d-flex align-items-start gap10 flex-wrap col-md-6 col-12">
              <div className="inputWrapper">
                <label htmlFor="">Profile Photo</label>
                {values.profilePhoto ? (
                  <div
                    className="d-flex align-items-center justify-content-between form-control"
                    style={{ border: "1px solid var(--main05)" }}
                  >
                    <img
                      src={`data:image;base64,${values.profilePhoto}`}
                      alt="Profile"
                      className="profile-photo"
                      width={50}
                    />
                    <svg
                      onClick={() => {
                        setValues({ ...values, profilePhoto: "" });
                      }}
                      style={{ cursor: "pointer" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="red"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </div>
                ) : (
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    onChange={handleImageUpload}
                  />
                )}
              </div>
            </div>
            <ButtonWithLoader isLoading={isSaving} extraClasses={["ms-auto"]}>
              Submit
            </ButtonWithLoader>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
