import React, { useState } from "react";
import ButtonWithLoader from "../../../UI/ButtonWithLoader/ButtonWithLoader";
import Modal from "../../../UI/Modal/Modal";
import ModalBodyComponent from "../../../UI/Modal/ModalBodyComponent";
import { useParams } from "react-router-dom";
import Textarea from "../../Inputs/Textarea";
import { RejectArticlePublication } from "../../../endpoints";
import {
  errorToast,
  successToast,
} from "../../../UI/HelperFunctions/HelperFunctions";
import { ArticleStatus } from "../../../Enums";

function RejectArticlePublicationButton({ setData }) {
  const [showModal, setShowModal] = useState(false);
  const { articleId } = useParams();
  const [rejectArticleComment, setRejectArticleComment] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  function onClose() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  function handleChange(e) {
    setRejectArticleComment(e.target.value);
  }

  function handleReject() {
    setIsSaving(true);
    RejectArticlePublication(articleId, rejectArticleComment)
      .then((res) => {
        if (res.data.success) {
          setData((prev) => ({
            ...prev,
            statusId: ArticleStatus.Rejected,
            rejectedComment: rejectArticleComment,
            rejectedDate: new Date().toJSON(),
          }));
          successToast(res.data.message);
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsSaving(false));
  }

  return (
    <>
      <ButtonWithLoader
        extraClasses={["negative"]}
        onClick={() => {
          openModal();
        }}
        buttonProps={{
          disabled: isSaving,
        }}
        isLoading={isSaving}
      >
        Reject
      </ButtonWithLoader>
      <Modal opened={showModal} onClose={onClose}>
        <ModalBodyComponent title={"Reject Article Publication Comment"}>
          <Textarea
            key={"rejectArticleComment"}
            inputProps={{
              type: "text",
              placeholder: "",
              name: "rejectArticleComment",
              id: "rejectArticleComment",
              required: false,
            }}
            value={rejectArticleComment}
            onChange={handleChange}
            label={"Comment"}
          />

          <div
            className="modal-footer d-flex align-items-center justify-content-end gap10"
            style={{ paddingBottom: "0px" }}
          >
            <ButtonWithLoader isLoading={isSaving} onClick={handleReject}>
              Reject
            </ButtonWithLoader>
            <ButtonWithLoader onClick={onClose} extraClasses={["negative"]}>
              Cancel
            </ButtonWithLoader>
          </div>
        </ModalBodyComponent>
      </Modal>
    </>
  );
}

export default RejectArticlePublicationButton;
