import React, { useContext, useEffect, useState } from "react";
import ArticleDetails from "../Modals/ArticleDetails";
import {
  AcceptArticlePublication,
  ApproveArticleByStatus,
  GetSubmittedArticleById,
  PublishArticle,
  RejectArticlePublication,
} from "../../endpoints";
import { useNavigate, useParams } from "react-router-dom";
import ButtonWithLoader from "../../UI/ButtonWithLoader/ButtonWithLoader";
import { ArticleStatus, UserRole } from "../../Enums";
import {
  changeArticleStatus,
  errorToast,
  successToast,
} from "../../UI/HelperFunctions/HelperFunctions";
import Preloader from "../../UI/Preloader/Preloader";
import PublishFileComponent from "./PublishFileComponent";
import SendAlertsButton from "./ManageArticleButtons/SendAlertsButton";
import AssignReviewersButton from "./ManageArticleButtons/AssignReviewersButton";
import Protector from "../../UI/Protector";
import { AuthContext } from "../../store/AuthContext";
import RejectArticlePublicationButton from "./ManageArticleButtons/RejectArticlePublicationButton";
import ApproveReviewsButton from "./ManageArticleButtons/ApproveReviewsButton";
import { SettingsContext } from "../../store/SettingsContext";
import CommentOnArticleButton from "./ManageArticleButtons/CommentOnArticleButton";

function ConvertToInformFile(document, documentType) {
  const base64Data = document[documentType].articleFile;
  const fileName = document[documentType].name;
  const fileType = document[documentType].type;

  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: fileType });
  const file = new File([blob], fileName, { type: fileType });

  return file;
}

function ManageArticleViewArticle() {
  const { articleId } = useParams();
  const { userObj } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [publishArticleData, setPublishArticleData] = useState({
    publishedPDF: "",
    volumeId: "",
  });
  const [isReady, setIsReady] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isFetchingPublishedData, setIsFetchingPublishedData] = useState(true);
  const [isAccepting, setIsAccepting] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const { approveReviewByEditor } = useContext(SettingsContext);
  const [hasPublishedFile, setHasPublishedFile] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    GetSubmittedArticleById(articleId, false)
      .then((res) => {
        setData(res.data);
      })
      .finally((err) => setIsReady(true));
  }, []);

  function backHandler() {
    navigate("/manage-articles");
  }

  function ApproveArticleByStatusHandler(currentStatus, resetReviewersStatus) {
    setIsApproving(true);
    const changedStatus = changeArticleStatus(currentStatus);
    ApproveArticleByStatus(articleId, changedStatus, resetReviewersStatus)
      .then((res) => {
        if (res.data.success) {
          setData((prev) => ({ ...prev, statusId: changedStatus }));
          successToast(res.data.message);
        } else {
          errorToast(res.data.message);
        }
      })
      .finally((res) => setIsApproving(false));
  }

  function acceptHandler() {
    setIsAccepting(true);
    AcceptArticlePublication(articleId)
      .then((res) => {
        if (res.data.success) {
          setData((prev) => ({ ...prev, statusId: ArticleStatus.Accepted }));
          successToast(res.data.message);
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsAccepting(false));
  }

  function publishHandler() {
    const publishedFileKeyType = publishArticleData.publishedPDF?.type
      ? "type"
      : "articleFileType";

    if (
      !publishArticleData?.publishedPDF ||
      publishArticleData.publishedPDF[publishedFileKeyType] !==
        "application/pdf"
    ) {
      errorToast(`Please upload the PDF version of the accepted article!`);
      return;
    }
    setIsPublishing(true);

    const formData = new FormData();
    formData.append("articleId", articleId);
    {
      !hasPublishedFile
        ? formData.append(
            "publishFile",
            publishArticleData.publishedPDF?.type
              ? publishArticleData.publishedPDF
              : ConvertToInformFile(publishArticleData, "publishedPDF")
          )
        : formData.append(
            "publishFile",
            ConvertToInformFile(publishArticleData, "publishedPDF")
          );
    }
    formData.append("volumeId", publishArticleData.volumeId);

    PublishArticle(formData)
      .then((res) => {
        if (res.data.success) {
          setData((prev) => ({ ...prev, statusId: ArticleStatus.Published }));
          successToast(res.data.message);
        } else {
          errorToast(res.data.message);
        }
      })
      .finally((res) => setIsPublishing(false));
  }

  return (
    <Protector
      isAuthorized={[UserRole.EditorInChief, UserRole.Editor].includes(
        userObj.role
      )}
    >
      <div className="auxillaryWrapper">
        <Preloader isReady={isReady}>

          <div className="d-flex align-items-center gap10 mb-2  flex-wrap">
          <CommentOnArticleButton/>
            <SendAlertsButton />
            {data.statusId <= ArticleStatus.InReviewR1 && (
              <AssignReviewersButton setData={setData} />
            )}
            </div>
          <ArticleDetails tableData={data} />
          {data.statusId >= ArticleStatus.Accepted && (
            <div className="d-flex align-items-center justify-content-center my-3 highlightedFileContainer box-shadow">
              <PublishFileComponent
                publishArticleData={publishArticleData}
                setPublishArticleData={setPublishArticleData}
                isFetchingPublishedData={isFetchingPublishedData}
                setIsFetchingPublishedData={setIsFetchingPublishedData}
                setHasPublishedFile={setHasPublishedFile}
              />
            </div>
          )}

          <div className="modal-footer justify-content-between mt-4">
            <ButtonWithLoader
              onClick={backHandler}
              buttonProps={{
                disabled:
                  isAccepting || isPublishing || isApproving || isRejecting,
              }}
            >
              {"<"} Go Back
            </ButtonWithLoader>

            {approveReviewByEditor &&
              [
                ArticleStatus.ReviewedR1,
                ArticleStatus.ReviewedR2,
                ArticleStatus.ReviewedR3,
              ].includes(data.statusId) && (
                <ApproveReviewsButton
                  setData={setData}
                  status={data.statusId}
                  articleId={articleId}
                />
              )}
            {[
              ArticleStatus.SubmittedAfterR1,
              ArticleStatus.SubmittedAfterReviewedR2,
            ].includes(data.statusId) && (
              <ButtonWithLoader
                onClick={() =>
                  ApproveArticleByStatusHandler(data.statusId, true)
                }
                isLoading={isApproving}
                buttonProps={{
                  disabled:
                    isAccepting || isPublishing || isApproving || isRejecting,
                }}
              >
                Initiate next round
              </ButtonWithLoader>
            )}

            <div>
              {data.statusId < ArticleStatus.Accepted &&
                data.statusId != ArticleStatus.Rejected && (
                  <ButtonWithLoader
                    onClick={acceptHandler}
                    isLoading={isAccepting}
                    buttonProps={{
                      disabled:
                        isAccepting ||
                        isPublishing ||
                        isApproving ||
                        isRejecting,
                    }}
                  >
                    Accept
                  </ButtonWithLoader>
                )}

              {data.statusId >= ArticleStatus.Accepted &&
                data.statusId != ArticleStatus.Rejected && (
                  <ButtonWithLoader
                    onClick={publishHandler}
                    isLoading={isPublishing}
                    buttonProps={{
                      disabled:
                        isAccepting ||
                        isPublishing ||
                        isApproving ||
                        isRejecting ||
                        isFetchingPublishedData,
                    }}
                  >
                    {data.statusId != ArticleStatus.Published
                      ? "Publish"
                      : "Republish"}
                  </ButtonWithLoader>
                )}

              {data.statusId < ArticleStatus.Accepted &&
                data.statusId != ArticleStatus.Rejected && (
                  <RejectArticlePublicationButton
                    setData={setData}
                    isRejecting={isRejecting}
                    setIsRejecting={setIsRejecting}
                  />
                )}
              {data?.statusId == ArticleStatus.Rejected && (
                <span className="text-danger">
                  This Article's Publication has been Rejected
                </span>
              )}
            </div>
          </div>
        </Preloader>
      </div>
    </Protector>
  );
}

export default ManageArticleViewArticle;
