import React, { useContext } from "react";
import { NavLink, useParams } from "react-router-dom";
import { SubmitArticleContext } from "../../../store/SubmitArticleContext";

function SubmitArticleStepper() {
  const { articleId } = useParams();
  const {
    currentForm,
    triggerUpdateForCurrentFrom,
    isFileUploading,
    isRequestFinished,
    completedStep,
  } = useContext(SubmitArticleContext);
  ///makep api calls and saves the entered data whenever user navigates via tabs
  function handleNavigate(e) {
    if (!currentForm.current) return;/// if no from is mounted do nothing
    if (!currentForm.current.checkValidity()) { ;/// if current mounted from is invalid do nothing
      e.preventDefault();
      currentForm.current.reportValidity();
      return;
    }
    if(triggerUpdateForCurrentFrom) triggerUpdateForCurrentFrom(); //make api call and save the current mounted form

  }
  return (
    <div>
      <h2 className="boardHeader">Submit Article</h2>
      <nav className="submitArticleNav">
        <ul>
          <li>
            <NavLink
              to={`/submit-article/${articleId}/article-information`}
              onClick={(e) => handleNavigate(e)}
              disabled={!isRequestFinished ||isFileUploading}
            >
              <h3>Step 1</h3>
              <p>Article Information</p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/submit-article/${articleId}/author-information`}
              onClick={(e) => handleNavigate(e)}
              disabled={!isRequestFinished || completedStep < 2 || isFileUploading}
            >
              <h3>Step 2</h3>
              <p>Author Information</p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/submit-article/${articleId}/upload-article`}
              onClick={(e) => handleNavigate(e)}
              disabled={!isRequestFinished || completedStep < 3 ||isFileUploading}
            >
              <h3>Step 3</h3>
              <p>Upload Article Information</p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/submit-article/${articleId}/confirm`}
              onClick={(e) => handleNavigate(e)}
              disabled={
                !isRequestFinished || (completedStep != 3 && completedStep != 4) || isFileUploading
              }
            >
              <h3>Step 4</h3>
              <p>Confirm and Send</p>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default SubmitArticleStepper;
