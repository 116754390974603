import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetArticleAuthors } from "../../endpoints";
import AuthorListItem from "./AuthorListItem";
import "./AuthorList.css";
import Preloader from "../Preloader/Preloader";

function AuthorList({ articleId }) {
  const [authorList, setAuthorList] = useState([]);
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    GetArticleAuthors(articleId)
      .then((res) => {
        setAuthorList(res.data.articleAuthors);
      })
      .finally((res) => setIsReady(true));
  }, []);
  return (
    <div className="articleAuthorsMainWrapper">
      <Preloader isReady={isReady} minHeight="auto">
        {authorList?.map((author, i) => {
          return <AuthorListItem author={author} index={i} key={author.id} />;
        })}
      </Preloader>
    </div>
  );
}

export default AuthorList;
