import "./Modal.css";
import React, { useEffect, useRef } from "react";

function Modal({ opened, onClose, children,className }) {

  const modalRef = useRef(null)

  useEffect(()=>{
    const handleKeyUp = (e)=> {
      if(e.key ==="Escape") {
        onClose()
      }
    }
    if(opened) {
      window.addEventListener('keyup', handleKeyUp);
      document.body.style.overflow = "hidden"
    }

    return () =>{
      window.removeEventListener('keyup', handleKeyUp);
      document.body.style.overflow = "auto"
    }

  },[opened,onClose])


  useEffect(()=>{

      modalRef?.current?.focus()

  },[modalRef.current])
  return (
    <>
      {opened && (
        <div onClick={onClose} className={`modalDiv ${className}`} ref={modalRef}>
          {opened && children}
        </div>
      )}
    </>
  );
}

export default Modal;
