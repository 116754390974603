import React, { useState } from "react";
import ButtonWithLoader from "../../../UI/ButtonWithLoader/ButtonWithLoader";
import Modal from "../../../UI/Modal/Modal";
import { AddArticleComment } from "../../../endpoints";
import { useParams } from "react-router-dom";
import {
  errorToast,
  successToast,
} from "../../../UI/HelperFunctions/HelperFunctions";
import Textarea from "../../Inputs/Textarea";
import ModalBodyComponent from "../../../UI/Modal/ModalBodyComponent";

function CommentOnArticleButton() {
  const { articleId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [comment, setComment] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  function onClose() {
    setShowModal(false);
  }
  function openModal() {
    setShowModal(true);
  }

  function onChange(e) {
    setComment(e.target.value);
  }
  const handleSend = () => {
    setIsSaving(true);
    AddArticleComment(articleId, comment)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => {
        setIsSaving(false);
        setShowModal(false);
      });
  };

  return (
    <>
      <ButtonWithLoader onClick={openModal} isLoading={isSaving}>
        <i className="bi bi-chat"></i> Add Comment
      </ButtonWithLoader>
      <Modal opened={showModal} onClose={onClose}>
        <ModalBodyComponent title={"Add Comment"}>
          <Textarea
            inputProps={{
              required: true,
              id: "comment",
            }}
            label={"Add Comment"}
            onChange={onChange}
            value={comment}
          />
          <div className="modal-footer d-flex align-items-center justify-content-end gap10">
            <ButtonWithLoader onClick={handleSend}>Comment</ButtonWithLoader>
            <ButtonWithLoader onClick={onClose} extraClasses={["negative"]}>
              Cancel
            </ButtonWithLoader>
          </div>
        </ModalBodyComponent>
      </Modal>
    </>
  );
}

export default CommentOnArticleButton;
