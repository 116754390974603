import React, { useContext, useEffect, useState } from "react";
import { GetUserSubmittedArticles } from "../../endpoints";
import Protector from "../../UI/Protector";
import { AuthContext } from "../../store/AuthContext";
import { ArticleStatus, UserRole } from "../../Enums";
import CustomizableTable from "../../UI/Tables/CustomizableTable";
import Preloader from "../../UI/Preloader/Preloader";
import { articleStatus } from "../../UI/HelperFunctions/HelperFunctions";
import { SettingsContext } from "../../store/SettingsContext";

function getBtnText(id) {
  if (id == ArticleStatus.Submitting) {
    return "Continue Editing";
  }
  if (
    [
      ArticleStatus.ApprovedR1,
      ArticleStatus.ApprovedR2,
      ArticleStatus.ApprovedR3,
    ].includes(id)
  ) {
    return "Resubmit";
  }
  return "View Article";
}

const getBtnLink = (statusId, id) => {
  if (ArticleStatus.Submitting === statusId)
    return `/submit-article/${id}/article-information`;

  return `/submitted-articles/${id}`;
};

function SubmittedArticles({ showBoardHeader = true }) {
  const [tableData, setTableData] = useState([]);
  const { userObj } = useContext(AuthContext);
  const [isReady, setIsReady] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [newPageNumber, setNewPageNumber] = useState(1);
  const { hasArticleTypes, hasSections } = useContext(SettingsContext);

  const tableHeaders = [
    { value: "Article ID" },
    { value: "Title" },
    ...(hasArticleTypes ? [{ value: "Type" }] : []),
    ...(hasSections ? [{ value: "Section" }] : []),
    { value: "Submitted Date" },
    { value: "Status" },
  ];

  function getBtnClass(id) {}

  useEffect(() => {
    if (userObj.role !== UserRole.Author) return;
    GetUserSubmittedArticles()
      .then((res) => {
        const copiedArray = res.data.articles.items?.map((obj) => ({
          id: {
            type: "text",
            value: obj.id,
            classes: "truncateTextToSingleLine",
          },
          title: { type: "text", value: obj.title },
          ...(hasArticleTypes
            ? { type: { type: "text", value: obj.articleType } }
            : []),
          ...(hasSections
            ? { section: { type: "text", value: obj.journalSection } }
            : []),
          submittedDate: {
            type: "text",
            value: obj.submittedDate?.substring(0, 10),
          },
          status: {
            type: "text",
            value: articleStatus(obj.statusId),
            classes: getBtnClass(obj.statusId),
          },
          buttonId: {
            type: "link",
            text: getBtnText(obj.statusId),
            link: getBtnLink(obj.statusId, obj.id),
          },
        }));
        setTableData(copiedArray?.map((obj) => Object.values(obj)));
      })
      .finally(() => setIsReady(true));
  }, []);

  return (
    <Protector isAuthorized={userObj.role === UserRole.Author}>
      <div>
        {showBoardHeader && <h2 className="boardHeader">Submitted Articles</h2>}
        <Preloader isReady={isReady}>
          <CustomizableTable
            tableHeaders={tableHeaders}
            tableData={tableData}
            paginationData={paginationData}
            se
          />
        </Preloader>
      </div>
    </Protector>
  );
}

export default SubmittedArticles;
