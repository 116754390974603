import React, { useState } from "react";
import CharacterCounter from "./CharacterCounter";
import { errorToast } from "../../UI/HelperFunctions/HelperFunctions";





const exludedWords = ["a","the","an"]
function getWordCountWithExludedWords(textToCountWords) {
return textToCountWords.trim().split(/\s+/).filter(el=>exludedWords.indexOf(el.toLowerCase()) === -1 && el.length > 1).length
}

function Textarea({
  inputProps,
  label,
  onChange,
  errorMessage,
  value,
  validateWordCount = false,
  maxWords,
}) {
  const [focus, setFocus] = useState(false);
  function validateOnChange(e) {
    if (validateWordCount) {
      let enteredValue = e.target.value
      let valueWordLength = getWordCountWithExludedWords(enteredValue)
      if (valueWordLength  > maxWords) {
        errorToast(`Max number of words is ${maxWords}!`);
        if(enteredValue.length > value.length)  return;
      }
    }
    onChange(e);
  }

  return (
    <div
      className={`inputWrapper ${
        validateWordCount ? "position-relative" : ""
      }`}
    >
      {label && (
        <label htmlFor={inputProps.id}>
          {label}
          {inputProps?.required ? <span>*</span> : ""}
        </label>
      )}
      <textarea
        {...inputProps}
        value={value || ""}
        onChange={(e) => validateOnChange(e)}
        onBlur={() => {
          setFocus(true);
        }}
        focus={focus.toString()}
      />
      {validateWordCount && (
        <CharacterCounter value={value.trim() != "" ? getWordCountWithExludedWords(value)  : 0} maxWords={maxWords} />
      )}
      <span>{errorMessage}</span>
    </div>
  );
}

export default Textarea;
