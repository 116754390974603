import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Protector from "../../UI/Protector";
import { AuthContext } from "../../store/AuthContext";
import { ReviewerApplicationStatus, UserRole } from "../../Enums";
import {
  AcceptReviewerApplication,
  GetReviewerApplicationById,
  GetReviewerApplicationsById,
  RejectReviewerApplication,
} from "../../endpoints";
import Preloader from "../../UI/Preloader/Preloader";
import "react-toastify/dist/ReactToastify.css";
import RejectReviewerApplicationModal from "../../UI/Modal/RejectReviewerApplicationModal";
import {
  errorToast,
  successToast,
} from "../../UI/HelperFunctions/HelperFunctions";
import Input from "../../components/Inputs/Input";
import Textarea from "../../components/Inputs/Textarea";
import Select from "../../components/Inputs/Select";
import "./Editors.css";
import ButtonWithLoader from "../../UI/ButtonWithLoader/ButtonWithLoader";

const ViewReviewerApplication = () => {
  const { id } = useParams();
  const { userObj } = useContext(AuthContext);
  const [isReady, setIsReady] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [rejectComment, setRejectComment] = useState();
  const [applicationData, setApplicationData] = useState({});
  const cvDownloadRef = useRef(null);
  const [isAccepting, setIsAccepting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  useEffect(() => {
    if (
      userObj.role !== UserRole.Editor &&
      userObj.role !== UserRole.EditorInChief
    )
      return;
    GetReviewerApplicationById(id).then((res) => {
      setApplicationData({
        ...res.data,
      });
      setIsReady(true);
    });
  }, []);

  const handleAccept = () => {
    setIsAccepting(true);
    AcceptReviewerApplication(id)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          setApplicationData({
            ...applicationData,
            statusId: ReviewerApplicationStatus.Accepted,
            acceptedBy: `${userObj.firstName} ${userObj.lastName}`,
            acceptedOn: new Date().toJSON(),
          });
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsAccepting(false));
  };

  const handleReject = () => {
    setIsRejecting(true);
    toggleModal();
    RejectReviewerApplication(id, rejectComment)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          toggleModal();
          setApplicationData({
            ...applicationData,
            statusId: ReviewerApplicationStatus.Rejected,
            rejectedComment: rejectComment || "",
            rejectedBy: `${userObj.firstName} ${userObj.lastName}`,
            rejectedOn: new Date().toJSON(),
          });
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRejecting(false));
  };

  const toggleModal = () => {
    if (showModal) {
      setShowModal(false);
      document.body.style.overflow = "unset";
    } else {
      setShowModal(true);
      setRejectComment("");
      document.body.style.overflow = "hidden";
    }
  };

  return (
    <Protector
      isAuthorized={
        userObj.role === UserRole.EditorInChief ||
        userObj.role === UserRole.Editor
      }
    >
      <RejectReviewerApplicationModal
        show={showModal}
        toggleModal={toggleModal}
        handleReject={handleReject}
        setRejectComment={setRejectComment}
        rejectComment={rejectComment}
        isSaving={isRejecting}
      />
      <Preloader isReady={isReady}>
        <h2 className="boardHeader">View Reviewer Application</h2>
        <form className="editProfileForm  d-flex flex-column gap15 w-100 p-2">
          <div className="d-flex align-items-start gap10 flex-wrap">
            <div className="inputWrapper">
              <Input
                key={"applicationId"}
                inputProps={{
                  type: "text",
                  name: "applicationId",
                  disabled: true,
                }}
                value={applicationData && `#${applicationData.id?.slice(-8)}`}
                label={"Application ID"}
              />
            </div>
            <div className="inputWrapper">
              <Input
                key={"applicationDate"}
                inputProps={{
                  type: "text",
                  name: "applicationDate",
                  disabled: true,
                }}
                value={
                  applicationData && applicationData.created?.substring(0, 10)
                }
                label={"Application Date"}
              />
            </div>
            <div className="inputWrapper">
              <Input
                key={"email"}
                inputProps={{
                  type: "email",
                  name: "email",
                  disabled: true,
                }}
                value={
                  applicationData && applicationData.createdByUser
                    ? applicationData.createdByUser.email
                    : null
                }
                label={"Email Address"}
              />
            </div>
          </div>
          <div className="d-flex align-items-start gap10 flex-wrap">
            <Input
              key={"firstName"}
              inputProps={{
                type: "text",
                name: "firstName",
                disabled: true,
              }}
              value={
                applicationData && applicationData.createdByUser
                  ? applicationData.createdByUser.firstName
                  : null
              }
              label={"First Name"}
            />
            <Input
              key={"middleName"}
              inputProps={{
                type: "text",
                name: "middleName",
                disabled: true,
              }}
              value={
                applicationData && applicationData.createdByUser
                  ? applicationData.createdByUser.middleName
                  : null
              }
              label={"Middle Name"}
            />
            <Input
              key={"lastName"}
              inputProps={{
                type: "text",
                name: "lastName",
                disabled: true,
              }}
              value={
                applicationData && applicationData.createdByUser
                  ? applicationData.createdByUser.lastName
                  : null
              }
              label={"Last Name"}
            />
          </div>
          <div className="d-flex align-items-start gap10 flex-wrap">
            <Input
              key={"title"}
              inputProps={{
                type: "text",
                name: "title",
                disabled: true,
              }}
              value={
                applicationData && applicationData.createdByUser
                  ? applicationData.createdByUser.title
                  : null
              }
              label={"Title"}
            />
            <Input
              key={"universityOrOrganization"}
              inputProps={{
                type: "text",
                name: "universityOrOrganization",
                disabled: true,
              }}
              value={
                applicationData && applicationData.createdByUser
                  ? applicationData.createdByUser.universityOrOrganization
                  : null
              }
              label={"University/Organization"}
            />

            <Input
              key={"department"}
              inputProps={{
                type: "text",
                name: "department",
                disabled: true,
              }}
              value={
                applicationData && applicationData.createdByUser
                  ? applicationData.createdByUser.department
                  : null
              }
              label={"Department"}
            />
          </div>
          <div className="d-flex gap10 flex-wrap">
            <div className="inputWrapper">
              <Input
                key={"addressLine1"}
                inputProps={{
                  type: "text",
                  name: "addressLine1",
                  disabled: true,
                }}
                value={
                  applicationData && applicationData.createdByUser
                    ? applicationData.createdByUser.addressLine1
                    : null
                }
                label={"Address Line 1"}
              />
            </div>
            <div className="inputWrapper">
              <Input
                key={"addressLine2"}
                inputProps={{
                  type: "text",
                  name: "addressLine2",
                  disabled: true,
                }}
                value={
                  applicationData && applicationData.createdByUser
                    ? applicationData.createdByUser.addressLine2
                    : null
                }
                label={"Address Line 2"}
              />
            </div>
          </div>
          <div className="d-flex gap10 flex-wrap">
            <Select
              key={"countryId"}
              inputProps={{
                type: "text",
                title: "countryId",
                disabled: true,
              }}
              defaultValue={
                applicationData && applicationData.createdByUser
                  ? applicationData.createdByUser.country["name"]
                  : null
              }
              label={"Country"}
            />
            <Input
              key={"city"}
              inputProps={{
                type: "text",
                name: "city",
                disabled: true,
              }}
              label={"City"}
              value={
                applicationData && applicationData.createdByUser
                  ? applicationData.createdByUser.city
                  : null
              }
            />
            <Input
              key={"zipCode"}
              inputProps={{
                type: "number",
                name: "zipCode",
                disabled: true,
              }}
              label={"Zip Code"}
              value={
                applicationData && applicationData.createdByUser
                  ? applicationData.createdByUser.zipCode
                  : null
              }
            />
          </div>
          <div className="d-flex gap10 flex-wrap">
            <Input
              key={"phoneNo"}
              inputProps={{
                type: "text",
                name: "phoneNo",
                disabled: true,
              }}
              value={
                applicationData && applicationData.createdByUser
                  ? applicationData.createdByUser.phoneNo
                  : null
              }
              label={"Phone Number"}
            />
            <Input
              key={"website"}
              inputProps={{
                type: "text",
                name: "website",
                disabled: true,
              }}
              value={
                applicationData && applicationData.createdByUser
                  ? applicationData.createdByUser.website
                  : null
              }
              label={"Website"}
            />
          </div>
          <div className="d-flex gap10 flex-wrap">
            <Textarea
              key={"biography"}
              inputProps={{
                type: "text",
                placeholder: "",
                name: "biography",
                disabled: true,
              }}
              value={
                applicationData && applicationData.createdByUser
                  ? applicationData.createdByUser.biography
                  : null
              }
              label={"Biography"}
            />
            <Textarea
              key={"interests"}
              inputProps={{
                type: "text",
                placeholder: "",
                name: "interests",
                disabled: true,
              }}
              value={
                applicationData && applicationData.createdByUser
                  ? applicationData.createdByUser.interests
                  : null
              }
              label={"Interests"}
            />
          </div>

          <div className="d-flex align-items-start gap10 flex-wrap">
            <div className="inputWrapper">
              <label htmlFor="">CV</label>
              <div
                className="d-flex align-items-center justify-content-between form-control"
                style={{ border: "1px solid var(--main05)" }}
              >
                <div className="d-flex">
                  <i className="bi bi-files"></i>
                  <span className="px-3" style={{ color: "black" }}>
                    {applicationData.reviewerCV?.cvFileName}
                  </span>
                </div>

                <div className="d-flex align-items-center">
                  <i
                    className="bi bi-download download"
                    onClick={() => cvDownloadRef.current.click()}
                  ></i>
                </div>
              </div>
            </div>

            <div className="inputWrapper">
              {applicationData.createdByUser?.profilePhoto?.split(",")[1] && (
                <>
                  <label htmlFor="">Profile Photo</label>
                  <div
                    className=" form-control"
                    style={{ border: "1px solid var(--main05)" }}
                  >
                    <img
                      src={`data:image;base64,${
                        applicationData.createdByUser.profilePhoto?.split(
                          ","
                        )[1]
                      }`}
                      alt="Profile"
                      className="profile-photo"
                      width={50}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="d-flex align-items-start gap10 flex-wrap">
            <div className="col-md-6 col-12">
              <label htmlFor="">Reviewer Comment</label>
              <p className="fw-normal form-control">
                {applicationData.reviewerComment}
              </p>
            </div>
          </div>
        </form>

        {applicationData.statusId === ReviewerApplicationStatus.Requested && (
          <div
            className="d-flex justify-content-end py-2"
            style={{ gap: "10px" }}
          >
            <ButtonWithLoader
              onClick={handleAccept}
              isLoading={isAccepting}
              buttonProps={{ disabled: isAccepting || isRejecting }}
            >
              Accept as Reviewer
            </ButtonWithLoader>
            <ButtonWithLoader
              onClick={() => toggleModal()}
              isLoading={isRejecting}
              buttonProps={{ disabled: isAccepting || isRejecting }}
            >
              Reject
            </ButtonWithLoader>
          </div>
        )}
        {applicationData.statusId == ReviewerApplicationStatus.Rejected && (
          <>
            <div className="py-2 mt-3 text-danger text-center">
              This application has already been rejected
            </div>
            <div className="flex-center mt-2 rejectedInfo">
              <div className="mt-2 d-flex mt-2 d-flex justify-content-sm-evenly justify-content-center flex-wrap gap10 applicationResultContainer ">
                {applicationData.rejectedBy && (
                  <div>
                    <span className="">Rejected by:</span>
                    <p>{applicationData.rejectedBy}</p>
                  </div>
                )}
                {applicationData.rejectedComment && (
                  <div>
                    <span className="">Rejected comment:</span>
                    <p>{applicationData.rejectedComment}</p>
                  </div>
                )}
                {applicationData.rejectedOn && (
                  <div>
                    <span className="">Rejected On:</span>
                    <p>{applicationData.rejectedOn?.substring(0, 10)}</p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {applicationData.statusId == ReviewerApplicationStatus.Accepted && (
          <>
            <div className="py-2 mt-3 text-success text-center">
              This application has already been accepted
            </div>
            <div className="flex-center mt-2 rejectedInfo">
              <div className="mt-2 d-flex justify-content-sm-evenly justify-content-center flex-wrap gap10">
                {applicationData.acceptedBy && (
                  <div>
                    <span className="">Accepted by:</span>
                    <p>{applicationData.acceptedBy}</p>
                  </div>
                )}

                {applicationData.acceptedOn && (
                  <div>
                    <span className="">Accepted On:</span>
                    <p>{applicationData.acceptedOn?.substring(0, 10)}</p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        <a
          ref={cvDownloadRef}
          href={`data:${applicationData?.reviewerCV?.cvFileType};base64,${applicationData.reviewerCV?.cvFile}`}
          download={applicationData.reviewerCV?.cvFileName}
          style={{ display: "none" }}
        ></a>
      </Preloader>
    </Protector>
  );
};

export default ViewReviewerApplication;
