import React, { useEffect, useState } from "react";
import { GetLastRoundReviewsList } from "../../../endpoints";
import { useParams } from "react-router-dom";
import ButtonWithLoader from "../../../UI/ButtonWithLoader/ButtonWithLoader";
import Modal from "../../../UI/Modal/Modal";
import ModalBodyComponent from "../../../UI/Modal/ModalBodyComponent";
import ReviewListItemModal from "../../../UI/ReviewersList/ReviewListItemModal";
import Preloader from "../../../UI/Preloader/Preloader";

function ViewFeedbackButton() {
  const { articleId } = useParams();
  const [reviewList, setReviewList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showReviewsModal, setShowReviewsModal] = useState(false);

  function DisplayLastRoundReviews() {
    setIsLoading(true);
    setShowReviewsModal(true);
    GetLastRoundReviewsList(articleId)
      .then((res) => {
        setReviewList(res.data.lastRoundReviews);
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <>
      <ButtonWithLoader
        buttonProps={{ disabled: isLoading }}
        onClick={DisplayLastRoundReviews}
        extraClasses={["outline-btn","mainBtn-outlined"]}
      >
        View Feedback
      </ButtonWithLoader>
      <Modal
        opened={showReviewsModal}
        onClose={() => setShowReviewsModal(false)}
      >
        <ModalBodyComponent title={"This Round's Feedback"}>
          <Preloader isReady={!isLoading} minHeight="auto">
            {reviewList.map((review, i) => (
              <ReviewListItemModal
                disableFunctionality={true}
                review={review}
                index={i}
                key={review.id}
              />
            ))}
          </Preloader>
          <div
            className="modal-footer d-flex align-items-center justify-content-end gap10"
            style={{ paddingBottom: "0px" }}
          >
            <ButtonWithLoader
              onClick={() => setShowReviewsModal(false)}
              extraClasses={["negative"]}
            >
              Close
            </ButtonWithLoader>
          </div>
        </ModalBodyComponent>
      </Modal>
    </>
  );
}

export default ViewFeedbackButton;
