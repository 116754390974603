import React, { useEffect, useState } from "react";
import { GetArticleReviews } from "../../endpoints";
import ReviewListItem from "./ReviewListItem";
import "./ReviewersList.css";
import Preloader from "../Preloader/Preloader";

function ReviewsList({ articleId, articleStatus }) {
  const [reviewList, setReviewList] = useState([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    GetArticleReviews(articleId)
      .then((res) => {
        setReviewList(res.data);
      })
      .finally((res) => setIsReady(true));
  }, []);

  return (
    <Preloader isReady={isReady} minHeight="80px">
      {reviewList?.round1Reviews?.length > 0 && (
        <div className="articleReviewsMainWrapper">
          <ReviewListItem
            reviews={reviewList.round1Reviews}
            articleStatus={articleStatus}
            round={1}
            authorResponseFile={reviewList.authorResponseForRound1}
          />
          {reviewList?.round2Reviews?.length > 0 && (
            <ReviewListItem reviews={reviewList.round2Reviews} round={2} authorResponseFile={reviewList.authorResponseForRound2}/>
          )}
          {reviewList?.round3Reviews?.length > 0 && (
            <ReviewListItem reviews={reviewList.round3Reviews} round={3} authorResponseFile={reviewList.authorResponseForRound3} />
          )}
        </div>
      )}
    </Preloader>
  );
}

export default ReviewsList;
