import React, { useContext, useEffect, useState } from "react";
import Protector from "../../UI/Protector";
import { AuthContext } from "../../store/AuthContext";
import { ArticleStatus, UserRole } from "../../Enums";
import { GetSubmittedArticlesList } from "../../endpoints";
import CustomizableTable from "../../UI/Tables/CustomizableTable";
import { articleStatus } from "../../UI/HelperFunctions/HelperFunctions";
import Preloader from "../../UI/Preloader/Preloader";
import { SettingsContext } from "../../store/SettingsContext";

function getAuthors(authors) {
  let authorsSting = "";

  authors.map((author, i) => {
    const authorString = `${author.firstName} ${author.lastName}${
      i !== authors.length - 1 ? ", " : ""
    } `;
    authorsSting += authorString;
  });
  return authorsSting;
}
function getBtnText(id) {
  return "Manage Article";
}

function getBtnClass(id) {}

const getBtnLink = (statusId, id) => {
  return `/manage-articles/${id}`;
};

const ManageArticles = () => {
  const [tableData, setTableData] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [paginationData, setPaginationData] = useState();
  const [newPageNumber, setNewPageNumber] = useState(1);
  const { hasArticleTypes, hasTopics } = useContext(SettingsContext);

  const tableHeaders = [
    { value: "Article Title" },
    { value: "Author/s" },
    ...(hasArticleTypes ? [{ value: "Type" }] : []),
    ...(hasTopics ? [{ value: "Topic" }] : []),
    { value: "Submitted Date" },
    { value: "Status" },
  ];

  useEffect(() => {
    GetSubmittedArticlesList(newPageNumber)
      .then((res) => {
        const copiedArray = res.data.articles.items?.map((obj) => ({
          title: { type: "text", value: obj.title },
          authors: { type: "text", value: getAuthors(obj.articleAuthors) },
          ...(hasArticleTypes
            ? { type: { type: "text", value: obj.articleType } }
            : []),
          ...(hasTopics
            ? { topic: { type: "text", value: obj.sectionResearchTopic } }
            : []),
          submittedDate: {
            type: "text",
            value: obj.submittedDate?.substring(0, 10),
          },
          status: {
            type: "text",
            value: articleStatus(obj.statusId),
            classes: getBtnClass(obj.statusId),
          },
          buttonId: {
            type: "link",
            text: getBtnText(obj.statusId),
            link: getBtnLink(obj.statusId, obj.id),
          },
        }));
        setPaginationData({
          pageNumber: res.data.articles.pageNumber,
          totalCount: res.data.articles.totalCount,
          totalPages: res.data.articles.totalPages,
          pageNumber: res.data.articles.pageNumber,
          hasPreviousPage: res.data.articles.hasPreviousPage,
          hasNextPage: res.data.articles.hasNextPage,
        });
        setTableData(copiedArray?.map((obj) => Object.values(obj)));
      })
      .finally(() => setIsReady(true));
  }, [newPageNumber]);

  const { userObj } = useContext(AuthContext);

  return (
    <Protector
      isAuthorized={[UserRole.EditorInChief, UserRole.Editor].includes(
        userObj.role
      )}
    >
      <h2 className="boardHeader">Manage Articles</h2>
      <Preloader isReady={isReady} minHeight="100%">
        <CustomizableTable
          tableData={tableData}
          tableHeaders={tableHeaders}
          setNewPageNumber={setNewPageNumber}
          paginationData={paginationData}
        />
      </Preloader>
    </Protector>
  );
};

export default ManageArticles;
