import "./App.css";
import { createGlobalStyle } from "styled-components";
import { journalColor } from "./JournalSettings";
import { Await, createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout, { loader as AuthLoader } from "./pages/RootLayout";
import Home from "./pages/Home";
import LoginLayout from "./pages/LoginLayout";
import Login from "./components/Authentication/Login";
import Register from "./components/Authentication/Register";
import SubmittedArticles from "./components/Author/SubmittedArticles";
import ReviewersApplications from "./components/Editor-ChiefEditor/ReviewersApplications";
import EditProfile from "./pages/EditProfile";
import ChangePassword from "./pages/ChangePassword";
import SubmitArticleAuthorInformation from "./components/Author/SubmitArticle/SubmitArticleAuthorInformation";
import SubmitArticleLayout from "./pages/SubmitArticleLayout";
import SubmitArticleArticleInformation from "./components/Author/SubmitArticle/SubmitArticleArticleInformation";
import SubmitArticleUploadArticleInformation from "./components/Author/SubmitArticle/SubmitArticleUploadArticleInformation";
import SubmitArticleConfirmation from "./components/Author/SubmitArticle/SubmitArticleConfirmation";
import ViewReviewerApplication from "./components/Editor-ChiefEditor/ViewReviewerApplication";
import ManageArticles from "./components/Editor-ChiefEditor/ManageArticles";
import { ToastContainer } from "react-toastify";
import BecomeReviewer from "./components/Author/BecomeReviewer";
import CompletedEvaluationsRequests from "./components/Reviewer/CompletedEvaluationsRequests";
import PendingEvaluationsRequests from "./components/Reviewer/PendingEvaluationsRequests";
import PendingEvaluationsModal from "./components/Modals/PendingEvaluationsModal";
import { Suspense } from "react";
import SuspenseFallback from "./UI/SuspenseFallback/SuspenseFallback";
import ErrorLayout from "./pages/ErrorLayout";
import Reviewers from "./components/Editor-ChiefEditor/ReviewersList";
import ForgotPasswordPage from "./components/Authentication/ForgotPassword";
import ResetPasswordPage from "./components/Authentication/ResetPassword";
import ViewReviewer from "./components/Editor-ChiefEditor/ViewReviewer";
import ManageArticleViewArticle from "./components/Editor-ChiefEditor/ManageArticleViewArticle";
import SubmittedArticlesViewArticle from "./components/Author/SubmittedArticlesViewArticle";
import CompletedEvaluationsViewEvaluation from "./components/Reviewer/CompletedEvaluationsViewEvaluation";

const GlobalStyles = createGlobalStyle`
:root {
  --main800:rgba(${journalColor}, 1);
  --main01:rgba(${journalColor}, 0.1);
  --main05:rgba(${journalColor}, 0.5);
  --main08:rgba(${journalColor}, 0.8);
  --mainLighterAccent: #0070a8;
  --statusPending:rgb(55, 134, 166);
  --statusRejected:rgb(232, 58, 46);
  --statusAccepted:rgb(12, 204, 95);
  --backgroundColorLogin: #f7f3ec;
  --inputBorderColor: #;
  --red:#c42121;
  --inputBorderColorFocus: rgba(87, 98, 127, 0.7);
  --black: #000000;
  --grayish: #dadada;
  --mainNavHover: white;
  --paddingLeftRight: 5%;
  --footerBackground: #333;
  --textColor: #1a1a1a;
  --textMuted: #a1a1a1;
}`;

let routes = createBrowserRouter([
  {
    path: "",
    element: <RootLayout />,
    errorElement: <ErrorLayout />,
    loader: AuthLoader,
    children: [
      {
        index: true,
        element: <Home />,
      },
      { path: "/edit-profile", element: <EditProfile /> },
      { path: "/change-password", element: <ChangePassword /> },
      {
        path: "/submitted-articles",
        element: <SubmittedArticles />,
      },
      {
        path: "/submitted-articles/:articleId",
        element: <SubmittedArticlesViewArticle />,
      },
      {
        path: "/submit-article/:articleId",
        element: <SubmitArticleLayout />,
        children: [
          {
            path: "/submit-article/:articleId/article-information",
            index: true,
            element: <SubmitArticleArticleInformation />,
          },
          {
            path: "/submit-article/:articleId/author-information",
            element: <SubmitArticleAuthorInformation />,
          },
          {
            path: "/submit-article/:articleId/upload-article",
            element: <SubmitArticleUploadArticleInformation />,
          },
          {
            path: "/submit-article/:articleId/confirm",
            element: <SubmitArticleConfirmation />,
          },
        ],
      },
      {
        path: "/reviewer-application",
        element: <BecomeReviewer />,
      },

      {
        path: "/reviewers-applications",
        element: <ReviewersApplications />,
      },
      {
        path: "/view-reviewer-application/:id",
        element: <ViewReviewerApplication />,
      },
      {
        path: "/manage-articles",
        element: <ManageArticles />,
      },
      {
        path: "/manage-articles/:articleId",
        element: <ManageArticleViewArticle />,
      },
      {
        path: "/completed-evaluations-requests",
        element: <CompletedEvaluationsRequests />,
      },
      {
        path: "/completed-evaluations-requests/:evaluationId",
        element: <CompletedEvaluationsViewEvaluation />,
      },
      {
        path: "/pending-evaluations-requests",
        element: <PendingEvaluationsRequests />,
      },
      {
        path: "/pending-evaluations-requests/:id",
        element: <PendingEvaluationsModal />,
      },
      {
        path: "/reviewers",
        element: <Reviewers />,
      },
      {
        path: "/reviewers/:id",
        element: <ViewReviewer />,
      },
    ],
  },
  {
    path: "/auth",
    element: <LoginLayout />,
    // errorElement: <ErrorLayout />,
    children: [
      {
        path: "/auth/login",
        element: <Login />,
      },
      {
        path: "/auth/register",
        element: <Register />,
      },
      { path: "/auth/forgot-password", element: <ForgotPasswordPage /> },
      { path: "/auth/reset-password/:token", element: <ResetPasswordPage /> },
    ],
  },
]);

function App() {
  return (
    <>
      <GlobalStyles />
      <ToastContainer />
      <Suspense fallback={<SuspenseFallback />}>
        <Await
          resolve={AuthLoader()}
          errorElement={<p>Error occurred loading data</p>}
        >
          <RouterProvider router={routes} location />
        </Await>
      </Suspense>
    </>
  );
}

export default App;
