import React, { useState } from "react";
import "./Table.css";
import { articleStatus } from "../HelperFunctions/HelperFunctions";

function SingleRowTable({ tableHeaders, tableData }) {
  return (
    <div className="table-container singleRowTable">
      <table>
        <thead>
          <tr>
            {tableHeaders.map((tableHeader, index) => {
              {
                return <th key={index}>{tableHeader.value}</th>;
              }
            })}
          </tr>
        </thead>
        {tableData && (
          <tbody>
            <tr>
              <td data-cell="Article ID">#{tableData.id?.slice(-8)}</td>
              <td data-cell="Title">{tableData.title}</td>
              <td data-cell="Submitted Date">
                {tableData.submittedDate?.substring(0, 10) || tableData.created?.substring(0, 10) || 
                  "Not submitted yet"}
              </td>
              <td data-cell="Status">{articleStatus(tableData.statusId)}</td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
}

export default SingleRowTable;
