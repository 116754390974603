import React, { useContext, useEffect, useState } from "react";
import VolatileFileInput from "../../UI/VolatileFileInput";
import { GetPublishedArticleFile, GetVolumesList } from "../../endpoints";
import Select from "../Inputs/Select";
import { useParams } from "react-router-dom";
import Preloader from "../../UI/Preloader/Preloader";
import { SettingsContext } from "../../store/SettingsContext";

function PublishFileComponent({
  publishArticleData,
  setPublishArticleData,
  setIsFetchingPublishedData,
  isFetchingPublishedData,
  setHasPublishedFile,
}) {
  const { articleId } = useParams();
  const [volumeOptions, setVolumeOptions] = useState([]);
  const { hasVolumes } = useContext(SettingsContext);

  useEffect(() => {
    GetVolumesList().then((res) => {
      setVolumeOptions(res.data.journalVolumes);
    });
    GetPublishedArticleFile(articleId).then((res) => {
      setPublishArticleData({
        publishedPDF: res.data.publishedArticle,
        volumeId: res.data.volumeId,
      });
      if (res.data.publishedArticle) {
        setHasPublishedFile(true);
      }
      setIsFetchingPublishedData(false);
    });
  }, []);

  function handleChange(e) {
    if (e.target.name == "volumeId") {
      setPublishArticleData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    } else {
      setPublishArticleData((prev) => ({
        ...prev,
        [e.target.name]: e.target.files[0],
      }));
    }
  }
  return (
    <Preloader isReady={!isFetchingPublishedData} minHeight="auto">
      <div className="publishFileContainer">
        <VolatileFileInput
          setFile={setPublishArticleData}
          fileObj={publishArticleData.publishedPDF}
          label={
            "Upload Article PDF that will be available for view/download by Journal users"
          }
          fileName={
            publishArticleData.publishedPDF?.articleFileName ||
            publishArticleData.publishedPDF?.name
          }
          fileBytes={
            publishArticleData.publishedPDF?.articleFile ||
            publishArticleData.publishedPDF?.size
          }
          fileType={
            publishArticleData.publishedPDF?.articleFileType ||
            publishArticleData.publishedPDF?.type
          }
          apiPropertyName={"publishedPDF"}
          onChange={handleChange}
          triggerConversionToBytes={publishArticleData.publishedPDF?.name}
          setHasPublishedFile={setHasPublishedFile}
        />
        {hasVolumes && (
          <Select
            options={volumeOptions}
            value={publishArticleData.volumeId}
            defaultValue={"No Volume"}
            inputProps={{
              name: "volumeId",
              id: "volumeId",
            }}
            label={"Select Volume"}
            onChange={handleChange}
          />
        )}
      </div>
    </Preloader>
  );
}

export default PublishFileComponent;
