import React, { useState } from "react";
import { getReviewerRecomendation } from "../HelperFunctions/HelperFunctions";
import { ArticleReviewStatus, ArticleStatus } from "../../Enums";

const getRejectedStatusId = (status) => {
  if (status === ArticleStatus.ReviewedR1)
    return ArticleReviewStatus.RejectedR1;
  if (status === ArticleStatus.ReviewedR2)
    return ArticleReviewStatus.RejectedR2;
  if (status === ArticleStatus.ReviewedR3)
    return ArticleReviewStatus.RejectedR3;
};

const ReviewListItemModal = ({
  review,
  index,
  articleStatus,
  dispatchReviewsOfLastRound,
  disableFunctionality = false,
}) => {
  const [isOpenAccordion, setIsOpenAccordion] = useState(false);

  function toggleAccordion() {
    setIsOpenAccordion((prev) => !prev);
  }

  function handleChangeStatus(status) {
    dispatchReviewsOfLastRound({
      type: "EDIT_REVIEW_STATUS",
      id: review.id,
      reviewStatus: status,
    });
  }

  return (
    <div className={`reviewContainer ${isOpenAccordion ? "open" : ""}`}>
      <button
        className={`accordionHeader py-0
        ${review.statusId < 0 ? "rejected text-white" : ""}
      `}
        type="button"
      >
        <div
          className="d-flex align-items-center py-2"
          style={{ flex: "1" }}
          onClick={toggleAccordion}
        >
          <i className="bi bi-chevron-right me-1"></i>
          <h3>Review {index + 1}</h3>
        </div>
        {!disableFunctionality && (
          <div className="text-end">
            {review.statusId === ArticleReviewStatus.Completed && (
              <div
                className="changeStatusBtn text-danger"
                onClick={() =>
                  handleChangeStatus(getRejectedStatusId(articleStatus))
                }
              >
                Reject
              </div>
            )}
            {review.statusId < 0 && (
              <div
                className="changeStatusBtn"
                onClick={() =>
                  handleChangeStatus(ArticleReviewStatus.Completed)
                }
              >
                Accept
              </div>
            )}
          </div>
        )}
      </button>
      <div className="accordionBody">
        <div>
          <div className="reviewInfoWrapper">
            <div className="d-flex">
              <b>Reviewer:</b>
              <span>{review.reviewerName}</span>
            </div>
            <div className="d-flex">
              <b>Recommendation:</b>
              <span>
                {getReviewerRecomendation(
                  review.reviewedRank || review.recommendation
                )}
              </span>
            </div>
            <div className="d-flex">
              <b>Comment to Authors:</b>
              <div
                dangerouslySetInnerHTML={{
                  __html: review.commentToAuthors,
                }}
              ></div>
            </div>
            <div className="d-flex editorComment">
              <b>Comment to Editors:</b>
              <div
                dangerouslySetInnerHTML={{
                  __html: review.commentToEditors,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewListItemModal;
