import React, { useRef } from "react";
import { baseURL } from "../../JournalSettings";

function StyledDownloadableFileComponent({
  file,
  label,
  styles = [],
  resetMargins,
}) {
  const downloadRef = useRef(null);
  return (
    <div
      className={`uploadedArticleContainer mb-4 ${styles.join(" ")}`}
      style={{ margin: "0 !important" }}
    >
      <span className="d-flex align-items-center gap10">
        {label && <span style={{ minWidth: "170px" }}>{label}</span>}
        <b>
          <div
            className="d-flex align-items-center justify-content-between form-control"
            style={{ border: "1px solid var(--main05)" }}
          >
            <i className="bi bi-files" style={{ marginRight: "1rem" }}></i>
            {file.name}
            <a
              ref={downloadRef}
              href={`${baseURL}/Article/DownloadArticleFile?articleFileId=${file.id}`}
              target="_blank"
            >
              <i className="bi bi-download download"></i>
            </a>
          </div>
        </b>
      </span>
    </div>
  );
}

export default StyledDownloadableFileComponent;
