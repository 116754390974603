import React, { useContext, useRef, useState } from 'react';
import { FilePond,registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { baseURL } from '../../JournalSettings';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { SubmitArticleContext } from '../../store/SubmitArticleContext';

registerPlugin(FilePondPluginFileValidateSize);



function FilePondUploader({name,apiURL,label,articleId,uploadedFile,fileType, required=true}) {

    const initialFileState = uploadedFile != null ? [{
        source: '12331',
        options: {
            type: 'local',
            file: {
                name: uploadedFile.name,
            },
        },
    }] : null
    const [files, setFiles] = useState(initialFileState);
    const [fileId,setFileId] = useState(uploadedFile?.id)
    const [isProccessing,setIsProcessing] = useState(false)
    const {setIsFileUploading} = useContext(SubmitArticleContext)
    const downloadFileRef = useRef(null)
    return (
        <>
            <FilePond
                files={files}
                onupdatefiles={setFiles}
                allowMultiple={false}
                checkValidity={true}
                maxFileSize={"100mb"}
                credits={false}
                server={{
                    url:baseURL,
                    process:{
                        url:apiURL,
                        ondata:(formData)=>{
                           formData.append("articleId",articleId)
                           formData.append("fileType",fileType)
                           return formData
                        },
                        headers:{
                            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                        }
                    }
                }}
                name={name}
                labelIdle={label}
                onaddfilestart={e=>{
                    console.log("started")
                    setIsProcessing(true)
                    setIsFileUploading(true)
                }}
                onactivatefile={(file)=> {
                    if(isProccessing) return
                    downloadFileRef.current.click()
                }}
                onerror={(e)=>setIsFileUploading(false)}
                onprocessfile={(e,file)=>{
                  const fileId = JSON.parse(file.serverId)?.fileId
                  setIsProcessing(false)
                  setIsFileUploading(false)
                  setFileId(fileId)
                }}
                required={required}
            />

            <a className='d-none' href={`${baseURL}/Article/DownloadArticleFile?articleFileId=${fileId}`} target='_blank' ref={downloadFileRef}>
            </a>
            </>
    );
}


export default FilePondUploader
