import React, { useContext } from "react";
import ManageArticles from "./ManageArticles";
import Card from "./Card";
import { useEffect } from "react";
import { GetSubmittedArticlesList, getAdminDashboard } from "../../endpoints";
import { useState } from "react";
import Chart from "../../UI/Charts/Chart";
import Preloader from "../../UI/Preloader/Preloader";
import ManageArticlesDashboard from "./ManageArticlesDashboard";
import { articleStatus } from "../../UI/HelperFunctions/HelperFunctions";
import { ArticleStatus } from "../../Enums";
import { SettingsContext } from "../../store/SettingsContext";

function getAuthors(authors) {
  let authorsSting = "";

  authors.map((author, i) => {
    const authorString = `${author.firstName} ${author.lastName}${
      i !== authors.length - 1 ? ", " : ""
    } `;
    authorsSting += authorString;
  });
  return authorsSting;
}
function getBtnText(id) {
  return "Manage Article";
}

function getBtnClass(id) {}

const getBtnLink = (statusId, id) => {
  return `/manage-articles/${id}`;
};

const Dashboard = () => {
  const [values, setValues] = useState();
  const [isReady, setIsReady] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState();
  const [newPageNumber, setNewPageNumber] = useState(1);
  const [isAdminDashboardFetched, setIsAdminDashboardFetched] = useState(false);
  const { hasArticleTypes, hasTopics, hasSections } =
    useContext(SettingsContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isAdminDashboardFetched) {
          const adminDashboardRes = await getAdminDashboard();
          setValues(adminDashboardRes.data);
          setIsAdminDashboardFetched(true);
        }

        const submittedArticlesRes = await GetSubmittedArticlesList(
          newPageNumber,
          ArticleStatus.Submitted
        );

        const copiedArray = submittedArticlesRes.data.articles.items?.map(
          (obj) => ({
            title: { type: "text", value: obj.title },
            authors: { type: "text", value: getAuthors(obj.articleAuthors) },
            ...(hasArticleTypes
              ? { type: { type: "text", value: obj.articleType } }
              : []),
            ...(hasTopics
              ? { topic: { type: "text", value: obj.sectionResearchTopic } }
              : []),
            submittedDate: {
              type: "text",
              value: obj.submittedDate?.substring(0, 10),
            },
            status: {
              type: "text",
              value: articleStatus(obj.statusId),
              classes: getBtnClass(obj.statusId),
            },
            buttonId: {
              type: "link",
              text: getBtnText(obj.statusId),
              link: getBtnLink(obj.statusId, obj.id),
            },
          })
        );
        setPaginationData({
          pageNumber: submittedArticlesRes.data.articles.pageNumber,
          totalCount: submittedArticlesRes.data.articles.totalCount,
          totalPages: submittedArticlesRes.data.articles.totalPages,
          hasPreviousPage: submittedArticlesRes.data.articles.hasPreviousPage,
          hasNextPage: submittedArticlesRes.data.articles.hasNextPage,
        });
        setTableData(copiedArray?.map((obj) => Object.values(obj)));
        setIsReady(true);
      } catch (error) {
        setIsReady(true);
      }
    };

    fetchData();
  }, [newPageNumber, isAdminDashboardFetched]);

  return (
    <Preloader isReady={isReady} minHeight="100%">
      <div className="row mb-3">
        <div className="col-md-6 col-xl-3 mb-1 mt-3">
          <Card title={"Total Authors"} amount={values?.numberOfAuthors} />
        </div>
        <div className="col-md-6 col-xl-3 mb-1 mt-3">
          <Card title={"Total Reviewers"} amount={values?.numberOfReviewers} />
        </div>
        <div className="col-md-6 col-xl-3 mb-1 mt-3">
          <Card title={"Total Editors"} amount={values?.numberOfEditors} />
        </div>
        <div className="col-md-6 col-xl-3 mb-1 mt-3">
          <Card
            title={"Total Editors-in-Chief"}
            amount={values?.numberOfChiefEditors}
          />
        </div>
        <div className="col-md-6 col-xl-4 mb-1 mt-3">
          <Card
            title={"Submitted Articles"}
            amount={values?.submittedArticles}
          />
        </div>
        <div className="col-md-6 col-xl-4 mb-1 mt-3">
          <Card
            title={"Articles Under Review"}
            amount={values?.articesInReview}
          />
        </div>
        <div className="col-md-6 col-xl-4 mb-1 mt-3">
          <Card
            title={"Published Articles"}
            amount={values?.publishedArticles}
          />
        </div>
      </div>
      <div className="d-flex flex-wrap mt-3">
        {hasArticleTypes && (
          <div className={`${hasSections ? "col-lg-6" : ""} col-12`}>
            <Chart
              title={"PUBLICATIONS BY TYPES"}
              chartData={values?.articlesByType}
              datakey={"published"}
            />
          </div>
        )}
        {hasSections && (
          <div className={`${hasArticleTypes ? "col-lg-6" : ""} col-12`}>
            <Chart
              title={"PUBLICATIONS BY SECTIONS"}
              chartData={values?.articlesBySection}
              datakey={"published"}
            />
          </div>
        )}
        <div className="col-12">
          <Chart
            title={"MONTHLY PUBLICATIONS"}
            chartData={values?.articlesByMonth}
            datakey={"amount"}
          />
        </div>
      </div>
      <div className="">
        <ManageArticlesDashboard
          tableData={tableData}
          paginationData={paginationData}
          setNewPageNumber={setNewPageNumber}
        />
      </div>
    </Preloader>
  );
};

export default Dashboard;
