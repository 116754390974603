import React, { useContext, useState } from "react";
import Input from "../components/Inputs/Input";
import { UserChangePassword } from "../endpoints";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../store/AuthContext";
import {
  errorToast,
  successToast,
} from "../UI/HelperFunctions/HelperFunctions";
import ButtonWithLoader from "../UI/ButtonWithLoader/ButtonWithLoader";

const ChangePassword = () => {
  const { userObj } = useContext(AuthContext);
  const [values, setValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    userId: userObj.id,
  });
  const [isSaving, setIsSaving] = useState(false);

  function handleChange(e) {
    setValues((prev) => {
      const name = e.target.name || e.target.title;
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsSaving(true);
    UserChangePassword(values)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          setValues({
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          });
          document
            .querySelectorAll(".changePasswordForm .inputWrapper input")
            .forEach((input) => {
              input.setAttribute("focus", false);
            });
        } else {
          errorToast(res.data.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsSaving(false));
  }

  return (
    <div>
      <h2 className="boardHeader">Change Password</h2>
      <div className="d-flex align-items-center justify-content-center">
        <form
          className="changePasswordForm d-flex flex-column gap15 col-md-5 col-10 p-2"
          onSubmit={(e) => handleSubmit(e)}
        >
          <Input
            key={"oldPassword"}
            inputProps={{
              type: "password",
              name: "oldPassword",
              id: "oldPassword",
              required: true,
            }}
            label={"Old Password"}
            value={values.oldPassword}
            onChange={handleChange}
          />

          <Input
            key={"newPassword"}
            inputProps={{
              type: "password",
              name: "newPassword",
              id: "newPassword",
              required: true,
              pattern: "^.{7,}$",
            }}
            value={values.newPassword}
            label={"New Password"}
            onChange={handleChange}
            errorMessage={"Password must be longer than 6 characters"}
          />

          <Input
            key={"confirmNewPassword"}
            inputProps={{
              type: "password",
              name: "confirmNewPassword",
              id: "confirmNewPassword",
              pattern: `^${values.newPassword.replace(
                /[.*+?^${}()|[\]\\]/g,
                "\\$&"
              )}$`,
              required: true,
            }}
            value={values.confirmNewPassword}
            label={"Confirm New Password"}
            onChange={handleChange}
            errorMessage={"Passwords do not match"}
          />
          <ButtonWithLoader isLoading={isSaving} extraClasses={["ms-auto"]}>
            Submit
          </ButtonWithLoader>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
