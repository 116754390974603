import React, { useRef } from "react";
import { baseURL } from "../../../JournalSettings";

function SubmitArtifleFileComponent({ manuscript }) {

  return (
      <a
        className="text-reset"
        href={`${baseURL}/Article/DownloadArticleFile?articleFileId=${manuscript.id}`}
      >{manuscript.name}</a>
  );
}

export default SubmitArtifleFileComponent;
