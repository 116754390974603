import React, { useState } from "react";
import ButtonWithLoader from "../../UI/ButtonWithLoader/ButtonWithLoader";
import Input from "../Inputs/Input";
import { ForgotPassword } from "../../endpoints";
import {
  errorToast,
  successToast,
} from "../../UI/HelperFunctions/HelperFunctions";
import { Link } from "react-router-dom";

const ForgotPasswordPage = () => {
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    ForgotPassword(email)
      .then((res) => {
        if (res.data.success) {
          successToast(`Success! Please check your email to proceed.`);
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
  };

  return (
    <div className="w-75 authContainer card">
      <h2 className="boardHeader main800Background">Forgot Password</h2>
      <div className="forgotPasswordDiv">
        <form
          className="authForm authLogin d-flex align-items-center justify-content-center gap15 flex-column"
          onSubmit={handleSubmit}
        >
          <p>Enter your email and we will send you a link to reset password</p>
          <Input
            inputProps={{
              name: "email",
              type: "email",
              required: true,
            }}
            value={email}
            label={"Email"}
            onChange={(e) => setEmail(e.target.value)}
          />
          <ButtonWithLoader isLoading={isRequestBeingMade}>
            Continue
          </ButtonWithLoader>
          <Link to={"/auth/register"} className="mt-1 text-center">
            Don't have an Account yet? <b>Sign up</b>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
