import React from "react";
import SubmittedArticles from "./SubmittedArticles";

const AuthorHome = () => {
  return (
    <div className="mt-2">
      <SubmittedArticles showBoardHeader={false} />
    </div>
  );
};

export default AuthorHome;
