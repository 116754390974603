import React from 'react'
import Modal from '../../UI/Modal/Modal'
import ButtonWithLoader from '../../UI/ButtonWithLoader/ButtonWithLoader'

function ExpiredTokenModal({showTokenExpiredModal,handleClose}) {
  return (
    <Modal opened={showTokenExpiredModal} onClose={handleClose}>
      <div
        className="modalContent"
        onClick={(e) => e.stopPropagation()}
        style={{height:"auto"}}
      >
          <h2 className="modalHeader d-flex align-items-center justify-content-between text-center">
            Your session has expired
          </h2>
          <div className='text-center my-3'>
            <p className=''>Please Login again to continue using the app</p>
          </div>
          <div className=" d-flex justify-content-center">
            <ButtonWithLoader isLoading={false} onClick={handleClose}>
              Go back to Login
            </ButtonWithLoader>
          </div>

      </div>
    </Modal>
  )
}

export default ExpiredTokenModal