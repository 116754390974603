import React, { useState } from "react";
import { ResetPassword } from "../../endpoints";
import { successToast } from "../../UI/HelperFunctions/HelperFunctions";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../Inputs/Input";
import ButtonWithLoader from "../../UI/ButtonWithLoader/ButtonWithLoader";

const ResetPasswordPage = () => {
  const { token } = useParams();
  const [values, setValues] = useState({
    password: "",
    confirmNewPassword: "",
    token: token,
  });
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);

  const navigate = useNavigate();

  function handleChange(e) {
    setValues((prev) => {
      const name = e.target.name || e.target.title;
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsRequestBeingMade(true);
    ResetPassword(values)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          navigate("/auth/login");
        }
      })
      .finally(() => setIsRequestBeingMade(true));
  };

  return (
    <div className="w-75 authContainer card">
      <h2 className="boardHeader main800Background">Reset Password</h2>
      <div>
        <form
          className="authForm authLogin d-flex align-items-center justify-content-center gap15 flex-column"
          onSubmit={handleSubmit}
        >
          <Input
            key={"password"}
            inputProps={{
              type: "password",
              name: "password",
              id: "password",
              pattern: "^.{7,}$",
              required: true,
            }}
            value={values.password}
            onChange={handleChange}
            label={"Password"}
            errorMessage={"Password must contain at least 7 characters"}
          />
          <Input
            key={"confirmNewPassword"}
            inputProps={{
              type: "password",
              name: "confirmNewPassword",
              id: "confirmNewPassword",
              pattern: values.password,
              required: true,
            }}
            value={values.confirmNewPassword}
            onChange={handleChange}
            label={"Confirm Password"}
            errorMessage={"Passwords do not match"}
          />
          <ButtonWithLoader isLoading={isRequestBeingMade}>
            Reset
          </ButtonWithLoader>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
