import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../Footer/Footer";

function LoginLayout() {
  return (
    <div className="mainWrapper">
      <Navbar isFromLayout={false}/>
      <main className="d-flex align-items-center justify-content-center card-shadow">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default LoginLayout;
