const UserRole = {
  Admin: 1,
  Author: 2,
  Editor: 3,
  EditorInChief: 4,
  Reviewer: 5,
  ReviewerBoard: 6,
};

const ReviewerApplicationStatus = {
  NoSubmission: 0,
  Requested: 1,
  Accepted: 2,
  Rejected: 3,
};

const ArticleStatus = {
  SubmitedByChiefEditor: -1,

  Submitting: 1,
  Submitted: 2,

  InReviewR1: 3,
  ReviewedR1: 4,
  ApprovedR1: 5,
  SubmittedAfterR1: 6,

  InReviewR2: 7,
  ReviewedR2: 8,
  ApprovedR2: 9,
  SubmittedAfterReviewedR2: 10,

  InReviewR3: 11,
  ReviewedR3: 12,
  ApprovedR3: 13,
  SubmittedAfterReviewedR3: 14,

  Rejected: 15,
  Accepted: 16,
  Published: 17,
};

const ArticleReviewStatus = {
  RejectedR1: -1,
  RejectedR2: -2,
  RejectedR3: -3,
  RejectedByReviewer: -4,
  Assigned: 1,
  UnderReview: 2,
  Completed: 3,
  Pending: 4,
};

const RequestType = {
  Portal: 1,
  UiSystem: 2,
};

const AuthorRole = {
  CorrespondingAuthor: 1,
  Author: 2,
};

const ArticleRecommendation = {
  AcceptInPresentForm: 1,
  AcceptAfterMinorRevision: 2,
  ReconsiderAfterMajorRevision: 3,
  Reject: 4,
};

const CorrespondingAuthor = {
  OnlyLoggedInUser: 1,
  AnyUser: 2,
  MultipleUser: 3,
};

const ArticleFileType = {
  Manuscript: 1,
  CopyrightAgreement: 2,
  PublishedArticle: 3,
  AuthorResponse: 4,
};

const UserRoleType = {
  Author: -1,
  EditorInChief: 1,
  CoEditorInChief: 2,

  Editor: 3,
  AssociateEditor: 4,
  TechnicalEditor: 5,
  Secretariat: 6,

  Reviewer: 7,
  ReviewersBoard: 8,
};

export {
  UserRole,
  ReviewerApplicationStatus,
  ArticleReviewStatus,
  ArticleStatus,
  ArticleRecommendation,
  AuthorRole,
  RequestType,
  CorrespondingAuthor,
  ArticleFileType,
  UserRoleType,
};
