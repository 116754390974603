import React, { useContext } from 'react'
import { AuthorRole, CorrespondingAuthor } from '../../../Enums'
import { SettingsContext } from '../../../store/SettingsContext'
import Input from '../../Inputs/Input'

function CorrespondingAuthorControl({userRole,formStateDispatcher,temporaryId}) {
    const {correspondingAuthorControl} = useContext(SettingsContext)

    function onChange (e) {
      const roleId = e.target.checked ? AuthorRole.CorrespondingAuthor : AuthorRole.Author
    formStateDispatcher({type:"CHANGE_CORRESPONDING_AUTHOR",roleId:roleId, tempId:temporaryId,correspondingAuthorControl:correspondingAuthorControl})
    }
  return (
    <>
    {
       correspondingAuthorControl != CorrespondingAuthor.OnlyLoggedInUser && <Input
       inputProps={{
        type: correspondingAuthorControl == CorrespondingAuthor.AnyUser ? "radio" : "checkbox",
        name:"correspondingAuthor",
        id:`correspondingAuthor${temporaryId}`,
        checked:userRole == AuthorRole.CorrespondingAuthor
       }}
       label={"Corresponding Author"}
       onChange={onChange}
       />
    }
    </>
  )
}

export default CorrespondingAuthorControl