import React, { useEffect } from "react";
import "./Modal.css";
import Textarea from "../../components/Inputs/Textarea";

const RejectReviewerApplicationModal = ({
  show,
  toggleModal,
  handleReject,
  setRejectComment,
  rejectComment,
  isRequestBeingMade,
}) => {
  useEffect(() => {
    const handleKeyUp = (e) => {
      if (e.key === "Escape") {
        toggleModal();
      }
    };

    if (show) {
      window.addEventListener("keyup", handleKeyUp);
    }

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [show]);

  function handleChange(e) {
    setRejectComment(e.target.value);
  }
  return (
    <div
      className={`reject-comment-modal ${show ? "show" : ""}`}
      onClick={() => toggleModal()}
    >
      <div
        className="modalContent col-md-9 col-11"
        onClick={(e) => e.stopPropagation()}
      >
        <h5 className="text-dark">Rejection Comment</h5>
        <div
          className="d-flex gap10 flex-wrap text-start my-3"
          style={{ height: "auto" }}
        >
          <Textarea
            key={"rejectComment"}
            inputProps={{
              type: "text",
              placeholder: "",
              name: "rejectComment",
              id: "rejectComment",
              required: false,
            }}
            value={rejectComment}
            onChange={handleChange}
            label={"Comment"}
          />
        </div>
        <div className="modal-buttons d-flex justify-content-end">
          <button className="btn btn-light mx-3" onClick={() => toggleModal()}>
            Cancel
          </button>
          <button className="btn btn-danger" onClick={handleReject}>
            Reject
          </button>
        </div>
      </div>
    </div>
  );
};

export default RejectReviewerApplicationModal;
