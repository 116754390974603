import React, { useContext, useEffect, useState } from "react";
import Input from "../../Inputs/Input";
import Select from "../../Inputs/Select";
import Textarea from "../../Inputs/Textarea";
import CorrespondingAuthorControl from "./CorrespondingAuthorControl";
import { AuthorRole } from "../../../Enums";
import { AuthContext } from "../../../store/AuthContext";

function AuthorItem({
  countryOptions,
  temporaryId,
  formStateDispatcher,
  formState,
  authorNr,
  setSelectedTempId,
  setShowDeleteAuthorModal,
}) {
  const [authorObj, setAuthorObj] = useState(
    formState.find((el) => el.temporaryId == temporaryId)
  );

  function getUserNoOptions() {
    const authorNoOptions = [];
    for (let i = 0; i < formState.length; i++) {
      authorNoOptions.push({ id: i, name: i + 1 });
    }
    return authorNoOptions;
  }

  const {userObj} = useContext(AuthContext)

  const [triggerUseEffect, setTriggerUseEffect] = useState(true);
  const [orderNo, setOrderNo] = useState(formState.indexOf(authorObj));

  function onChange(e) {
    formStateDispatcher({ type: "EDIT_INFO", tempId: temporaryId, target: e });
    setTriggerUseEffect((prev) => !prev);
  }

  function onDelete() {
    setSelectedTempId(temporaryId);
    setShowDeleteAuthorModal(true);
  }

  function moveAuthor(e) {
    formStateDispatcher({
      type: "MOVE_AUTHOR",
      moveTo: e.target.value,
      tempId: temporaryId,
    });
    setOrderNo(e.target.value);
  }

  useEffect(() => {
    setAuthorObj(formState.find((el) => el.temporaryId == temporaryId));
  }, [triggerUseEffect]);

  useEffect(() => {
    setOrderNo(formState.indexOf(authorObj));
  }, [authorNr]);

  return (
    <div>
      {authorObj.roleId === AuthorRole.CorrespondingAuthor && (
        <span className="correspondingAuthorBadge">Corresponding Author</span>
      )}
      <div
        className={`d-flex align-items-center authorHeader ${
          authorObj.roleId === AuthorRole.CorrespondingAuthor ? "correspondingAuthorHeader" : ""
        }`}
      >
        <h3>Author {authorNr}</h3>
        <CorrespondingAuthorControl userRole={authorObj.roleId} formStateDispatcher={formStateDispatcher} temporaryId={temporaryId}/>
        <Select
          options={getUserNoOptions()}
          value={orderNo}
          inputProps={{ id: "orderNumber" }}
          onChange={moveAuthor}
          label={"Order Number"}
        />

        <button
          onClick={onDelete}
          disabled={authorObj.isAuthorLoggedIn}
          type="button"
        >
          <i className="bi bi-person-x-fill"></i>
        </button>
      </div>
      <div className="authorInputContainer">
        <Input
          key={"email"}
          inputProps={{
            type: "email",
            //placeholder: "Enter Your Email",
            name: "email",
            id: `email${orderNo}`,
            required: true,
            maxLength:"80",
            pattern: "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$",
            disabled:authorObj.isAuthorLoggedIn
          }}
          value={authorObj.email}
          onChange={onChange}
          label={"Email Address"}
        />
        <div className="d-flex align-items-start gap10 flex-wrap">
          <Input
            key={"firstName"}
            inputProps={{
              type: "text",
              // placeholder: "Enter Your First Name",
              name: "firstName",
              id: `firstName${orderNo}`,
              required: true,
              maxLength:"150",
            }}
            value={authorObj.firstName}
            onChange={onChange}
            label={"First Name"}
          />
          <Input
            key={"middleName"}
            inputProps={{
              type: "text",
              // placeholder: "Enter Your Middle Name",
              name: "middleName",
              id: `middleName${orderNo}`,
              required: false,
              maxLength:"150",
            }}
            value={authorObj.middleName}
            onChange={onChange}
            label={"Middle Name"}
          />
          <Input
            key={"lastName"}
            inputProps={{
              type: "text",
              // placeholder: "Enter Your Last Name",
              name: "lastName",
              id: `lastName${orderNo}`,
              required: true,
              maxLength:"150",
            }}
            value={authorObj.lastName}
            onChange={onChange}
            label={"Last Name"}
          />
        </div>
        <div className="d-flex align-items-start gap10 flex-wrap">
          <Input
            key={"title"}
            inputProps={{
              type: "text",
              // placeholder: "Enter Your Title",
              name: "title",
              id: `title${orderNo}`,
              required: false,
              maxLength:"150",
            }}
            value={authorObj.title}
            onChange={onChange}
            label={"Title"}
          />
          <Input
            key={"universityOrOrganization"}
            inputProps={{
              type: "text",
              // placeholder: "University/Organization",
              name: "universityOrOrganization",
              id: `universityOrOrganization${orderNo}`,
              required: true,
              maxLength:"350",
            }}
            value={authorObj.universityOrOrganization}
            onChange={onChange}
            label={"University/Organization"}
          />

          <Input
            key={"department"}
            inputProps={{
              type: "text",
              // placeholder: "Department",
              name: "department",
              id: `department${orderNo}`,
              required: false,
              maxLength:"350",
            }}
            value={authorObj.department}
            onChange={onChange}
            label={"Department"}
          />
        </div>
        <Input
          key={"addressLine1"}
          inputProps={{
            type: "text",
            // placeholder: "Address Line 1",
            name: "addressLine1",
            id: `addressLine1${orderNo}`,
          }}
          value={authorObj.addressLine1}
          onChange={onChange}
          label={"Address Line 1"}
        />
        <Input
          key={"addressLine2"}
          inputProps={{
            type: "text",
            // placeholder: "Address Line 2",
            name: "addressLine2",
            id: `addressLine2${orderNo}`,
            required: false,
          }}
          value={authorObj.addressLine2}
          onChange={onChange}
          label={"Address Line 2"}
        />
        <div className="d-flex gap10 flex-wrap">
          <Select
            key={"countryId"}
            inputProps={{
              type: "text",
              title: "countryId",
              id: `countryId${orderNo}`,
              required: true,
            }}
            defaultValue={"Select Country"}
            options={countryOptions}
            onChange={onChange}
            label={"Country"}
            value={authorObj.countryId}
          />
          <Input
            key={"city"}
            inputProps={{
              type: "text",
              name: "city",
              id: `city${orderNo}`,
              required: true,
              maxLength:"150",
            }}
            onChange={onChange}
            label={"City"}
            value={authorObj.city}
          />
          <Input
            key={"zipCode"}
            inputProps={{
              type: "number",
              name: "zipCode",
              id: `zipCode${orderNo}`,
              required: true,
              maxLength:"20",
            }}
            onChange={onChange}
            label={"Zip Code"}
            value={authorObj.zipCode}
          />
        </div>
        <div className="d-flex gap10 flex-wrap">
          <Input
            key={"phoneNo"}
            inputProps={{
              type: "text",
              // placeholder: "Phone Number",
              name: "phoneNo",
              id: `phoneNo${orderNo}`,
              pattern:
                "/^(+d{1,3}s?)?((d{1,3})|d{1,3})[-s.]?d{3,4}[-s.]?d{4}$/",
                maxLength:"25",
            }}
            value={authorObj.phoneNo}
            onChange={onChange}
            label={"Phone Number"}
          />
          {/* <Input
                key={"website"}
                inputProps={{
                  type: "text",
                  //  placeholder: "Website",
                  name: "website",
                  id: "website",
                  required: false,
                }}
                value={authorObj.website}
                onChange={onChange}
                label={"Website"}
              /> */}
        </div>
        <div className="d-flex gap10 flex-wrap">
          <Textarea
            key={"biography"}
            inputProps={{
              type: "text",
              placeholder: "",
              name: "biography",
              id: `biography${orderNo}`,
              required: false,
            }}
            value={authorObj.biography}
            onChange={onChange}
            label={"Biography"}
          />
          {/* <Textarea
                key={"interests"}
                inputProps={{
                  type: "text",
                  placeholder: "",
                  name: "interests",
                  id: "interests",
                  required: false,
                }}
                value={authorObj.interests}
                onChange={onChange}
                label={"Interests"}
              /> */}
        </div>
      </div>
    </div>
  );
}

export default AuthorItem;
