import React, { useState } from "react";

function AuthorListItem({ index, author }) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleAccordion() {
    setIsOpen(prev=>!prev);
  }
  return (
    <div
      className={`articleAuthorContainer ${
        author.roleId == 1 ? "correspondingAuthor" : ""
      } ${isOpen ? "open" : ""}`}
      key={author.id}
    >
      <button
        className="d-flex align-items-center justify-content-between accordionHeader"
        onClick={toggleAccordion}
      >
        <div className="d-flex align-items-center">
          <i className="bi bi-chevron-right"></i>
        <h3>Author {index + 1}</h3>
        </div>
        {author.roleId == 1 && (
          <span className="correspondingAuthorBadge">Corresponding Author</span>
        )}
      </button>
      <div className="accordionBody">
        <div>
          <div className="authorInfoWrapper">
            <b>Email:</b><span>{author.email}</span>
          </div>
          <div className="authorInfoWrapper">
            <b>Full Name:</b>
            <span>
              {author?.title} {author.firstName} {author.middleName}{" "}
              {author.lastName}
            </span>
          </div>

          <div className="authorInfoWrapper">
            <b>University/Organization:</b>
            <span>{author.universityOrOrganization}</span>
          </div>
          {author.department && (
            <div className="authorInfoWrapper">
              <b>Department:</b>
              <span>{author.department}</span>
            </div>
          )}

          <div className="authorInfoWrapper">
            <b>Address Line 1:</b><span>{author.addressLine1}</span>
          </div>
          {author.addressLine2 && (
            <div className="authorInfoWrapper">
              <b>Address Line 2:</b><span>{author.addressLine2}</span>
            </div>
          )}
          <div className="authorInfoWrapper">
            <b>Location:</b>
            <span>
              {author.country.name}, {author.city}, {author.zipCode}{" "}
            </span>
          </div>

          {author.phoneNo && (
            <div className="authorInfoWrapper">
              <b>Phone Number:</b><span>{author.phoneNo}</span>
            </div>
          )}
          {author.biography && (
            <div className="authorInfoWrapper">
              <b>Biography:</b><span>{author.biography}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AuthorListItem;
