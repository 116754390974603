import React from "react";
import ReactQuill from "react-quill";

function ReviewerComments({ reviewerComments, setReviewerComments, canEdit }) {
  return (
    <div className="d-flex gap15 flex-wrap">
      <div className="quillContainer">
        <label htmlFor=""> Comments for Author </label>
        <ReactQuill
          theme="snow"
          value={reviewerComments.commentToAuthor}
          readOnly={canEdit}
          onChange={(e) =>
            setReviewerComments((prev) => ({ ...prev, commentToAuthor: e }))
          }
        />
      </div>
      <div className="quillContainer">
        <label htmlFor=""> Comments for Editor </label>
        <ReactQuill
          theme="snow"
          value={reviewerComments.commentToEditor}
          readOnly={canEdit}
          onChange={(e) =>
            setReviewerComments((prev) => ({ ...prev, commentToEditor: e }))
          }
        />
      </div>
    </div>
  );
}

export default ReviewerComments;
