import React, { useCallback, useRef, useState } from "react";

import { createContext } from "react";

const SubmitArticleContext = createContext({
  currentForm: null,
  triggerUpdateForCurrentFrom: () => {},
  setTriggerUpdateForCurrentForm: () => {},
  isRequestFinished: false,
  setisRequestFinished: () => {},
  isFileUploading:false,
  setIsFileUploading:()=>{},
  completedStep: 1,
  setCompletedStep: () => {},
});

function SubmitArticleContextProvider({ children, completedStepInfo }) {
  const currentForm = useRef(null);
  const [triggerUpdateForCurrentForm, setTriggerUpdateForCurrentForm] =
    useState(() => {});
  const [isRequestFinished, setisRequestFinished] = useState(true);
  const [completedStep, setCompletedStep] = useState(completedStepInfo);
  const [isFileUploading,setIsFileUploading] = useState(false)
  return (
    <SubmitArticleContext.Provider
      value={{
        currentForm: currentForm,
        triggerUpdateForCurrentFrom: triggerUpdateForCurrentForm,
        setTriggerUpdateForCurrentForm: setTriggerUpdateForCurrentForm,
        isRequestFinished: isRequestFinished,
        setisRequestFinished: setisRequestFinished,
        completedStep: completedStep,
        setCompletedStep: setCompletedStep,
        isFileUploading,
        setIsFileUploading
      }}
    >
      {children}
    </SubmitArticleContext.Provider>
  );
}

export { SubmitArticleContext };
export default SubmitArticleContextProvider;
