import axios from "axios";
import { GUID, baseURL } from "./JournalSettings";
import { ArticleStatus, RequestType, UserRole, UserRoleType } from "./Enums";
import { errorToast } from "./UI/HelperFunctions/HelperFunctions";
let accessToken = "";
let refreshToken = "";
const instance = axios.create({
  baseURL: baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
});

instance.interceptors.request.use(
  (config) => {
    accessToken = localStorage.getItem("accessToken");
    refreshToken = localStorage.getItem("refreshToken");
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

async function RegisterUser(otherParams) {
  const body = {
    journalId: GUID,
    role: UserRole.Author,
    userRoleType: UserRoleType.Author,
    ...otherParams,
  };
  return instance.post("/Login/SignUpUI", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function RefreshAccessTokenVerifyLogin() {
  try {
    const response = await axios.post(`${baseURL}/Login/Refresh`, {
      refreshToken: refreshToken,
      token: accessToken,
    });
    accessToken = response.data.token;
    refreshToken = response.data.refreshToken;
    localStorage.setItem("accessToken", response.data.token);
    localStorage.setItem("refreshToken", response.data.refreshToken);
    return response;
  } catch (err) {
    ///if it doesn't manage to refresh the accesstoken navigate to login
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    accessToken = null;
    refreshToken = null;
    throw err;
  }
}

async function VerifyLogin() {
  return instance
    .get(`/User/GetUserInfo?journalId=${GUID}`)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch(async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        return await RefreshAccessTokenVerifyLogin().then(() => {
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axios(originalRequest);
        });
      }
      return Promise.reject(error);
    });
}

async function LoginUser(otherParams) {
  const body = {
    journalId: GUID,
    ...otherParams,
  };
  return instance.post("/Login/Login", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function GetCountries() {
  return instance
    .get(`/Login/GetCountries?journalId=${GUID}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetUserSubmittedArticles() {
  return instance
    .get(`/Article/GetAuthorSubmittedArticles?journalId=${GUID}`)
    .then((res) => {
      return Promise.resolve(res);
    });
}

async function GetSubmittedArticlesList(newPageNumber, statusId) {
  return instance
    .get(
      `/Editor/GetSubmittedArticlesList?journalId=${GUID}&status=${statusId}&pageNumber=${newPageNumber}`
    )
    .then((res) => {
      return Promise.resolve(res);
    });
}

async function EditUser(otherParams) {
  const body = {
    journalId: GUID,
    ...otherParams,
  };
  return instance.post("/User/EditUser", body).then((res) => {
    return Promise.resolve(res);
  });
}

async function UserChangePassword(params) {
  const body = {
    ...params,
  };
  return instance.post("/User/ChangePassword", body).then((res) => {
    return Promise.resolve(res);
  });
}

async function GetUserById(userId) {
  return instance
    .get(`/User/GetUserById?id=${userId}&journalId=${GUID}`)
    .then((res) => {
      return Promise.resolve(res);
    });
}

async function GetUsersByRoleList(role) {
  const body = {
    journalId: GUID,
    roles: role,
  };
  return instance.post("/User/GetUsersByRoleList", body).then((response) => {
    return Promise.resolve(response);
  });
}

async function GetArticleById(id) {
  return instance
    .get(`/Article/GetArticleById?journalId=${GUID}&articleId=${id}`)
    .then((res) => {
      return Promise.resolve(res);
    });
}

async function GetSubmittedArticleById(articleId, includeReviewers) {
  return instance
    .get(
      `/Editor/GetSubmittedArticleById?id=${articleId}&journalId=${GUID}&assignReviewers=${includeReviewers}`
    )
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function AssignReviewersToArticle(id, assignedReviewers) {
  const body = {
    journalId: GUID,
    articleId: id,
    reviewerIds: assignedReviewers,
  };
  return instance
    .post("/Editor/AssignReviewersToArticle", body)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetReviewerApplicationsList() {
  return instance
    .get(`/Editor/GetReviewerApplicationsList?journalId=${GUID}`)
    .then((res) => {
      return Promise.resolve(res);
    });
}

async function GetReviewerApplicationById(applicationId) {
  return instance
    .get(`/Editor/GetReviewerApplicationById?id=${applicationId}`)
    .then((res) => {
      return Promise.resolve(res);
    });
}

async function AcceptReviewerApplication(reviewerApplicationId) {
  const body = {
    reviewerApplicationId: reviewerApplicationId,
  };
  return instance
    .post("/Editor/AcceptReviewerApplication", body)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function RejectReviewerApplication(id, rejectComment) {
  const body = {
    reviewerApplicationId: id,
    rejectComment: rejectComment,
  };
  return instance
    .post("/Editor/RejectReviewerApplication", body)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function DeleteReviewer(id) {
  return instance.delete(`/Editor/DeleteReviewer?id=${id}`).then((response) => {
    return Promise.resolve(response);
  });
}

async function GetSubmitArticleInfo(articleId, getStep = false, step) {
  return instance
    .get(
      `/Article/GetSubmitArticleInfo?journalId=${GUID}&articleId=${articleId}&getStep=${getStep}&step=${step}`
    )
    .then((result) => {
      return Promise.resolve(result);
    });
}

async function GetTopicsBySectionId(journalSectionId) {
  return instance
    .get(
      `/Article/GetTopicsBySectionId?journalId=${GUID}&journalSectionId=${journalSectionId}`
    )
    .then((result) => {
      return Promise.resolve(result);
    });
}

async function AddOrUpdateArticleInformation(otherProps) {
  const body = {
    journalId: GUID,
    ...otherProps,
  };
  return instance
    .post(`/Article/AddOrUpdateArticleInformation`, body)
    .then((res) => {
      return Promise.resolve(res);
    });
}

async function AddOrUpdateAuthorInformation(articleId, articleAuthors) {
  const body = {
    articleId: articleId,
    articleAuthors: articleAuthors,
  };
  return instance
    .post("/Article/AddOrUpdateAuthorInformation", body)
    .then((res) => {
      return Promise.resolve(res);
    });
}

async function UploadArticleInformation(articleDocuments) {
  return instance
    .post("/Article/UploadArticleInformation", articleDocuments)
    .then((res) => {
      return Promise.resolve(res);
    });
}

async function SubmitArticle(articleId, userId) {
  const body = {
    articleId: articleId,
    userId: userId,
  };
  return instance.post("/Article/SubmitArticle", body).then((res) => {
    return Promise.resolve(res);
  });
}

async function AddArticleComment(articleId, comment) {
  const body = {
    articleId: articleId,
    comment: comment,
  };
  return instance.post("/Editor/AddArticleComment", body).then((res) => {
    return Promise.resolve(res);
  });
}

async function SendArticleAlert(body) {
  return instance.post("/Editor/SendArticleAlert", body).then((res) => {
    return Promise.resolve(res);
  });
}

async function CheckIfArticleIsInProgress(articleId) {
  return instance
    .get(`/Article/CheckIfArticleIsInProgress?articleId=${articleId}`)
    .then((res) => {
      return Promise.resolve(res);
    });
}

async function ApplyAsReviewer(otherProps) {
  const formData = new FormData();
  formData.append("userId", otherProps.userId);
  formData.append("comment", otherProps.comment);
  formData.append("cv", otherProps.cv);
  formData.append("journalId", GUID);

  return instance
    .post(`/Reviewer/ApplyAsReviewer`, formData)
    .then((res) => Promise.resolve(res));
}

async function GetEvaluationsList(status) {
  return instance
    .get(`/Reviewer/GetEvaluationsList?journalId=${GUID}&status=${status}`)
    .then((res) => {
      return Promise.resolve(res);
    });
}

async function GetReviewerApplicationInfo() {
  return instance
    .get(`/Reviewer/GetReviewerApplicationInfo?journalId=${GUID}`)
    .then((res) => Promise.resolve(res));
}

async function GetEvaluationById(articleId) {
  return instance
    .get(`/Reviewer/GetEvaluationById?id=${articleId}`)
    .then((res) => Promise.resolve(res));
}

async function ReviewArticle(props, submit, currentRound) {
  let reviewRank = null;
  if (currentRound == ArticleStatus.InReviewR1) reviewRank = props.reviewRank1;
  if (currentRound == ArticleStatus.InReviewR2) reviewRank = props.reviewRank2;
  if (currentRound == ArticleStatus.InReviewR3) reviewRank = props.reviewRank3;
  if (!reviewRank) reviewRank = null;
  const body = {
    ...props,
    reviewRank: reviewRank,
    submit: submit,
  };
  return instance
    .post(`/Reviewer/ReviewArticle?`, body)
    .then((res) => Promise.resolve(res));
}

async function ForgotPassword(email) {
  return instance
    .post(`/User/ForgotPassword?email=${email}`)
    .then((res) => Promise.resolve(res));
}

async function ResetPassword(body) {
  return instance
    .post(`User/ResetPassword?`, body)
    .then((res) => Promise.resolve(res));
}

async function RejectArticlePublication(articleId, rejectArticleComment) {
  const body = {
    articleId: articleId,
    rejectedComment: rejectArticleComment,
  };
  return instance
    .post(`/Editor/RejectArticlePublication`, body)
    .then((res) => Promise.resolve(res));
}

async function AcceptArticlePublication(articleId) {
  const body = {
    articleId: articleId,
  };
  return instance
    .post(`/Editor/AcceptArticlePublication`, body)
    .then((res) => Promise.resolve(res));
}

async function ApproveArticleByStatus(articleId, status, resetReviewersStatus,isFromChiefEditor=false) {
  const body = {
    articleId: articleId,
    status: status,
    resetReviewersStatus: resetReviewersStatus,
    isFromChiefEditor:isFromChiefEditor


  };
  return instance
    .post(`/Editor/ApproveArticleByStatus`, body)
    .then((res) => Promise.resolve(res));
}

async function PublishArticle(formData) {
  return instance
    .post(`/Editor/PublishArticle`, formData)
    .then((res) => Promise.resolve(res));
}

async function GetReviewerDetailsById(reviewerId) {
  return instance
    .get(`/Editor/GetReviewerDetailsById?id=${reviewerId}`)
    .then((res) => {
      return Promise.resolve(res);
    });
}

async function GetArticleAuthors(articleId) {
  return instance
    .get(`/Article/GetArticleAuthors?articleId=${articleId}`)
    .then((res) => {
      return Promise.resolve(res);
    });
}
async function GetArticleReviews(articleId) {
  return instance
    .get(`/Article/GetArticleReviews?articleId=${articleId}`)
    .then((res) => {
      return Promise.resolve(res);
    });
}

async function GetVolumesList() {
  return instance
    .get(`/Journals/GetVolumesList?journalId=${GUID}`)
    .then((res) => {
      return Promise.resolve(res);
    });
}

async function GetPublishedArticleFile(articleId) {
  return instance
    .get(`/Editor/GetPublishedArticleFile?articleId=${articleId}`)
    .then((res) => {
      return Promise.resolve(res);
    });
}

async function GetSubmittedArticleReviewers(articleId) {
  return instance
    .get(
      `/Editor/GetSubmittedArticleReviewers?id=${articleId}&journalId=${GUID}`
    )
    .then((response) => Promise.resolve(response));
}

async function getAdminDashboard() {
  return instance
    .get(`/Dashboard/GetAdminDashboard?journalId=${GUID}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetAppSettingDetails() {
  return instance
    .get(`/AppSettings/getAppSettingDetails?journalId=${GUID}`)
    .then((response) => Promise.resolve(response));
}

async function AssignReviewerToReviewersBoard(userid, isAddingRole) {
  const body = {
    userid: userid,
    journalId: GUID,
    isAddingRole: isAddingRole,
  };
  return instance
    .post(`Editor/AssignReviewerToReviewersBoard`, body)
    .then((res) => Promise.resolve(res));
}

async function GetLastRoundReviewsList(articleId) {
  return instance
    .get(`/Editor/GetLastRoundReviewsList?articleId=${articleId}`)
    .then((response) => Promise.resolve(response));
}

async function ApproveArticleFromModal(articleId, statusId, reviews) {
  const body = {
    articleId: articleId,
    statusId: statusId,
    reviews: reviews,
  };
  return instance
    .post(`Editor/ApproveArticleFromModal`, body)
    .then((res) => Promise.resolve(res));
}

async function AcceptOrRejectAssignedEvaluation(articleId, statusId) {
  const body = {
    articleId: articleId,
    status: statusId,
  };
  return instance
    .post(`Reviewer/AcceptOrRejectAssignedEvaluation`, body)
    .then((res) => Promise.resolve(res));
}

async function DownloadArticleFile(fileId) {
  return instance
    .get(`Article/DownloadArticleFile?articleFileId=${fileId}`)
    .then((res) => Promise.resolve(res));
}



////React component used to navigate user back to login whenever the refreshToken Expires
const Endpoints = ({ setShowTokenExpiredModal }) => {
  ////this function tries to refresh the accesstoken
  async function RefreshAccessToken() {
    try {
      const response = await axios.post(`${baseURL}/Login/Refresh`, {
        refreshToken: refreshToken,
        token: accessToken,
      });
      accessToken = response.data.token;
      refreshToken = response.data.refreshToken;
      localStorage.setItem("accessToken", response.data.token);
      localStorage.setItem("refreshToken", response.data.refreshToken);
    } catch (err) {
      ///if it doesn't manage to refresh the accesstoken navigate to login
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      accessToken = null;
      refreshToken = null;
      setShowTokenExpiredModal(true);
      throw err;
    }
  }

  // Axios interceptor to handle expired tokens
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status !== 401) errorToast(error.message);
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        return await RefreshAccessToken().then(() => {
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axios(originalRequest);
        });
      }
      return Promise.reject(error);
    }
  );

  return <></>;
};

export {
  RegisterUser,
  GetCountries,
  LoginUser,
  GetUserSubmittedArticles,
  EditUser,
  GetAppSettingDetails,
  GetUserById,
  VerifyLogin,
  PublishArticle,
  GetUsersByRoleList,
  UserChangePassword,
  GetSubmittedArticlesList,
  ApproveArticleByStatus,
  AcceptArticlePublication,
  GetArticleById,
  GetArticleReviews,
  GetArticleAuthors,
  AssignReviewersToArticle,
  GetSubmittedArticleById,
  GetReviewerApplicationsList,
  GetReviewerApplicationById,
  AcceptReviewerApplication,
  RejectReviewerApplication,
  GetSubmitArticleInfo,
  UploadArticleInformation,
  GetTopicsBySectionId,
  AddOrUpdateAuthorInformation,
  AddOrUpdateArticleInformation,
  AddArticleComment,
  SendArticleAlert,
  SubmitArticle,
  CheckIfArticleIsInProgress,
  ApplyAsReviewer,
  GetEvaluationsList,
  GetReviewerApplicationInfo,
  GetEvaluationById,
  ReviewArticle,
  DeleteReviewer,
  ForgotPassword,
  RejectArticlePublication,
  ResetPassword,
  GetReviewerDetailsById,
  GetVolumesList,
  GetPublishedArticleFile,
  GetSubmittedArticleReviewers,
  getAdminDashboard,
  AssignReviewerToReviewersBoard,
  GetLastRoundReviewsList,
  ApproveArticleFromModal,
  AcceptOrRejectAssignedEvaluation,
  DownloadArticleFile,
};

export default Endpoints;
