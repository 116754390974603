import React, { useState } from "react";
import Modal from "../../../UI/Modal/Modal";
import ModalBodyComponent from "../../../UI/Modal/ModalBodyComponent";
import ButtonWithLoader from "../../../UI/ButtonWithLoader/ButtonWithLoader";
import { AcceptOrRejectAssignedEvaluation, ApproveArticleByStatus } from "../../../endpoints";
import { errorToast, successToast } from "../../../UI/HelperFunctions/HelperFunctions";
import { useParams } from "react-router-dom";
import { ArticleStatus } from "../../../Enums";

const ContinueAnywayModal = ({
  showShouldContinueAnywayModal,
  setShowShouldContinueAnywayModal,
  setData,
  setShowReviewsModal
}) => {
  const [isConfirming, setIsConfirming] = useState(false);
  function onClose() {
    setShowShouldContinueAnywayModal(false);
  }
  const {articleId} = useParams()

  const handleConfirm = () => {
    setIsConfirming(true)
    ApproveArticleByStatus(articleId,ArticleStatus.SubmitedByChiefEditor,false,true).then((res=>{
        if(res.data.success) {
            setData(prev=>({
                ...prev,
                statusId:res.data.status
            }))
            onClose()
            setShowReviewsModal(false)

            successToast("Changes Applied Succesfully")
        }
    })).finally(()=>setIsConfirming(false)).catch(()=>{errorToast("Something went wrong! Please try again")})
  };
  return (
    <Modal opened={showShouldContinueAnywayModal} onClose={onClose} className={"zIndex"}>
      <ModalBodyComponent title={"Article Status Will Update"} >
        <p>All Remaining reviewers have submitted thier reviews.</p>
        <p>The article will proceed to the next step.</p>
        <div
          className="modal-footer d-flex align-items-center justify-content-end gap10"
          style={{ paddingBottom: "0px" }}
        >
          <ButtonWithLoader onClick={handleConfirm} isLoading={isConfirming}>
            Continue Anyway
          </ButtonWithLoader>
          <ButtonWithLoader onClick={onClose} extraClasses={["negative"]}>
            Cancel
          </ButtonWithLoader>
        </div>
      </ModalBodyComponent>
    </Modal>
  );
};

export default ContinueAnywayModal;
