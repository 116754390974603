import React, { useContext, useState, useEffect } from "react";
import Protector from "../../UI/Protector";
import { AuthContext } from "../../store/AuthContext";
import { ArticleReviewStatus, UserRole } from "../../Enums";
import { GetEvaluationsList } from "../../endpoints";
import Table from "../../UI/Tables/Table";
import { useParams } from "react-router-dom";
import Preloader from "../../UI/Preloader/Preloader";
import { articleStatus } from "../../UI/HelperFunctions/HelperFunctions";
import { SettingsContext } from "../../store/SettingsContext";

const CompletedEvaluationsRequests = () => {
  const [tableData, setTableData] = useState([]);
  const { userObj } = useContext(AuthContext);
  const [isReady, setIsReady] = useState(false);
  const { evaluationId } = useParams();
  const { hasArticleTypes, hasSections } = useContext(SettingsContext);

  const tableHeaders = [
    { value: "Article ID" },
    { value: "Title" },
    ...(hasArticleTypes ? [{ value: "Type" }] : []),
    ...(hasSections ? [{ value: "Section" }] : []),
    { value: "Article Status" },
    { value: "Requested Date" },
    { value: "Submitted Date" },
    { value: "Recommendation" },
  ];

  useEffect(() => {
    if (![UserRole.Reviewer, UserRole.ReviewerBoard].includes(userObj.role))
      return;
    GetEvaluationsList(ArticleReviewStatus.Completed).then((res) => {
      const copiedArray = res.data.evaluations?.map((obj) => ({
        id: `#${obj.article.id?.slice(-8)}`,
        title: obj.article?.title,
        ...(hasArticleTypes ? { type: obj.article?.articleType } : {}),
        ...(hasSections ? { section: obj.article?.journalSection } : {}),
        // status: articleStatus(obj.statusId),
        status: articleStatus(obj.articleStatusId),
        requestedDate: obj.created?.substring(0, 10),
        submittedDate: obj.submittedOn?.substring(0, 10),
        reviewRanking: [
          obj.reviewRanking1,
          obj.reviewRanking2,
          obj.reviewRanking3,
        ],
        buttonId: obj.id,
      }));
      setTableData(copiedArray?.map((obj) => Object.values(obj)));
      setIsReady(true);
    });
  }, []);

  return (
    <Protector
      isAuthorized={[UserRole.Reviewer, UserRole.ReviewerBoard].includes(
        userObj.role
      )}
    >
      <Preloader isReady={isReady}>
        <h2 className="boardHeader">Completed Evaluations Requests</h2>
        <Table
          tableHeaders={tableHeaders}
          tableData={tableData}
          btnText={"Open Review"}
          btnLink={"/completed-evaluations-requests"}
        />
      </Preloader>
    </Protector>
  );
};

export default CompletedEvaluationsRequests;
