import React from "react";
import "./Table.css";
import { Link } from "react-router-dom";
import EmptyResponse from "../EmptyResponse/EmptyResponse";
import PagingButtons from "../PagingButtons/PagingButtons";
import { getReviewerRecomendation } from "../HelperFunctions/HelperFunctions";

function Table({
  tableHeaders,
  tableData,
  btnText,
  btnLink,
  paginationData = {},
  setNewPageNumber,
}) {
  if (tableData?.length === 0 || !tableData) {
    return <EmptyResponse />;
  }
  return (
    <>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              {tableHeaders?.map((tableHeader, index) => {
                {
                  return <th key={index}>{tableHeader.value}</th>;
                }
              })}
              <th></th>
            </tr>
          </thead>
          {tableData && (
            <tbody>
              {tableData?.map((data, i) => (
                <tr key={i}>
                  {data.map((num, j) => (
                    <td
                      key={j}
                      data-cell={Object.values(tableHeaders)[j]?.value}
                    >
                      {(() => {
                        if (j === data.length - 1) {
                          return (
                            <Link
                              className="btn tableBtn w-100"
                              to={`${btnLink}/${data.at(-1)}`}
                            >
                              {btnText}
                            </Link>
                          );
                        } else {
                          if (
                            Object.values(tableHeaders)[j]?.value !==
                            "Recommendation"
                          ) {
                            return (
                              <span
                                className={`${
                                  j === 0 ? "truncateTextToSingleLine" : ""
                                }`}
                              >
                                {num}
                              </span>
                            );
                          } else {
                            return (
                              <>
                                <p>
                                  <i>Round 1:</i>{" "}
                                  {getReviewerRecomendation(num[0])}
                                </p>
                                {num[1] !== 0 && (
                                  <p>
                                    <i>Round 2:</i>{" "}
                                    {getReviewerRecomendation(num[1])}
                                  </p>
                                )}
                                {num[2] !== 0 && (
                                  <p>
                                    <i>Round 3:</i>{" "}
                                    {getReviewerRecomendation(num[2])}
                                  </p>
                                )}
                              </>
                            );
                          }
                        }
                      })()}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      {Object.keys(paginationData).length !== 0 && (
        <PagingButtons
          totalPages={paginationData.totalPages}
          setNewPageNumber={setNewPageNumber}
          hasPreviousPage={paginationData.hasPreviousPage}
          hasNextPage={paginationData.hasNextPage}
          pageNumber={paginationData.pageNumber}
        />
      )}
    </>
  );
}

export default Table;
