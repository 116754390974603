import React from "react";

const Card = ({ title, amount }) => {
  return (
    <div className="card text-center">
      <p className="text-uppercase fst-normal">{title}</p>
      <h4 className="mb-1 mt-1">
        <span className="counter-value">{amount}</span>
      </h4>
    </div>
  );
};

export default Card;
