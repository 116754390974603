import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../store/AuthContext";
import { ReviewerApplicationStatus, UserRole } from "../../Enums";
import Protector from "../../UI/Protector";
import Table from "../../UI/Tables/Table";
import { GetReviewerApplicationsList } from "../../endpoints";
import Preloader from "../../UI/Preloader/Preloader";

function ReviewersApplications() {
  const { userObj } = useContext(AuthContext);
  const [tableData, setTableData] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const tableHeaders = [
    { value: "Full name" },
    { value: "Title" },
    { value: "University/Organization" },
    { value: "Department" },
    { value: "Country" },
    { value: "Application date" },
    { value: "Status" },
  ];

  useEffect(() => {
    GetReviewerApplicationsList().then((res) => {
      const applicationStatus = (status) => {
        if (status === ReviewerApplicationStatus.Requested) {
          return "Requested";
        } else if (status === ReviewerApplicationStatus.Accepted) {
          return "Accepted";
        } else if (status === ReviewerApplicationStatus.Rejected) {
          return "Rejected";
        }
      };
      const copiedArray = res.data["reviewersApplications"].map((obj) => ({
        fullName: `${obj.createdByUser?.firstName} ${obj.createdByUser?.lastName}`,
        title: obj.createdByUser?.title,
        universityOrOrganization: obj.createdByUser?.universityOrOrganization,
        department: obj.createdByUser?.department,
        country: obj.createdByUser?.country?.name,
        applicationDate: obj.created?.substring(0, 10),
        status: applicationStatus(obj.statusId),
        buttonId: obj.id,
      }));
      setTableData(copiedArray?.map((obj) => Object.values(obj)));
      setIsReady(true);
    });
  }, []);

  return (
    <Protector
      isAuthorized={
        userObj.role === UserRole.EditorInChief ||
        userObj.role === UserRole.Editor
      }
    >
      <Preloader isReady={isReady}>
        <h2 className="boardHeader">Reviewers applications</h2>
        <Table
          tableHeaders={tableHeaders}
          tableData={tableData}
          btnText={"View Application"}
          btnLink={"/view-reviewer-application"}
        />
      </Preloader>
    </Protector>
  );
}

export default ReviewersApplications;
