import React from "react";
import "./EmptyResponse.css";

function EmptyResponse({ message = "There is no data available yet" }) {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column emptyResponse">
      <i className="bi bi-journal-x"></i>
      <p className="text-center">{message}</p>
    </div>
  );
}

export default EmptyResponse;
