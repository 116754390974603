import React, { useContext } from "react";
import "./Menus.css";
import { AuthContext } from "../../store/AuthContext";
import AuthorMenu from "./AuthorMenu";
import EditorMenu from "./EditorMenu";
import ChiefEditorMenu from "./ChiefEditorMenu";
import ReviewersMenu from "./ReviewersMenu";
import { UserRole } from "../../Enums";

function UserRoleMenu() {
  const { userObj } = useContext(AuthContext);
  return (
    <>
      {userObj.role === UserRole.Author && <AuthorMenu />}
      {userObj.role === UserRole.Editor && <EditorMenu />}
      {userObj.role === UserRole.EditorInChief && <ChiefEditorMenu />}
      {[UserRole.Reviewer, UserRole.ReviewerBoard].includes(userObj.role) && (
        <ReviewersMenu />
      )}
    </>
  );
}

export default UserRoleMenu;
