import React, { useContext, useEffect, useState } from "react";
import Select from "../../Inputs/Select";
import {
  AddOrUpdateArticleInformation,
  GetSubmitArticleInfo,
  GetTopicsBySectionId,
} from "../../../endpoints";
import { useNavigate, useParams } from "react-router-dom";
import Textarea from "../../Inputs/Textarea";
import Input from "../../Inputs/Input";
import { SubmitArticleContext } from "../../../store/SubmitArticleContext";
import Preloader from "../../../UI/Preloader/Preloader";
import { errorToast } from "../../../UI/HelperFunctions/HelperFunctions";
import { SettingsContext } from "../../../store/SettingsContext";

const initialState = {
  articleTypeId: null,
  journalSectionId: null,
  sectionResearchTopicId: null,
  title: "",
  abstract: "",
  keywords: "",
  numberOfPages: 1,
  articleId: 0,
  journalIssueId: "",
};

function SubmitArticleArticleInformation() {
  const { articleId } = useParams();
  const formattedArticleId = articleId == 0 ? "" : articleId;
  const {
    currentForm,
    setTriggerUpdateForCurrentForm,
    setCompletedStep,
    isRequestFinished,
    setisRequestFinished,
  } = useContext(SubmitArticleContext);
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const [articleTypeOptions, setArticleTypeOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [researchTopicOptions, setResearchTopicOptions] = useState([]);
  const [articleIssues, setArticleIssues] = useState([]);
  const [values, setValues] = useState({
    ...initialState,
    articleId: articleId,
  });

  const {
    abstractMaxWords,
    titleMaxCharacters,
    keywordsMaxLength,
    hasArticleTypes,
    hasSections,
    hasTopics,
    hasSpecialIssues,
  } = useContext(SettingsContext);

  function refetchTopicsBySectionId(e, researchTopicId = null) {
    const sectionId = e?.target?.value || e;
    GetTopicsBySectionId(sectionId).then((res) => {
      setResearchTopicOptions(res.data);
      setValues((prev) => {
        return { ...prev, sectionResearchTopicId: researchTopicId };
      });
    });
  }

  useEffect(() => {
    if (articleId == 0) {
      setValues(initialState);
    }
    GetSubmitArticleInfo(formattedArticleId, 1)
      .then((res) => {
        const articleData = res.data.articleInformation;
        setArticleTypeOptions(articleData.acticleTypes);
        setArticleIssues(articleData.issues);
        setSectionOptions(articleData.sections);
        if (!formattedArticleId) return;
        refetchTopicsBySectionId(
          articleData.journalSectionId,
          articleData.sectionResearchTopicId
        );
        setValues((prev) => {
          return {
            ...prev,
            articleTypeId: articleData.articleTypeId,
            journalSectionId: articleData.journalSectionId,
            title: articleData.title,
            abstract: articleData.abstract,
            keywords: articleData.keywords,
            numberOfPages: articleData.numberOfPages,
            journalIssueId: articleData.journalIssueId
              ? articleData.journalIssueId
              : "",
          };
        });
      })
      .finally((res) => setIsReady(true));
  }, [articleId]);

  function handleChange(e) {
    const name = e.target.name || e.target.title;
    let value = e.target.value;
    if (name == "journalSectionId") {
      refetchTopicsBySectionId(e);
    }
    if (name == "numberOfPages") {
      if (e.target.value > 1000) {
        value = 1000;
        errorToast("Maximum number of pages is 1000!");
      }
    }

    setValues((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  function updateInfo() {
    if (!formattedArticleId) {
      delete values.articleId;
    }
    if (!values.journalIssueId) {
      delete values.journalIssueId;
    }
    setisRequestFinished(false);
    AddOrUpdateArticleInformation(values)
      .then((res) => {})
      .catch((res) => console.log(res))
      .finally((res) => setisRequestFinished(true));
  }

  useEffect(() => {
    setTriggerUpdateForCurrentForm(() => {
      return () => {
        updateInfo();
      };
    });
  }, [values]);

  function onSubmit(e) {
    e.preventDefault();

    if (!formattedArticleId) {
      delete values.articleId;
    }
    if (!values.journalIssueId) {
      delete values.journalIssueId;
    }
    setisRequestFinished(false);
    AddOrUpdateArticleInformation(values)
      .then((res) => {
        if (res.data.success) {
          navigate(`/submit-article/${res.data.articleId}/author-information`);
          if (res.data.completedStep == 1) {
            setCompletedStep(res.data.completedStep + 1);
          } else {
            setCompletedStep(res.data.completedStep);
          }
        } else {
          errorToast(res.data.message);
        }
      })
      .catch((res) => console.log(res))
      .finally((res) => setisRequestFinished(true));
  }
  return (
    <Preloader isReady={isReady && isRequestFinished}>
      <form onSubmit={onSubmit} ref={currentForm}>
        {hasArticleTypes && (
          <Select
            key={"articleTypeId"}
            inputProps={{
              title: "articleTypeId",
              id: "articleTypeId",
              required: true,
            }}
            options={articleTypeOptions}
            value={values.articleTypeId}
            defaultValue={"Select Article Type"}
            onChange={handleChange}
            label={"Select Article Type"}
          />
        )}
        {hasSections && (
          <Select
            key={"journalSectionId"}
            inputProps={{
              title: "journalSectionId",
              id: "journalSectionId",
              required: true,
            }}
            options={sectionOptions}
            value={values.journalSectionId}
            defaultValue={"Select Section"}
            onChange={handleChange}
            label={"Select Section"}
          />
        )}
        {hasSections && hasTopics && (
          <Select
            key={"sectionResearchTopicId"}
            inputProps={{
              title: "sectionResearchTopicId",
              id: "sectionResearchTopicId",
              required: true,
            }}
            options={researchTopicOptions}
            value={values.sectionResearchTopicId}
            defaultValue={"Select Research Topic"}
            onChange={handleChange}
            label={"Select Research Topic"}
          />
        )}
        {hasSpecialIssues && (
          <Select
            key={"journalIssueId"}
            inputProps={{
              title: "journalIssueId",
              id: "journalIssueId",
            }}
            options={articleIssues}
            value={values.journalIssueId}
            defaultValue={"Select Issue"}
            onChange={handleChange}
            label={"Select Issue"}
          />
        )}
        <Textarea
          key={"title"}
          inputProps={{
            name: "title",
            id: "title",
            required: true,
          }}
          value={values.title}
          onChange={handleChange}
          label={"Title"}
          validateWordCount={true}
          maxWords={titleMaxCharacters}
        />
        <Textarea
          key={"abstract"}
          inputProps={{
            name: "abstract",
            id: "abstract",
            required: true,
          }}
          validateWordCount={true}
          value={values.abstract}
          onChange={handleChange}
          label={"Abstract"}
          maxWords={abstractMaxWords}
        />
        <Input
          key={"keywords"}
          inputProps={{
            name: "keywords",
            id: "keywords",
            required: true,
          }}
          label={'Keywords (please separate each keyword with: ";" )'}
          validateWordCount={true}
          onChange={handleChange}
          value={values.keywords}
          maxWords={keywordsMaxLength}
        />
        {/* <Input
          key={"numberOfPages"}
          inputProps={{
            name: "numberOfPages",
            id: "numberOfPages",
            required: true,
            type: "number",
          }}
          label={"Number Of Pages"}
          onChange={handleChange}
          value={values.numberOfPages}
        /> */}
        <div className="d-flex align-items-center justify-content-between">
          <button
            className="mainBtn mt-2 ms-auto"
            id="nextBtn"
            disabled={!isRequestFinished}
          >
            Next {">"}
          </button>
        </div>
      </form>
    </Preloader>
  );
}

export default SubmitArticleArticleInformation;
