import React from "react";
import "./PagingButtons.css";

function PagingButtons({
  totalPages,
  setNewPageNumber,
  hasPreviousPage = false,
  hasNextPage = false,
  pageNumber,
}) {
  function PagingButtons() {
    let html = [];
    for (let i = 1; i <= totalPages; i++) {
      html.push(
        <button
          className={`${pageNumber == i ? "active" : ""}`}
          onClick={() => setNewPageNumber(i)}
          key={i}
        >
          {i}
        </button>
      );
    }
    let firstPageIndex;
    let lastPageIndex;
    if (pageNumber > 3) {
      firstPageIndex = pageNumber - 3;
      lastPageIndex = firstPageIndex + 5;
    }

    if (pageNumber <= 3) {
      firstPageIndex = 0;
      lastPageIndex = firstPageIndex + 5;
    }

    let newHtml = html.slice(firstPageIndex, lastPageIndex);
    if (pageNumber - 3 > 0 && pageNumber - 4 != 0) {
      newHtml.unshift("...");
      newHtml.unshift(
        <button onClick={() => setNewPageNumber(1)} key={400}>
          1
        </button>
      );
    }
    if (totalPages + 3 > pageNumber && totalPages - 3 >= pageNumber) {
      newHtml.push("...");
      newHtml.push(
        <button onClick={() => setNewPageNumber(totalPages)} key={500}>
          {totalPages}
        </button>
      );
    }

    return newHtml;
  }

  return (
    <div className="pagingButtonsContainer">
      <button
        disabled={!hasPreviousPage}
        onClick={() => {
          setNewPageNumber((prev) => prev - 1);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-chevron-left"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          />
        </svg>
      </button>
      {PagingButtons()}
      <button
        disabled={!hasNextPage}
        onClick={() => {
          setNewPageNumber((prev) => prev + 1);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-chevron-right"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </button>
    </div>
  );
}

export default PagingButtons;
