import React, { useContext, useEffect, useState } from "react";
import {
  errorToast,
  successToast,
} from "../../../UI/HelperFunctions/HelperFunctions";
import ButtonWithLoader from "../../../UI/ButtonWithLoader/ButtonWithLoader";
import Modal from "../../../UI/Modal/Modal";
import ModalBodyComponent from "../../../UI/Modal/ModalBodyComponent";
import {
  AssignReviewersToArticle,
  GetSubmittedArticleReviewers,
} from "../../../endpoints";
import { useParams } from "react-router-dom";
import Preloader from "../../../UI/Preloader/Preloader";
import { SettingsContext } from "../../../store/SettingsContext";
import { ArticleStatus } from "../../../Enums";
import ContinueAnywayModal from "./ContinueAnywayModal";

function AssignReviewersButton({ setData }) {
  const [showModal, setShowModal] = useState(false);
  const { articleId } = useParams();
  const fallbackArray = [];
  const [assignedReviewers, setAssignedReviewers] = useState([]);
  const [selectedReviewersCount, setSelectedReviewersCount] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [showShouldContinueAnywayModal,setShowShouldContinueAnywayModal] = useState(false)
  const [filteredReviewers, setFilteredReviewers] = useState([]);
  const { maxAssignedReviewers, minAssignedReviewers } =
    useContext(SettingsContext);

  const GetSubmittedArticleReviewersFunc = () => {
    setIsReady(false);
    setSearchValue("");
    GetSubmittedArticleReviewers(articleId).then((res) => {
      setFilteredReviewers(res.data);
      res.data.map((reviewer) => {
        if (reviewer["isSelected"] && !reviewer.hasRefused) {
          fallbackArray.push(reviewer.id);
        }
      });
      setIsReady(true);
      setSelectedReviewersCount(fallbackArray.length);
      setAssignedReviewers(fallbackArray);
    });
  };

  function onClose() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const handleCheck = (event, reviewerId) => {
    if (
      maxAssignedReviewers !== "" &&
      selectedReviewersCount >= maxAssignedReviewers &&
      event.target.checked
    ) {
      errorToast(
        `You can not assign more than ${maxAssignedReviewers} reviewer${
          maxAssignedReviewers > 1 ? "s" : ""
        }`
      );
    } else {
      const updatedReviewers = filteredReviewers.map((reviewer) => {
        if (reviewer.id === reviewerId) {
          return {
            ...reviewer,
            isSelected: event.target.checked,
          };
        }
        return reviewer;
      });

      const selectedReviewers = updatedReviewers
        .filter((reviewer) => reviewer.isSelected && !reviewer.hasRefused)
        .map((reviewer) => reviewer.id);
      setAssignedReviewers(selectedReviewers);
      setSelectedReviewersCount(selectedReviewers.length);
      setFilteredReviewers(updatedReviewers);
    }
  };

  const handleAssign = (continueAnyway) => {
    if (
      minAssignedReviewers !== "" &&
      minAssignedReviewers > selectedReviewersCount
    ) {
      errorToast(
        `Please assign more than ${minAssignedReviewers} reviewer${
          minAssignedReviewers > 1 ? "s" : ""
        }`
      );
    } else {
      setIsSaving(true);
      AssignReviewersToArticle(articleId, assignedReviewers)
        .then((res) => {
          
          if (res.data.success && !res.data.otherReviewsAreCompleted) {
            setShowModal(false);
            setData((prev) => ({
              ...prev,
              statusId: ArticleStatus.InReviewR1,
            }));
            successToast("Reviewers assigned successfully");
          } else {
            if(!res.data.otherReviewsAreCompleted) {

              errorToast("Failed to assign reviewers");
            }
            if(res.data.otherReviewsAreCompleted) {
              setShowShouldContinueAnywayModal(true)
            }
          }
        })
        .finally(() => setIsSaving(false));
    }
  };


  return (
    <>


      <ButtonWithLoader
        onClick={() => {
          openModal();
          GetSubmittedArticleReviewersFunc();
        }}
        isLoading={isSaving}
      >
        <i className="bi bi-people"></i> Assign Reviewers
      </ButtonWithLoader>
        <ContinueAnywayModal showShouldContinueAnywayModal={showShouldContinueAnywayModal} setShowReviewsModal={setShowModal} setShowShouldContinueAnywayModal={setShowShouldContinueAnywayModal} setData={setData}/>
      <Modal opened={showModal} onClose={onClose}>
        <Preloader isReady={isReady}>
          <ModalBodyComponent title={"Assign Reviewers"}>
            <div
              className=" inputContainer flex-column align-items-start pb-3"
              style={{ marginTop: "0px" }}
            >
              <div>
                <p className="reviewersNo">
                  Selected Reviewers: {selectedReviewersCount}
                </p>
              </div>
              <div className="w-100">
                <div className="search-bar form-control">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="gray"
                    className="bi bi-search"
                    viewBox="0 0 15 15"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                  <input
                    type="text"
                    className="w-100"
                    value={searchValue}
                    placeholder="Search for reviewers"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="reviewerContainer d-flex justify-content-start gap15 flex-column">
              {filteredReviewers &&
                Array.isArray(filteredReviewers) &&
                filteredReviewers
                  .filter(
                    (obj) =>
                      obj.firstName
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                      obj.lastName
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                  )
                  .map((reviewer, index) => {
                    return (
                      <label
                        key={index}
                        className={`d-flex align-items-center w-100  ${
                          reviewer.hasRefused ? "reviewerRefused" : ""
                        }`}
                        style={{ gap: "5px" }}
                      >
                        <input
                          value={reviewer.id}
                          className="mt-0 mx-1"
                          type="checkbox"
                          disabled={reviewer.hasRefused}
                          checked={reviewer.isSelected || false}
                          onChange={(event) => handleCheck(event, reviewer.id)}
                        />
                        <span className="reviewerDetails">
                          <div className="d-flex align-items-center header">
                            <h2>
                              {reviewer.title} {reviewer.firstName}{" "}
                              {reviewer.middleName} {reviewer.lastName}
                            </h2>
                          </div>
                          <span>
                            {reviewer.department
                              ? `${reviewer.department},`
                              : ""}{" "}
                            {reviewer.universityOrOrganization
                              ? `${reviewer.universityOrOrganization},`
                              : ""}{" "}
                            {reviewer.countryName
                              ? `${reviewer.countryName},`
                              : ""}{" "}
                            {reviewer.city ? `${reviewer.city},` : ""}{" "}
                            {reviewer.zipCode ? `${reviewer.zipCode}` : ""}{" "}
                          </span>
                          <div className="d-flex align-items-start contact">
                            <span>Contact: </span>
                            <p>{reviewer.email}</p>
                          </div>
                        </span>
                        {reviewer.hasRefused && (
                          <span
                            className="text-danger"
                            style={{ marginLeft: "auto", maxWidth: "125px" }}
                          >
                            This reviewer declined the invitation to review this
                            article.
                          </span>
                        )}
                      </label>
                    );
                  })}
            </div>
            <div
              className="modal-footer d-flex align-items-center justify-content-end gap10"
              style={{ paddingBottom: "0px" }}
            >
              <ButtonWithLoader onClick={handleAssign} isLoading={isSaving}>
                Assign Reviewers
              </ButtonWithLoader>
              <ButtonWithLoader onClick={onClose} extraClasses={["negative"]}>
                Cancel
              </ButtonWithLoader>
            </div>
          </ModalBodyComponent>
        </Preloader>
      </Modal>
    </>
  );
}

export default AssignReviewersButton;
