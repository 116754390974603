import React from "react";
import Preloader from "../Preloader/Preloader";

const SuspenseFallback = () => {
  return (
    <div className="vh-100 d-flex justify-content-center">
      <Preloader />
    </div>
  );
};

export default SuspenseFallback;
