import React, { useEffect, useState } from "react";
import { GetEvaluationById } from "../../endpoints";
import Preloader from "../../UI/Preloader/Preloader";
import ButtonWithLoader from "../../UI/ButtonWithLoader/ButtonWithLoader";
import ArticleDetails from "../Modals/ArticleDetails";
import ReviewRoundCreater from "./ReviewRoundCreater";
import { ArticleReviewStatus, ArticleStatus } from "../../Enums";
import { useNavigate, useParams } from "react-router-dom";

function CompletedEvaluationsViewEvaluation() {
  const [evaluationData, setEvaluationData] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [round1Values, setRound1Values] = useState([]);
  const [round2Values, setRound2Values] = useState([]);
  const [round3Values, setRound3Values] = useState([]);
  const { evaluationId } = useParams();
  const navigate = useNavigate();

  function backHandler() {
    navigate("/completed-evaluations-requests");
  }

  useEffect(() => {
    GetEvaluationById(evaluationId)
      .then((res) => {
        setEvaluationData(res.data);
        setRound1Values({
          commentToAuthor: res.data.round1Review?.commentsToAuthors || "",
          commentToEditor: res.data.round1Review?.commentsToEditor || "",
          reviewRank1: res.data.round1Review?.reviewedRank || "",
        });
        setRound2Values({
          commentToAuthor: res.data.round2Review?.commentsToAuthors || "",
          commentToEditor: res.data.round2Review?.commentsToEditor || "",
          reviewRank2: res.data.round2Review?.reviewedRank || "",
        });
        setRound3Values({
          commentToAuthor: res.data.round3Review?.commentsToAuthors || "",
          commentToEditor: res.data.round3Review?.commentsToEditor || "",
          reviewRank3: res.data.round3Review?.reviewedRank || "",
        });
      })
      .finally(() => {
        setIsReady(true);
      });
  }, []);

  return (
    <Preloader isReady={isReady}>
      <div className="p-3" style={{ height: "100%" }}>
        <ArticleDetails
          tableData={{
            ...evaluationData.article,
            created: evaluationData.created,
          }}
        />
        <div className="mt-2">
          <ReviewRoundCreater
            values={round1Values}
            setValues={setRound1Values}
            round={1}
            initialOpenState={
              evaluationData?.article?.statusId >= ArticleStatus.Submitted &&
              evaluationData?.article?.statusId < ArticleStatus.InReviewR2
            }
            readOnly={evaluationData?.statusId >= ArticleReviewStatus.Completed}
          />
          {evaluationData?.article?.statusId >= ArticleStatus.InReviewR2 &&
            evaluationData.round2Review != null && (
              <ReviewRoundCreater
                values={round2Values}
                setValues={setRound2Values}
                round={2}
                initialOpenState={
                  evaluationData?.article?.statusId >=
                    ArticleStatus.InReviewR2 &&
                  evaluationData?.article?.statusId < ArticleStatus.InReviewR3
                }
                readOnly={true}
              />
            )}
          {evaluationData?.article?.statusId >= ArticleStatus.InReviewR3 &&
            evaluationData.round3Review != null && (
              <ReviewRoundCreater
                values={round3Values}
                setValues={setRound2Values}
                round={3}
                initialOpenState={true}
                readOnly={true}
              />
            )}
        </div>
      </div>
      <div className="modal-footer justify-content-between">
        <ButtonWithLoader onClick={backHandler}>{"<"} Go Back</ButtonWithLoader>
        <div></div>
      </div>
    </Preloader>
  );
}

export default CompletedEvaluationsViewEvaluation;
