import React, { useEffect, useState } from "react";
import Modal from "../../UI/Modal/Modal";
import { useNavigate, useParams } from "react-router-dom";
import {
  AcceptOrRejectAssignedEvaluation,
  GetEvaluationById,
  ReviewArticle,
} from "../../endpoints";
import ArticleDetails from "./ArticleDetails";
import "react-quill/dist/quill.snow.css";

import ReviewRoundCreater from "../Reviewer/ReviewRoundCreater";
import {
  errorToast,
  successToast,
} from "../../UI/HelperFunctions/HelperFunctions";
import Preloader from "../../UI/Preloader/Preloader";
import ButtonWithLoader from "../../UI/ButtonWithLoader/ButtonWithLoader";
import { ArticleReviewStatus, ArticleStatus } from "../../Enums";

function PendingEvaluationsModal() {
  const { id } = useParams();
  const [evaluationData, setEvaluationData] = useState({});
  const [round1Values, setRound1Values] = useState({
    commentToEditor: "",
    commentToAuthor: "",
    id: id,
    reviewRank1: "",
  });
  const [round2Values, setRound2Values] = useState({
    commentToEditor: "",
    commentToAuthor: "",
    id: id,
    reviewRank2: "",
  });

  const [round3Values, setRound3Values] = useState({
    commentToEditor: "",
    commentToAuthor: "",
    id: id,
    reviewRank3: "",
  });

  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  const [isReady, setIsReady] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    GetEvaluationById(id)
      .then((res) => {
        setEvaluationData(res.data);
        setRound1Values({
          commentToAuthor: res.data.round1Review?.commentsToAuthors || "",
          commentToEditor: res.data.round1Review?.commentsToEditor || "",
          reviewRank1: res.data.round1Review?.reviewedRank || "",
          id: id,
        });
        setRound2Values({
          commentToAuthor: res.data.round2Review?.commentsToAuthors || "",
          commentToEditor: res.data.round2Review?.commentsToEditor || "",
          reviewRank2: res.data.round2Review?.reviewedRank || "",
          id: id,
        });
        setRound3Values({
          commentToAuthor: res.data.round3Review?.commentsToAuthors || "",
          commentToEditor: res.data.round3Review?.commentsToEditor || "",
          reviewRank3: res.data.round3Review?.reviewedRank || "",
          id: id,
        });
      })
      .finally(() => {
        setIsReady(true);
      });
  }, []);

  function onChange(e) {
    const name = e.target.name;
    let value = e.target.value;
    if (name.includes("reviewRank")) value = +value;
    if (evaluationData.article.statusId === ArticleStatus.InReviewR1)
      setRound1Values((prev) => ({ ...prev, [name]: value }));
    if (evaluationData.article.statusId === ArticleStatus.InReviewR2)
      setRound2Values((prev) => ({ ...prev, [name]: value }));
    if (evaluationData.article.statusId === ArticleStatus.InReviewR3)
      setRound3Values((prev) => ({ ...prev, [name]: value }));
  }

  function backHandler() {
    navigate("/pending-evaluations-requests");
  }

  function acceptOrRejectAssignedEvalation(statusId) {
    let changedStatus;
    if (statusId === ArticleReviewStatus.Assigned)
      changedStatus = ArticleReviewStatus.Assigned;
    if (statusId === ArticleReviewStatus.RejectedByReviewer)
      changedStatus = ArticleReviewStatus.RejectedByReviewer;
    AcceptOrRejectAssignedEvaluation(evaluationData?.article?.id, statusId)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          setEvaluationData((prev) => ({ ...prev, statusId: changedStatus }));
        } else {
          errorToast(res.data.message);
        }
      })
      .finally((res) => {
        setIsAccepting(false);
        setIsRejecting(false);
      });
  }

  function onSubmit(submit) {
    const articleStatus = evaluationData.article.statusId;
    let values = null;
    if (articleStatus == ArticleStatus.InReviewR1) values = round1Values;
    if (articleStatus == ArticleStatus.InReviewR2) values = round2Values;
    if (articleStatus == ArticleStatus.InReviewR3) values = round3Values;
    let reviewRound = null;
    if (articleStatus == ArticleStatus.InReviewR1) reviewRound = "reviewRank1";
    if (articleStatus == ArticleStatus.InReviewR2) reviewRound = "reviewRank2";
    if (articleStatus == ArticleStatus.InReviewR3) reviewRound = "reviewRank3";

    if (values[reviewRound] === "" && submit) {
      errorToast("You need to rank the article first!");
      return;
    }
    if (submit) {
      setIsSubmitting(true);
    } else if (!submit) {
      setIsSavingDraft(true);
    }
    ReviewArticle(values, submit, articleStatus)
      .then((res) => {
        if (res.data.success) {
          const toastMessage = !submit
            ? "Review Draft Saved Successfully!"
            : "Review Submitted Successfully!";
          successToast(toastMessage);
          navigate("/pending-evaluations-requests");
        }
        if (!res.data.success) {
          errorToast("Something Went Wrong Please Try Again Later!");
        }
      })
      .finally(() => {
        setIsSavingDraft(false);
        setIsSubmitting(false);
      });
  }

  return (
    <>
      <div className="p-3" style={{ height: "100%" }}>
        <Preloader isReady={isReady} minHeight="100%">
          <ArticleDetails
            tableData={{
              ...evaluationData.article,
              created: evaluationData.created,
            }}
          />
          {![
            ArticleReviewStatus.Pending,
            ArticleReviewStatus.RejectedByReviewer,
          ].includes(evaluationData?.statusId) && (
            <div className="mt-2">
              <ReviewRoundCreater
                values={round1Values}
                setValues={setRound1Values}
                onChange={onChange}
                round={1}
                initialOpenState={
                  evaluationData?.article?.statusId >=
                    ArticleStatus.Submitted &&
                  evaluationData?.article?.statusId < ArticleStatus.InReviewR2
                }
                readOnly={
                  evaluationData?.article?.statusId >= ArticleStatus.ReviewedR1
                }
              />
              {evaluationData?.article?.statusId >=
                ArticleStatus.InReviewR2 && (
                <ReviewRoundCreater
                  values={round2Values}
                  setValues={setRound2Values}
                  round={2}
                  onChange={onChange}
                  initialOpenState={
                    evaluationData?.article?.statusId >=
                      ArticleStatus.InReviewR2 &&
                    evaluationData?.article?.statusId < ArticleStatus.InReviewR3
                  }
                  readOnly={
                    evaluationData?.article?.statusId >=
                    ArticleStatus.ReviewedR2
                  }
                />
              )}

              {evaluationData?.article?.statusId >=
                ArticleStatus.InReviewR3 && (
                <ReviewRoundCreater
                  values={round3Values}
                  setValues={setRound3Values}
                  round={3}
                  onChange={onChange}
                  initialOpenState={true}
                  readOnly={false}
                />
              )}
            </div>
          )}
        </Preloader>
      </div>
      <div className="modal-footer justify-content-between">
        <ButtonWithLoader
          isLoading={isSavingDraft}
          onClick={backHandler}
          buttonProps={{ disabled: isSavingDraft || isSubmitting }}
        >
          {"<"} Go Back
        </ButtonWithLoader>

        {evaluationData?.statusId == ArticleReviewStatus.Pending && (
          <div>
            <span>Do you choose to review this article?</span>
            <div>
              <ButtonWithLoader
                isLoading={isAccepting}
                onClick={() => {
                  acceptOrRejectAssignedEvalation(ArticleReviewStatus.Assigned);
                  setIsAccepting(true);
                }}
                buttonProps={{ disabled: isAccepting || isRejecting }}
              >
                Yes
              </ButtonWithLoader>
              <ButtonWithLoader
                isLoading={isRejecting}
                onClick={() => {
                  acceptOrRejectAssignedEvalation(
                    ArticleReviewStatus.RejectedByReviewer
                  );
                  setIsRejecting(true);
                }}
                extraClasses={["negative"]}
                buttonProps={{ disabled: isAccepting || isRejecting }}
              >
                No
              </ButtonWithLoader>
            </div>
          </div>
        )}
        {evaluationData?.statusId == ArticleReviewStatus.RejectedByReviewer && (
          <span className="text-danger">
            This article review request has been rejected by you{" "}
          </span>
        )}
        {![
          ArticleReviewStatus.Pending,
          ArticleReviewStatus.RejectedByReviewer,
        ].includes(evaluationData?.statusId) && (
          <div>
            <ButtonWithLoader
              isLoading={isSavingDraft}
              onClick={() => onSubmit(false)}
              buttonProps={{ disabled: isSavingDraft || isSubmitting }}
            >
              Save Draft
            </ButtonWithLoader>
            <ButtonWithLoader
              isLoading={isSubmitting}
              onClick={() => onSubmit(true)}
              buttonProps={{ disabled: isSavingDraft || isSubmitting }}
            >
              Submit Review
            </ButtonWithLoader>
          </div>
        )}
      </div>
    </>
  );
}

export default PendingEvaluationsModal;
