import React, { useContext } from "react";
import { AuthContext } from "../store/AuthContext";
import { UserRole } from "../Enums";
import Dashboard from "../components/Editor-ChiefEditor/Dashboard";
import AuthorHome from "../components/Author/AuthorHome";
import PendingEvaluationsRequests from "../components/Reviewer/PendingEvaluationsRequests";

function Home() {
  const { userObj } = useContext(AuthContext);

  return (
    <>
      {userObj.role === UserRole.Editor && <Dashboard />}
      {userObj.role === UserRole.EditorInChief && <Dashboard />}
      {userObj.role === UserRole.Author && <AuthorHome />}
      {[UserRole.Reviewer, UserRole.ReviewerBoard].includes(userObj.role) && (
        <PendingEvaluationsRequests />
      )}
    </>
  );
}

export default Home;
