import React, { useContext, useRef } from "react";
import SingleRowTable from "../../UI/Tables/SingleRowTable";
import { AuthContext } from "../../store/AuthContext";
import { ArticleStatus, UserRole } from "../../Enums";
import AuthorList from "../../UI/AuthorList/AuthorList";
import ReviewersList from "../../UI/ReviewersList/ReviewsList";
import StyledDownloadableFileComponent from "../../UI/StyledDownloadableFileComponent/StyledDownloadableFileComponent";
import { SettingsContext } from "../../store/SettingsContext";

const ArticleDetails = ({ tableData }) => {
  const articleDownloadRef = useRef(null);
  const { userObj } = useContext(AuthContext);
  const { hasArticleTypes, hasSections, hasTopics, hasSpecialIssues } =
    useContext(SettingsContext);

  const tableHeaders = [
    { value: "Article ID" },
    { value: "Title" },
    { value: "Submitted Date" },
    { value: "Status" },
  ];

  return (
    <>
      <SingleRowTable tableHeaders={tableHeaders} tableData={tableData} />
      {tableData && (
        <div className="px-2 articleDetailsContainer">
          {hasArticleTypes && (
            <div className="flex-center inputContainer">
              <label htmlFor="">Type</label>
              <input
                disabled
                value={tableData.articleType ? tableData.articleType : ""}
              />
            </div>
          )}
          {hasSections && (
            <div className="flex-center inputContainer">
              <label htmlFor="">Section</label>
              <input
                disabled
                value={tableData.journalSection ? tableData.journalSection : ""}
              />
            </div>
          )}
          {hasTopics && (
            <div className="flex-center inputContainer">
              <label htmlFor="">Topic</label>
              <input
                disabled
                value={
                  tableData.sectionResearchTopic
                    ? tableData.sectionResearchTopic
                    : ""
                }
              />
            </div>
          )}
          {hasSpecialIssues && (
            <div className="flex-center inputContainer">
              <label htmlFor="">Issue</label>
              <input
                disabled
                value={tableData.journalIssue ? tableData.journalIssue : ""}
              />
            </div>
          )}
          {![UserRole.Reviewer, UserRole.ReviewerBoard].includes(
            userObj.role
          ) && (
            <div className="flex-center inputContainer">
              <label htmlFor="">Authors</label>
              <AuthorList articleId={tableData.id} />
            </div>
          )}
          <div className="flex-center inputContainer">
            <label htmlFor="">Abstract</label>
            <textarea
              id="articleInformation"
              disabled
              value={tableData.abstract ? tableData.abstract : ""}
              rows={3}
              style={{ flex: `${1} !important` }}
            />
          </div>
          <div className="flex-center inputContainer">
            <label htmlFor="">Keywords</label>
            <input
              disabled
              value={tableData.keywords ? tableData.keywords : ""}
            />
          </div>
          <div className="flex-center inputContainer manuscript">
            <label htmlFor="">Download Article</label>
            <StyledDownloadableFileComponent file={tableData.manuscript} />
          </div>
        </div>
      )}
      {![UserRole.Reviewer, UserRole.ReviewerBoard].includes(userObj.role) && (
        <ReviewersList
          articleId={tableData.id}
          articleStatus={tableData.statusId}
        />
      )}
      {tableData.statusId == ArticleStatus.Rejected && (
        <div className="px-2 articleDetailsContainer d-flex flex-wrap gap10 align-items-start">
          <div className="flex-center inputContainer" style={{ flex: "1" }}>
            <label htmlFor="">Rejected Comment</label>
            <textarea
              id="articleInformation"
              disabled
              value={tableData.rejectedComment ? tableData.rejectedComment : ""}
              rows={3}
              style={{ flex: `${1} !important` }}
            />
          </div>
          <div
            className="flex-center inputContainer"
            style={{ flex: "0 1 0%" }}
          >
            <label htmlFor="">Rejected Date</label>
            <input
              disabled
              value={
                tableData.rejectedDate
                  ? tableData?.rejectedDate.substring(0, 10)
                  : ""
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ArticleDetails;
