import React, { useContext, useEffect, useState } from "react";
import Textarea from "../Inputs/Textarea";
import { AuthContext } from "../../store/AuthContext";
import { ApplyAsReviewer, GetReviewerApplicationInfo } from "../../endpoints";
import {
  errorToast,
  successToast,
} from "../../UI/HelperFunctions/HelperFunctions";
import "./BecomeReviewer.css";
import Protector from "../../UI/Protector";
import { ReviewerApplicationStatus, UserRole } from "../../Enums";
import Preloader from "../../UI/Preloader/Preloader";
import ButtonWithLoader from "../../UI/ButtonWithLoader/ButtonWithLoader";
import RejectedApplcationItem from "./SubmitArticle/RejectedApplcationItem";

function ConvertToInformFile(document) {
  const base64Data = document.cvFile; // Replace with your base64 data
  const fileName = document.cvFileName; // Replace with the desired file name
  const fileType = document.cvFileType; // Replace with the desired file type

  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: fileType });
  const file = new File([blob], fileName, { type: fileType });

  return file;
}

function BecomeReviewer() {
  const { userObj } = useContext(AuthContext);
  const [values, setValues] = useState({
    comment: "",
    cv: "",
    userId: userObj.id,
  });

  const [submissionStatus, setSubmissionStatus] = useState(
    ReviewerApplicationStatus.NoSubmission
  );

  const [isReady, setIsReady] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prevApplications, setPrevApplications] = useState([]);

  function getSubmissionStatusClassName() {
    if (submissionStatus == ReviewerApplicationStatus.Requested) {
      return "pending";
    }
    if (submissionStatus == ReviewerApplicationStatus.Rejected) {
      return "rejected";
    }
    if (userObj.isReviewer) {
      return "accepted";
    }

    if (submissionStatus == ReviewerApplicationStatus.NoSubmission) {
      return "";
    }
  }

  useEffect(() => {
    if (userObj.role !== UserRole.Author) return;
    GetReviewerApplicationInfo()
      .then((res) => {
        if (res.data.success) {
          setValues({
            comment: res.data.latestApplication.comment,
            cv: res.data.latestApplication.cv,
            userId: userObj.id,
          });
          setSubmissionStatus(res.data.latestApplication.statusId);
          setPrevApplications(res.data.rejectedApplications);
        }
      })
      .finally(() => setIsReady(true));
  }, []);

  function handleChange(e) {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "cv") {
      value = e.target.files[0];
    }
    setValues((prev) => {
      return { ...prev, [name]: value };
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);
    if (values.cv?.cvFile) {
      values.cv = ConvertToInformFile(values.cv);
    }
    ApplyAsReviewer(values)
      .then((res) => {
        if (res.data.success) {
          successToast(`${res.data.message}`);
          setSubmissionStatus(ReviewerApplicationStatus.Requested);
        }
        if (!res.data.success) {
          errorToast(res.data.message);
        }
      })
      .then(() => setIsSubmitting(false));
  }

  return (
    <Protector
      isAuthorized={userObj.role === UserRole.Author}
      isReviewer={userObj.isReviewer}
    >
      <Preloader isReady={isReady}>
        <div className="becomeReviewer">
          <h2 className="boardHeader">Become a Reviewer</h2>
          <div className="becomeReviewerWrapper">
            <div className="applicationStatus">
              <h3>
                Application Status:
                <span className={getSubmissionStatusClassName()}>
                  {submissionStatus == ReviewerApplicationStatus.NoSubmission &&
                    "You haven't applied yet"}
                  {submissionStatus == ReviewerApplicationStatus.Requested &&
                    "Pending"}
                  {submissionStatus == ReviewerApplicationStatus.Rejected &&
                    "Rejected"}
                  {userObj.isReviewer && "Accepted"}
                </span>
              </h3>
              <p>
                {submissionStatus == ReviewerApplicationStatus.NoSubmission &&
                  "To apply as a reviewer please enter the required data."}
                {submissionStatus == ReviewerApplicationStatus.Requested &&
                  "While your submission is pending you are not allowed  to resubmit your application."}
                {submissionStatus == ReviewerApplicationStatus.Rejected &&
                  "Your application has been rejected"}
              </p>
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="d-flex align-items-start gap10 flex-wrap">
                <div className="inputWrapper">
                  <label htmlFor="">
                    Upload your updated CV:<span>*</span>
                  </label>
                  {values.cv?.size || values.cv?.cvFile ? (
                    <div
                      className="d-flex align-items-center justify-content-between form-control"
                      style={{ border: "1px solid var(--main05)" }}
                    >
                      <div>
                        <i className="bi bi-files"></i>
                        <span className="px-3">
                          {values.cv?.name || values.cv?.cvFileName}
                        </span>
                      </div>
                      <div className="d-flex align-items-center">
                        {![
                          userObj.isReviewer,
                          ReviewerApplicationStatus.Requested,
                        ].includes(submissionStatus) && (
                          <svg
                            onClick={() => {
                              setValues((prev) => {
                                return {
                                  ...prev,
                                  cv: "",
                                };
                              });
                            }}
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="red"
                            className="bi bi-x"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        )}
                      </div>
                    </div>
                  ) : (
                    <input
                      type="file"
                      disabled={[
                        userObj.isReviewer,
                        ReviewerApplicationStatus.Requested,
                      ].includes(submissionStatus)}
                      name="cv"
                      className="form-control"
                      required
                      onChange={(e) => handleChange(e)}
                    />
                  )}
                </div>
              </div>
              <Textarea
                label={"Add additional information regarding your application"}
                inputProps={{
                  name: "comment",
                  id: "comment",
                  required: true,
                  disabled: [
                    userObj.isReviewer,
                    ReviewerApplicationStatus.Requested,
                  ].includes(submissionStatus),
                }}
                value={values.comment}
                onChange={handleChange}
              ></Textarea>
              <ButtonWithLoader
                isLoading={isSubmitting}
                buttonProps={{
                  disabled: [
                    userObj.isReviewer,
                    ReviewerApplicationStatus.Requested,
                  ].includes(submissionStatus),
                }}
              >
                {submissionStatus !== ReviewerApplicationStatus.NoSubmission
                  ? "Resubmit"
                  : "Submit"}
              </ButtonWithLoader>
            </form>
          </div>
          {prevApplications.length > 0 && (
            <div className="rejectionsContainer mt-3">
              <h3 style={{ color: "red" }}>Rejected Applications</h3>
              {prevApplications.map((el, i) => {
                return <RejectedApplcationItem data={el} key={i} />;
              })}
            </div>
          )}
        </div>
      </Preloader>
    </Protector>
  );
}

export default BecomeReviewer;
