import React, { useState } from "react";
import ReviewRound from "./ReviewRound";
import PlainFileComponent from "../PlainFileComponent.jsx/PlainFileComponent";

function ReviewListItem({ reviews, round,authorResponseFile }) {
  const [open, setOpen] = useState(false);
  function toggleAccordion() {
    setOpen((prev) => !prev);
  }


  return (
    <div className={`reviewAccordionContainer ${open ? "open" : ""}`}>
      <button
        className="d-flex align-items-center justify-content-between accordionHeader flex-wrap"
        onClick={toggleAccordion}
      >
        <div className="d-flex align-items-center">
        <i className="bi bi-chevron-right"></i>
        <h3>Review Round {round}</h3>
        </div>
   <PlainFileComponent file={authorResponseFile}/>
      </button>
      <div className="accordionBody">
        <div>
          {reviews?.map((review, i) => {
            return <ReviewRound review={review} round={i + 1} key={i} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default ReviewListItem;
