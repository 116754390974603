import React, { useRef } from "react";
import Textarea from "../../Inputs/Textarea";

function RejectedApplcationItem({ data }) {
  const cvRef = useRef();
  const handleManuscriptDownload = (ref) => {
    ref.current.click();
  };
  return (
    <div
      className=" d-flex flex-column rejectedApplicationWrapper"
      style={{ gap: "10px" }}
    >
      <p>
        Rejected on: <b>{data.rejectedOn.substring(0, 10)}</b>
      </p>
      <div className="d-flex align-items-start gap10 flex-wrap">
        <div className="inputWrapper">
          <label htmlFor="">
            CV:<span>*</span>
          </label>
          <div
            className="d-flex align-items-center justify-content-between form-control"
            style={{ border: "1px solid var(--main05)" }}
          >
            <div>
              <i className="bi bi-files"></i>
              <span className="px-3">
                {data.cv?.name || data.cv?.cvFileName}
              </span>
            </div>
            <i
              className="bi bi-download download"
              onClick={() => handleManuscriptDownload(cvRef)}
            ></i>
            <div className="d-flex align-items-center"></div>
          </div>
        </div>
      </div>
      <Textarea
        label={"Your Comment"}
        inputProps={{
          name: "comment",
          id: "comment",
          disabled: true,
        }}
        value={data.comment}
      ></Textarea>
      <Textarea
        label={"Rejection Reason"}
        inputProps={{
          name: "comment",
          id: "comment",
          disabled: true,
        }}
        value={data.rejectedComment}
      ></Textarea>
      <a
        ref={cvRef}
        href={`data:${data?.cv?.cvFileType};base64,${data.cv.cvFile}`}
        download={data.cv.cvFileName}
        style={{ display: "none" }}
      ></a>
    </div>
  );
}

export default RejectedApplcationItem;
