import React, { useContext } from "react";
import "./UnauthorizedResponse.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../store/AuthContext";
import { UserRole } from "../../Enums";

const UnauthorizedResponse = ({ isReviewer, requiredRole }) => {
  const { setUserObj } = useContext(AuthContext);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setUserObj({});
  };

  if (isReviewer) {
    return (
      <div className="unauthorizedResponse">
        <i className="bi bi-journal-check"></i>
        <p>You are already a reviewer for this Journal</p>
        <Link to={"/"} className="logoutLink">
          Go Back
        </Link>
      </div>
    );
  }

  return (
    <div className="unauthorizedResponse">
      <i className="bi bi-x-circle"></i>
      <p>It seems like you don't have permission to access this page.</p>
      <p>Please sign in with a different account.</p>
      {requiredRole && <b>Required Role: {requiredRole}</b>}
      <Link to={"/auth/login"} className="logoutLink" onClick={handleLogout}>
        Continue to Logout
      </Link>
    </div>
  );
};

export default UnauthorizedResponse;
