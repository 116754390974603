import { useState } from "react";
import { createContext } from "react";

const AuthContext = createContext({
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  token: "",
  setToken: () => {},
  userObj: {},
  setUserObj: () => {},
});

function AuthContextProvider({ children }) {
  const storedToken = localStorage.getItem("token");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(storedToken);
  const [userObj, setUserObj] = useState({});


  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        setIsLoggedIn: setIsLoggedIn,
        token: token,
        setToken: setToken,
        userObj: userObj,
        setUserObj: setUserObj,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext };
export default AuthContextProvider;
