import React from "react";
import { NavLink } from "react-router-dom";

const EditorMenu = () => {
  return (
    <div className="card journalMenuContainer mt-3 mb-2">
      <h2>Editor's Menu</h2>
      <nav>
        <ul className="d-flex flex-column journalMenu">
          <li>
            <NavLink to={"/manage-articles"}>Manage articles</NavLink>
          </li>
          <li>
            <NavLink to={"/reviewers-applications"}>
              Reviewers applications
            </NavLink>
          </li>
          <li>
            <NavLink to={"/reviewers"}>Reviewers list</NavLink>
          </li>
          {/* <li>
            <NavLink to={"/send-receipts"}>Send receipts</NavLink>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default EditorMenu;
