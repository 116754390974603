import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import "./Menus.css";
import { AuthContext } from "../../store/AuthContext";
import { UserRole } from "../../Enums";

function UserMenu() {
  const { userObj, setUserObj } = useContext(AuthContext);

  function removeToken() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setUserObj({});
  }

  return (
    <div className="card journalMenuContainer">
      <h2>User's Menu</h2>
      <nav>
        <ul className="journalMenu">
          {userObj.role === UserRole.Editor ||
          userObj.role === UserRole.EditorInChief ? (
            <li>
              <NavLink to={"/"}>Dashboard</NavLink>
            </li>
          ) : (
            <li>
              <NavLink to={"/"}>Home</NavLink>
            </li>
          )}

          <li>
            <NavLink to={"/edit-profile"}>Edit Profile</NavLink>
          </li>
          <li>
            <NavLink to={"/change-password"}>Change Password</NavLink>
          </li>
          <li onClick={removeToken}>
            <NavLink to={"/auth/login"}>Logout</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default UserMenu;
