import React, { useContext, useEffect, useState } from "react";
import { GetSubmitArticleInfo, SubmitArticle } from "../../../endpoints";
import { useNavigate, useParams } from "react-router-dom";
import Preloader from "../../../UI/Preloader/Preloader";
import { AuthContext } from "../../../store/AuthContext";
import { SubmitArticleContext } from "../../../store/SubmitArticleContext";
import {
  errorToast,
  successToast,
} from "../../../UI/HelperFunctions/HelperFunctions";
import ButtonWithLoader from "../../../UI/ButtonWithLoader/ButtonWithLoader";
import StyledDownloadableFileComponent from "../../../UI/StyledDownloadableFileComponent/StyledDownloadableFileComponent";
import { SettingsContext } from "../../../store/SettingsContext";

function SubmitArticleConfirmation() {
  const { articleId } = useParams();
  const navigate = useNavigate();
  const [articleInfo, setArticleInfo] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const { userObj } = useContext(AuthContext);
  const { completedStep, isRequestFinished } = useContext(SubmitArticleContext);
  const { hasSections, hasTopics, hasSpecialIssues, hasArticleTypes } =
    useContext(SettingsContext);

  useEffect(() => {
    if (!isRequestFinished) return;
    if (articleId == 0) {
      navigate("/submit-article/0/article-information");
      return;
    }

    if (completedStep != 3 && completedStep != 4) {
      navigate(`/submit-article/${articleId}/article-information`);
      return;
    }
    GetSubmitArticleInfo(articleId, false, 4).then((res) => {
      setIsReady(true);
      setArticleInfo(res.data);
    });
  }, [isRequestFinished]);

  function submitArticle() {
    setIsSaving(true);
    SubmitArticle(articleId, userObj.id)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          navigate("/submitted-articles");
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsSaving(false));
  }

  function goBack() {
    navigate(`/submit-article/${articleId}/upload-article`);
  }

  return (
    <Preloader isReady={isReady && isRequestFinished}>
      <h2 className="boardHeader">Article Info</h2>
      {
        <>
          {hasArticleTypes && (
            <div className="articleInfoContainer mx-0">
              <span>Article Type:</span>{" "}
              <b>{articleInfo.articleInformation?.articleType}</b>
            </div>
          )}
          {hasSections && (
            <div className="articleInfoContainer mx-0">
              <span>Article Section: </span>
              <b>{articleInfo.articleInformation?.journalSection}</b>
            </div>
          )}
          {hasTopics && hasSections && (
            <div className="articleInfoContainer mx-0">
              <span>Article Reserach Topic: </span>
              <b>{articleInfo.articleInformation?.sectionResearchTopic}</b>
            </div>
          )}
          {hasSpecialIssues && articleInfo.articleInformation?.journalIssue && (
            <div className="articleInfoContainer mx-0">
              <span>Article Issue: </span>
              <b>{articleInfo.articleInformation?.journalIssue}</b>
            </div>
          )}

          <div className="articleInfoContainer mx-0">
            <span>Article Title:</span>{" "}
            <b>{articleInfo.articleInformation?.title}</b>
          </div>

          <div className="articleInfoContainer mx-0">
            <span>Article Abstract: </span>
            <b>{articleInfo.articleInformation?.abstract}</b>
          </div>
          <div className="articleInfoContainer mx-0">
            <span>Article Keywords: </span>
            <b>{articleInfo.articleInformation?.keywords}</b>
          </div>
          {/* <div className="articleInfoContainer">
            <span>Article Pages: </span>
            <b>{articleInfo.articleInformation?.numberOfPages}</b>
          </div> */}

          <div className="articleInfoAuthorContainerwrapper">
            <h2 className="boardHeader">Author Information</h2>
            <div className="totalAuthorNr">
              <span> Total Number Of Authors:</span>{" "}
              <b>{articleInfo.authorInformation?.articleAuthors.length}</b>
            </div>
            {articleInfo.authorInformation?.articleAuthors.map((author, i) => {
              return (
                <div
                  className={`articleInfoAuthorContainer mx-0 ${
                    author.roleId == 1 ? "correspondingAuthor" : ""
                  }`}
                  key={author.id}
                >
                  {author.roleId == 1 && (
                    <span className="correspondingAuthorBadge">
                      Corresponding Author
                    </span>
                  )}

                  <h3>Author {i + 1} </h3>
                  <div className="authorInfoWrapper">
                    <span>Email:</span> <b>{author.email}</b>
                  </div>
                  <div className="authorInfoWrapper">
                    <span>Full Name:</span>
                    <b>
                      {author?.title} {author.firstName} {author.middleName}{" "}
                      {author.lastName}
                    </b>
                  </div>

                  <div className="authorInfoWrapper">
                    <span>University/Organization:</span>
                    <b>{author.universityOrOrganization}</b>
                  </div>
                  {author.department && (
                    <div className="authorInfoWrapper">
                      <span>Department:</span>
                      <b>{author.department}</b>
                    </div>
                  )}

                  <div className="authorInfoWrapper">
                    <span>Address Line 1:</span> <b>{author.addressLine1}</b>
                  </div>
                  {author.addressLine2 && (
                    <div className="authorInfoWrapper">
                      <span>Address Line 2:</span> <b>{author.addressLine2}</b>
                    </div>
                  )}
                  <div className="authorInfoWrapper">
                    <span>Location: </span>{" "}
                    <b>
                      {author.country.name}, {author.city}, {author.zipCode}{" "}
                    </b>
                  </div>

                  {author.phoneNo && (
                    <div className="authorInfoWrapper">
                      <span>Phone Number: </span> <b>{author.phoneNo}</b>
                    </div>
                  )}
                  {author.biography && (
                    <div className="authorInfoWrapper">
                      <span>Biography: </span> <b>{author.biography}</b>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div>
            <h2 className="boardHeader">Uploaded Article Files</h2>
            {articleInfo.uploadArticleInformation?.manuscript && (
              <StyledDownloadableFileComponent
                file={articleInfo?.uploadArticleInformation?.manuscript}
                label={"Uploaded Article File"}
              />
            )}
            {articleInfo.uploadArticleInformation?.copyrightAgreement && (
              <StyledDownloadableFileComponent
                file={articleInfo?.uploadArticleInformation?.copyrightAgreement}
                label={"Copyright Agreement"}
              />
            )}
            {articleInfo?.uploadArticleInformation?.authorResponse && (
              <StyledDownloadableFileComponent
                file={articleInfo?.uploadArticleInformation?.authorResponse}
                label={"Author Feedback Response"}
              />
            )}
          </div>
          <div className="d-flex align-items-center justify-content-sm-between justify-content-center flex-wrap gap10">
            <button
              type="button"
              className="mainBtn"
              onClick={goBack}
              id="prevBtn"
            >
              {"<"} Previous
            </button>

            <ButtonWithLoader isLoading={isSaving} onClick={submitArticle}>
              Confirm and Submit
              <i
                className="bi bi-check-circle-fill"
                style={{ marginLeft: "5px", height: "17px" }}
              ></i>
            </ButtonWithLoader>
          </div>
        </>
      }
    </Preloader>
  );
}

export default SubmitArticleConfirmation;
