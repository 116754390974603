import "./Table.css";
import { Link } from "react-router-dom";
import EmptyResponse from "../EmptyResponse/EmptyResponse";
import { v4 as uuid } from "uuid";
import PagingButtons from "../PagingButtons/PagingButtons";
import { getReviewerRecomendation } from "../HelperFunctions/HelperFunctions";

function CustomizableTable({
  tableHeaders,
  tableData,
  paginationData = {},
  setNewPageNumber,
}) {
  if (tableData?.length === 0 || !tableData) {
    return <EmptyResponse />;
  }

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {tableHeaders.map((tableHeader, index) => {
              {
                return <th key={index}>{tableHeader.value}</th>;
              }
            })}
            <th></th>
          </tr>
        </thead>
        {tableData && (
          <tbody>
            {tableData?.map((data, i) => (
              <tr key={uuid()}>
                {data.map((tableItem, j) => (
                  <td
                    key={uuid()}
                    data-cell={Object.values(tableHeaders)[j]?.value}
                  >
                    {tableItem.type == "text" && (
                      <span
                        className={`${
                          tableItem?.classes ? tableItem?.classes : ""
                        }`}
                      >
                        {tableItem.value}
                      </span>
                    )}
                    {tableItem.type == "link" && (
                      <Link className="btn tableBtn w-100" to={tableItem.link}>
                        {tableItem.text}
                      </Link>
                    )}

                    {/* {tableItem.type == "ranking" && (
                      <Ratings rating={tableItem.rank} />
                    )} */}
                    {tableItem.type == "recommendation" &&
                      getReviewerRecomendation(tableItem.rank)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {Object.keys(paginationData).length !== 0 && (
        <PagingButtons
          totalPages={paginationData.totalPages}
          setNewPageNumber={setNewPageNumber}
          hasPreviousPage={paginationData.hasPreviousPage}
          hasNextPage={paginationData.hasNextPage}
          pageNumber={paginationData.pageNumber}
        />
      )}
    </div>
  );
}

export default CustomizableTable;
