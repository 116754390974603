import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ArticleStatus, ArticleRecommendation } from "../../Enums";

function successToast(message) {
  return toast.success(`${message}`, {
    position: toast.POSITION.TOP_CENTER,
    transition: Zoom,
    autoClose: 2500,
    closeOnClick: true,
    hideProgressBar: true,
  });
}

function errorToast(message) {
  return toast.error(`${message}`, {
    position: toast.POSITION.TOP_CENTER,
    transition: Zoom,
    autoClose: 2500,
    toastId: 1,
    closeOnClick: true,
    hideProgressBar: true,
  });
}

const articleStatus = (status) => {
  if (status === ArticleStatus.Submitting) {
    return "Submitting";
  } else if (status === ArticleStatus.Submitted) {
    return "Submitted";
  } else if (status === ArticleStatus.InReviewR1) {
    return "In Review Round 1";
  } else if (status === ArticleStatus.ReviewedR1) {
    return "Reviewed Round 1";
  } else if (status === ArticleStatus.ApprovedR1) {
    return "Approved Round 1";
  } else if (status === ArticleStatus.SubmittedAfterR1) {
    return "Submitted After Round 1";
  } else if (status === ArticleStatus.InReviewR2) {
    return "In Review Round 2";
  } else if (status === ArticleStatus.ReviewedR2) {
    return "Reviewed Round 2";
  } else if (status === ArticleStatus.ApprovedR2) {
    return "Approved Round 2";
  } else if (status === ArticleStatus.SubmittedAfterReviewedR2) {
    return "Submitted After Round 2";
  } else if (status === ArticleStatus.InReviewR3) {
    return "In Review Round 3";
  } else if (status === ArticleStatus.ReviewedR3) {
    return "Reviewed Round 3";
  } else if (status === ArticleStatus.ApprovedR3) {
    return "Approved Round 3";
  } else if (status === ArticleStatus.SubmittedAfterReviewedR3) {
    return "Submitted After Round 3";
  } else if (status === ArticleStatus.Rejected) {
    return "Rejected";
  } else if (status === ArticleStatus.Accepted) {
    return "Accepted";
  } else if (status === ArticleStatus.Published) {
    return "Published";
  }
};

const reviewRank = (rating) => {
  if (rating === ArticleRecommendation.AcceptInPresentForm) {
    return "Accept in Present Form";
  } else if (rating === ArticleRecommendation.AcceptAfterMinorRevision) {
    return "Accept afer minor revision";
  } else if (rating === ArticleRecommendation.ReconsiderAfterMajorRevision) {
    return "Reconsider after major revision";
  } else if (rating === ArticleRecommendation.Reject) {
    return "Reject";
  }
  //  else if (rating === ArticleRecommendation.VeryReject) {
  //   return "Very Reject";
  // }
};

function getReviewerRecomendation(statusId) {
  if (statusId == ArticleRecommendation.AcceptInPresentForm) {
    return "Accept In Present Form";
  }

  if (statusId == ArticleRecommendation.AcceptAfterMinorRevision) {
    return "Accept After Minor Revision";
  }

  if (statusId == ArticleRecommendation.ReconsiderAfterMajorRevision) {
    return "Reconsider After Major Revision";
  }

  if (statusId == ArticleRecommendation.Reject) {
    return "Reject";
  }

  return "No Recommendation Yet";
}

function changeArticleStatus(currentStatus) {
  let status;
  if (currentStatus === ArticleStatus.ReviewedR1) {
    status = ArticleStatus.ApprovedR1;
  } else if (currentStatus === ArticleStatus.ReviewedR2) {
    status = ArticleStatus.ApprovedR2;
  } else if (currentStatus === ArticleStatus.ReviewedR3) {
    status = ArticleStatus.ApprovedR3;
  } else if (currentStatus === ArticleStatus.SubmittedAfterR1) {
    status = ArticleStatus.InReviewR2;
  } else if (currentStatus === ArticleStatus.SubmittedAfterReviewedR2) {
    status = ArticleStatus.InReviewR3;
  }
  return status;
}

export {
  successToast,
  errorToast,
  articleStatus,
  reviewRank,
  getReviewerRecomendation,
  changeArticleStatus,
};
