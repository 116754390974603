import React from "react";
import { NavLink } from "react-router-dom";

const AuthorMenu = () => {
  return (
    <div className="card journalMenuContainer mt-3 mb-2">
      <h2>Author's Menu</h2>
      <nav>
        <ul className="d-flex flex-column journalMenu">
          <li>
            <NavLink to={"/submitted-articles"}>
              Display Submitted Articles
            </NavLink>
          </li>
          <li>
            <NavLink to={"/submit-article/0/article-information"}>
              Submit Article
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default AuthorMenu;
