import React, { useContext, useEffect, useReducer, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SubmitArticleContext } from "../../../store/SubmitArticleContext";
import { v4 as uuid } from "uuid";
import AuthorItem from "./AuthorItem";
import {
  AddOrUpdateAuthorInformation,
  GetSubmitArticleInfo,
} from "../../../endpoints";
import Preloader from "../../../UI/Preloader/Preloader";
import Modal from "../../../UI/Modal/Modal";
import { errorToast } from "../../../UI/HelperFunctions/HelperFunctions";
import { SettingsContext } from "../../../store/SettingsContext";
import { AuthorRole, CorrespondingAuthor } from "../../../Enums";

function initAuthorObj(author = null) {
  const authorObj = {
    id: author?.id || "",
    orderNo: author?.orderNo || 0,
    email: author?.email || "",
    firstName: author?.firstName || "",
    middleName: author?.middleName || "",
    lastName: author?.lastName || "",
    title: author?.title || "",
    roleId: author?.roleId || 2,
    universityOrOrganization: author?.universityOrOrganization || "",
    department: author?.department || "",
    addressLine1: author?.addressLine1 || "",
    addressLine2: author?.addressLine2 || "",
    zipCode: author?.zipCode || "",
    city: author?.city || "",
    countryId: author?.countryId || "",
    biography: author?.biography || "",
    phoneNo: author?.phoneNo || "",
    temporaryId: uuid(),
    isAuthorLoggedIn: author?.isAuthorLoggedIn || false,
  };
  if (!authorObj.id) {
    delete authorObj["id"];
  }
  return authorObj;
}

function dispatchFormState(state, action) {
  if (action.type === "INITIAL_STATE") {
    return action.data;
  }

  if (action.type === "EDIT_INFO") {
    const inputEvent = action.target.target;
    const inputName = inputEvent.name || inputEvent.title;
    const findEditedAuthorObj = state.find(
      (el) => el.temporaryId == action.tempId
    );

    if (findEditedAuthorObj) {
      const indexOfEditedAuthorObj = state.indexOf(findEditedAuthorObj);
      const editedAuthorObj = state[indexOfEditedAuthorObj];
      editedAuthorObj[inputName] = inputEvent.value;
      return state;
    }
  }

  if (action.type === "ADD_NEW_AUTHOR") {
    return [...state, initAuthorObj()];
  }

  if (action.type === "DELETE_AUTHOR" && state.length > 1) {
    return state.filter((el) => el.temporaryId != action.tempId);
  }

  if (action.type === "MOVE_AUTHOR") {
    const findMovedAuthorObj = state.find(
      (el) => el.temporaryId == action.tempId
    );
    const temporaryState = [...state];
    const indexOfMovedAuthorObj = state.indexOf(findMovedAuthorObj);
    const authorToMove = temporaryState.splice(indexOfMovedAuthorObj, 1)[0];
    temporaryState.splice(action.moveTo, 0, authorToMove);
    return temporaryState;
  }

  if (action.type === "CHANGE_CORRESPONDING_AUTHOR") {
    //if(action.correspondingAuthorControl == CorrespondingAuthor.OnlyLoggedInUser) return state

    if (action.correspondingAuthorControl == CorrespondingAuthor.AnyUser) {
      state.forEach((el) => (el.roleId = AuthorRole.Author));
    }
    const findChangedAuthorObj = state.find(
      (el) => el.temporaryId == action.tempId
    );

    const indexOfChangedAuthorObj = state.indexOf(findChangedAuthorObj);
    const authorToChange = state[indexOfChangedAuthorObj];
    authorToChange.roleId = action.roleId;

    const isThereAtLeastOneCorrespondingAuhtor = state.some(
      (el) => el.roleId === AuthorRole.CorrespondingAuthor
    );
    if (!isThereAtLeastOneCorrespondingAuhtor) {
      errorToast(`There Must Be At Least 1 Corresponding Author!`);
      authorToChange.roleId = AuthorRole.CorrespondingAuthor;
    }
    return [...state];
  }
  return state;
}
function SubmitArticleAuthorInformation() {
  const { articleId } = useParams();
  const location = useLocation();
  const [numberOfAuthors, setNumberOfAuthors] = useState(1);
  const [isReady, setIsReady] = useState(false);
  const [countries, setCountries] = useState([]);
  const {
    currentForm,
    setTriggerUpdateForCurrentForm,
    setCompletedStep,
    isRequestFinished,
    setisRequestFinished,
  } = useContext(SubmitArticleContext);
  const navigate = useNavigate();
  const [showDeleteAuthorModal, setShowDeleteAuthorModal] = useState(false);
  const [tempId, setSelectedTempId] = useState(0);
  const [fromState, formStateDispatcher] = useReducer(dispatchFormState, []);

  useEffect(() => {
    if (articleId == 0) return;
    if (!isRequestFinished) {
      return;
    }

    GetSubmitArticleInfo(articleId, false, 2)
      .then((res) => {
        const authorInformation = res.data.authorInformation;
        setCountries(authorInformation.countries);
        const formattedUserArray = [];
        authorInformation.articleAuthors.forEach((author) => {
          formattedUserArray.push(initAuthorObj(author));
        });
        formStateDispatcher({
          type: "INITIAL_STATE",
          data: formattedUserArray,
        });
      })
      .finally(() => setIsReady(true));
  }, [isRequestFinished, articleId]);

  function UpdateState() {
    setisRequestFinished(false);
    AddOrUpdateAuthorInformation(articleId, fromState)
      .then((res) => {})
      .finally((res) => setisRequestFinished(true));
  }

  useEffect(() => {
    setTriggerUpdateForCurrentForm(() => {
      return () => {
        UpdateState();
      };
    }, []);
  }, [fromState]);

  function setAuthorNumber() {
    setNumberOfAuthors((prev) => prev + 1);
    formStateDispatcher({ type: "ADD_NEW_AUTHOR" });
  }
  function onAuthorDelete() {
    formStateDispatcher({ type: "DELETE_AUTHOR", tempId: tempId });
    setShowDeleteAuthorModal(false);
  }

  function onClose() {
    setShowDeleteAuthorModal(false);
  }

  function goBack(e) {
    if (!currentForm.current.checkValidity()) {
      currentForm.current.reportValidity();
      return;
    }
    setisRequestFinished(false);
    AddOrUpdateAuthorInformation(articleId, fromState)
      .then((res) => {
        if (res.data.success) {
          navigate(`/submit-article/${articleId}/article-information`);
          return;
        }
        errorToast(res.data.message);
      })
      .finally((res) => setisRequestFinished(true));
  }

  function handleSubmit(e) {
    e.preventDefault();
    setisRequestFinished(false);
    AddOrUpdateAuthorInformation(articleId, fromState)
      .then((res) => {
        if (res.data.success) {
          if (res.data.completedStep == 2) {
            setCompletedStep((prev) => prev + 1);
          } else {
            setCompletedStep(res.data.completedStep);
          }

          navigate(`/submit-article/${articleId}/upload-article`);
          return;
        }

        errorToast(res.data.message);
      })
      .finally((res) => setisRequestFinished(true));
  }

  return (
    <Preloader isReady={isReady && isRequestFinished}>
      <form
        ref={currentForm}
        onSubmit={handleSubmit}
        className="authorInformationForm"
      >
        {fromState?.map((author, i) => {
          return (
            <AuthorItem
              authorNr={i + 1}
              formStateDispatcher={formStateDispatcher}
              formState={fromState}
              temporaryId={author.temporaryId}
              key={author.temporaryId}
              countryOptions={countries}
              setShowDeleteAuthorModal={setShowDeleteAuthorModal}
              setSelectedTempId={setSelectedTempId}
            />
          );
        })}
        <button onClick={setAuthorNumber} id="addNewAuthor" type="button">
          Add Another Author +
        </button>
        <div className="d-flex align-items-center flex-wrap gap10 justify-content-sm-between justify-content-center">
          <button
            className="mainBtn"
            id="prevBtn"
            type="button"
            onClick={(e) => goBack(e)}
          >
            {"<"} Previous
          </button>
          <button className="mainBtn" id="nextBtn">
            Next {">"}
          </button>
        </div>
      </form>
      <Modal opened={showDeleteAuthorModal} onClose={onClose}>
        <div
          className="deleteAuthorModalBody"
          onClick={(e) => e.stopPropagation()}
        >
          <h2>Remove Author</h2>
          <p>Are you sure you want to remove the selected author?</p>
          <div className="d-flex align-items-center gap10">
            <button onClick={onAuthorDelete}>Remove Author</button>
            <button onClick={onClose}>Cancel</button>
          </div>
        </div>
      </Modal>
    </Preloader>
  );
}

export default SubmitArticleAuthorInformation;
