import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "./Chart.css";

const Chart = ({ title, chartData, datakey }) => {
  return (
    <div className="chartWrapper">
      <p>{title}</p>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: -20,
            bottom: 5,
          }}
          barSize={20}
        >
          <XAxis
            dataKey="name"
            scale="point"
            padding={{ left: 10, right: 10 }}
          />
          <YAxis />
          <Tooltip
            wrapperStyle={{
              textTransform: "capitalize",
            }}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar
            dataKey={datakey}
            fill="var(--main05)"
            background={{ fill: "#eee" }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
