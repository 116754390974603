import { createContext } from "react";
import { CorrespondingAuthor } from "../Enums";

const SettingsContext = createContext({
  abstractMaxWords: Number,
  titleMaxCharacters: Number,
  keywordsMaxLength: Number,
  correspondingAuthorControl: Number,
  maxAssignedReviewers: Number,
  minAssignedReviewers: Number,
  approveReviewByEditor: Boolean,
  hasVolumes: Boolean,
  hasArticleTypes: Boolean,
  hasSections: Boolean,
  hasTopics: Boolean,
  hasSpecialIssues: Boolean,
});

function SettingsContextProvider({ children, settingsData }) {
  return (
    <SettingsContext.Provider
      value={{
        abstractMaxWords:
          +settingsData.find((el) => el.key == "Abstract Max Words")?.value ||
          4000,
        titleMaxCharacters:
          +settingsData.find((el) => el.key == "Title Max Characters")?.value ||
          350,
        keywordsMaxLength:
          +settingsData.find((el) => el.key == "Keywords Max Words")?.value ||
          350,
        correspondingAuthorControl:
          +settingsData.find(
            (el) => el.key == "Allow Multi Corresponding Author"
          )?.value || CorrespondingAuthor.OnlyLoggedInUser,

        maxAssignedReviewers:
          settingsData.find((el) => el.key == "Max Assigned Reviewers")
            ?.value || "",
        minAssignedReviewers:
          settingsData.find((el) => el.key == "Min Assigned Reviewers")
            ?.value || "",
        approveReviewByEditor: settingsData.find(
          (el) => el.key == "Approve Review By Editor"
        )?.isActive,
        hasVolumes: settingsData.find((el) => el.key == "Has Volumes")
          ?.isActive,
        hasArticleTypes: settingsData.find(
          (el) => el.key == "Has Article Types"
        )?.isActive,
        hasSections: settingsData.find((el) => el.key == "Has Sections")
          ?.isActive,
        hasTopics: settingsData.find((el) => el.key == "Has Topics")?.isActive,
        hasSpecialIssues: settingsData.find(
          (el) => el.key == "Has Special Issues"
        )?.isActive,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsContext };
export default SettingsContextProvider;
