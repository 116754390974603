import React from "react";
import "./Preloader.css";
function Preloader({ children, isReady, minHeight = "30vh" }) {
  return (
    <>
      {!isReady && (
        <div
          style={{ minHeight: minHeight }}
          className="d-flex align-items-center justify-content-center"
        >
          <svg className="ring" viewBox="25 25 50 50" strokeWidth="5">
            <circle cx="50" cy="50" r="20" />
          </svg>
        </div>
      )}
      {isReady && children}
    </>
  );
}
export default Preloader;
