import React, { useRef } from "react";
import { baseURL } from "../JournalSettings";

function VolatileFileInput({
  setFile,
  onChange,
  fileName,
  fileBytes,
  apiPropertyName,
  fileType,
  triggerConversionToBytes,
  fileObj,
  label,
  setHasPublishedFile,
}) {
  const handleFileDownload = (ref) => {
    if (triggerConversionToBytes) {
      const file = fileObj;
      const url = window.URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(url);
      return;
    }
    ref.current.click();
  };

  const fileDownloadRef = useRef();
  const downloadRef = useRef();

  return (
    <div>
      <div className="d-flex align-items-start flex-wrap">
        <div className="inputWrapper">
          <label htmlFor="">{label}</label>
          {fileName ? (
            <div
              className="d-flex align-items-center justify-content-between form-control"
              style={{ border: "1px solid var(--main05)" }}
            >
              <div>
                <i className="bi bi-files"></i>
                <span className="px-3">{fileName}</span>
              </div>

              <div className="d-flex align-items-center">
                {fileType && (
                  <i
                    className="bi bi-download download"
                    onClick={() => handleFileDownload(fileDownloadRef)}
                  ></i>
                )}
                {!fileType && (
                  <a
                    ref={downloadRef}
                    href={`${baseURL}/Article/DownloadArticleFile?articleFileId=${fileObj.id}`}
                    target="_blank"
                  >
                    <i className="bi bi-download download"></i>
                  </a>
                )}
                <svg
                  onClick={() => {
                    setFile((prev) => {
                      return {
                        ...prev,
                        [apiPropertyName]: "",
                      };
                    });
                    setHasPublishedFile(false);
                  }}
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="red"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
            </div>
          ) : (
            <input
              type="file"
              accept="image/"
              name={apiPropertyName}
              required
              className="form-control"
              onChange={(e) => onChange(e)}
            />
          )}
        </div>
        <div className="inputWrapper"></div>
      </div>
      <a
        ref={fileDownloadRef}
        href={`data:${fileType};base64,${fileBytes}`}
        download={fileName}
        style={{ display: "none" }}
      ></a>
    </div>
  );
}

export default VolatileFileInput;
