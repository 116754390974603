import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { GetSubmitArticleInfo } from "../../../endpoints";
import { SubmitArticleContext } from "../../../store/SubmitArticleContext";
import Preloader from "../../../UI/Preloader/Preloader";
import SubmitArtifleFileComponent from "./SubmitArtifleFileComponent";
import { ArticleFileType, ArticleStatus } from "../../../Enums";
import ViewFeedbackButton from "./ViewFeedbackButton";
import FilePondUploader from "../../../UI/FilePondUploader/FilePondUploader";

function SubmitArticleUploadArticleInformation() {
  const { articleId } = useParams();
  const navigate = useNavigate();
  const [formState, setFromState] = useState({
    manuscript: "",
    copyrightAgreement: "",
    manuscriptOldVersions: [],
    authorResponse: "",
  });
  const {
    currentForm,
    setTriggerUpdateForCurrentForm,
    isRequestFinished,
    isFileUploading,
    completedStep,
  } = useContext(SubmitArticleContext);
  const [isReady, setIsReady] = useState(false);
  const [articleStatusId, setArticleStatusId] = useState(false);
  useEffect(() => {
    setTriggerUpdateForCurrentForm(null);
  });
  useEffect(() => {
    if (!isRequestFinished) return;
    if (articleId == 0) {
      navigate("/submit-article/0/article-information");
      return;
    }
    if (completedStep < 3) {
      navigate(`/submit-article/${articleId}/article-information`);
    }
    GetSubmitArticleInfo(articleId, false, 3)
      .then((res) => {
        setFromState(res.data.uploadArticleInformation);
        setArticleStatusId(res.data.statusId);
        setIsReady(true);
      })
      .catch((res) => console.log(res));
  }, [isRequestFinished]);

  function goBack() {
    navigate(`/submit-article/${articleId}/author-information`);
  }

  function onSubmit(e) {
    e.preventDefault();
    navigate(`/submit-article/${articleId}/confirm`);
  }
  return (
    <Preloader isReady={isReady && isRequestFinished}>
      <div className="uploadArticlesDescription">
        <span>Upload your manuscript</span>
        <ul>
          <li>
            If you are submitting several files please compress them into a
            single .zip archive.
          </li>
          <li>
            The total amount of data for all files must not exceed 100 MB.
          </li>
        </ul>
      </div>
      <form ref={currentForm} onSubmit={(e) => onSubmit(e)}>
        {formState?.manuscriptOldVersions?.length > 0 && (
          <div className="uploadArticlesDescription" style={{ gap: "33px" }}>
            <span>Previous Article Versions</span>
            <ul>
              {formState?.manuscriptOldVersions.map((manuscript, i) => {
                return (
                  <li>
                    {" "}
                    <SubmitArtifleFileComponent
                      manuscript={manuscript}
                      key={i}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div>
          <label>
            Article Manuscript(Word/Zip) <span className="text-danger">*</span>
          </label>
          <FilePondUploader
            name={"file"}
            apiURL={`/Article/UploadArticleInformation`}
            label={"Upload Your Article/Manuscript File Here (Word/Zip)"}
            articleId={articleId}
            uploadedFile={formState.manuscript}
            fileType={ArticleFileType.Manuscript}
          />
        </div>
        {articleStatusId > ArticleStatus.Submitting && (
          <div>
            <label>Author's Response to Feedback</label>
            <FilePondUploader
              name={"file"}
              apiURL={`/Article/UploadArticleInformation`}
              label={"Upload Your Response To Recieved Feedback Here"}
              articleId={articleId}
              uploadedFile={formState.authorResponse}
              fileType={ArticleFileType.AuthorResponse}
              required={false}
            />
            <ViewFeedbackButton />
          </div>
        )}

        <div className="d-flex align-items-center flex-wrap gap10 justify-content-sm-between justify-content-center">
          <button
            className="mainBtn"
            id="prevBtn"
            type="button"
            onClick={(e) => goBack(e)}
          >
            {"<"} Previous
          </button>
          <button className="mainBtn" id="nextBtn" disabled={isFileUploading}>
            Next {">"}
          </button>
        </div>
      </form>
    </Preloader>
  );
}

export default SubmitArticleUploadArticleInformation;
