import React, { useState } from "react";

function Select({
  inputProps,
  label,
  onChange,
  options,
  defaultValue,
  errorMessage,
  value,
}) {
  const [focus, setFocus] = useState(false);

  return (
    <div className="inputWrapper">
      {label && (
        <label htmlFor={inputProps.id}>
          {label}
          {inputProps.required ? <span>*</span> : ""}{" "}
        </label>
      )}
      <select
        {...inputProps}
        onChange={(e) => onChange(e)}
        onBlur={() => {
          setFocus(true);
        }}
        focus={focus.toString()}
        value={value || ""}
      >
        {defaultValue && (
          <option defaultValue={""} value={""}>
            {defaultValue}
          </option>
        )}
        {options?.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      <span>{errorMessage}</span>
    </div>
  );
}

export default Select;
