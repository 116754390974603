import React, { useRef } from "react";
import { baseURL } from "../../JournalSettings";


function PlainFileComponent({file}) {
    const fileRef = useRef();
    if(!file) {
        return<></>
      }
    
  return (
    <div className="d-flex align-items-center">
        <label htmlFor="" className="mx-2">Author Response:</label>
      <a
        ref={fileRef}
        className="text-reset truncateTextToSingleLine"
        href={`${baseURL}/Article/DownloadArticleFile?articleFileId=${file.id}`}
        target="_blank"
        onClick={(e)=>e.stopPropagation()}
      >{file?.name}</a>   
      </div> );
    
}


export default PlainFileComponent