import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import "../components/Author/SubmitArticle/SubmitArticle.css";
import SubmitArticleContextProvider from "../store/SubmitArticleContext";
import SubmitArticleStepper from "../components/Author/SubmitArticle/SubmitArticleStepper";
import Protector from "../UI/Protector";
import { AuthContext } from "../store/AuthContext";
import { UserRole } from "../Enums";
import Preloader from "../UI/Preloader/Preloader";
import { CheckIfArticleIsInProgress } from "../endpoints";
import { errorToast } from "../UI/HelperFunctions/HelperFunctions";

function SubmitArticleLayout() {
  const { userObj } = useContext(AuthContext);
  const [completedStep, setCompletedStep] = useState(1);
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const { articleId } = useParams();
  useEffect(() => {
    if (articleId == 0) {
      setIsReady(true);
      setCompletedStep(1);
      return;
    }
    CheckIfArticleIsInProgress(articleId).then((res) => {
      if (!res.data.isInProgress) {
        navigate("/submit-article/0/article-information");
        errorToast("This article has already been published")
      }
      setCompletedStep(res.data.completedStep);
      setIsReady(true);
    });
  }, [articleId]);

  return (
    <Protector isAuthorized={userObj.role === UserRole.Author} requiredRole={"Author"}>
      <Preloader isReady={isReady}>
        <SubmitArticleContextProvider
          completedStepInfo={completedStep}
          articleId={articleId}
        >
          <SubmitArticleStepper />
          <div className="submitArticleForm">
            <Outlet />
          </div>
        </SubmitArticleContextProvider>
      </Preloader>
    </Protector>
  );
}

export default SubmitArticleLayout;
