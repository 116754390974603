import React, { useState } from "react";
import Input from "../Inputs/Input";
import { UserRole } from "../../Enums";
import { LoginUser } from "../../endpoints";
import { Link, useNavigate } from "react-router-dom";
import "./Auth.css";
import "react-toastify/dist/ReactToastify.css";
import {
  errorToast,
  successToast,
} from "../../UI/HelperFunctions/HelperFunctions";
import ButtonWithLoader from "../../UI/ButtonWithLoader/ButtonWithLoader";

function Login() {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    password: "",
    role: UserRole.Author,
  });
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);

  function handleChange(e) {
    setValues((prev) => {
      const name = e.target.name || e.target.title;

      let val;
      if (name == "role") {
        val = JSON.parse(e.target.value);
      } else {
        val = e.target.value;
      }
      return {
        ...prev,
        [name]: val,
      };
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsRequestBeingMade(true);
    LoginUser(values)
      .then((res) => {
        if (!res.data.success) {
          setIsRequestBeingMade(false);
          errorToast(res.data.message);
        } else {
          localStorage.setItem("accessToken", res.data.token);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          navigate("/");
        }
      })
      .catch(() => setIsRequestBeingMade(false));
  }
  return (
    <>
      <div className="w-75 authContainer card">
        <h2 className="boardHeader main800Background">Login</h2>
        <div>
          <form
            className="authForm authLogin d-flex align-items-center justify-content-center gap15 flex-column"
            onSubmit={handleSubmit}
          >
            <Input
              inputProps={{
                name: "email",
                type: "email",
                required: true,
              }}
              value={values.email}
              label={"Email"}
              onChange={handleChange}
            />
            <Input
              inputProps={{
                name: "password",
                type: "password",
                required: true,
              }}
              value={values.password}
              label={"Password"}
              onChange={handleChange}
            />

            <div className="rolesContainer">
              <p>Proceed as:</p>
              <div className="d-flex gap15 my-1 flex-wrap">
                <label className="d-flex">
                  <span>Author</span>
                  <input
                    className="me-1"
                    type="radio"
                    name="role"
                    value={UserRole.Author}
                    id="role_author"
                    checked={values.role === UserRole.Author}
                    onChange={handleChange}
                  />
                </label>
                <label className="d-flex">
                  <span>Reviewer</span>
                  <input
                    className="me-1"
                    type="radio"
                    name="role"
                    value={UserRole.Reviewer}
                    id="role_reviewer"
                    checked={values.role === UserRole.Reviewer}
                    onChange={handleChange}
                  />
                </label>
                <label className="d-flex">
                  <span>Editor</span>
                  <input
                    className="me-1"
                    type="radio"
                    name="role"
                    value={UserRole.Editor}
                    id="role_editor"
                    checked={values.role === UserRole.Editor}
                    onChange={handleChange}
                  />
                </label>
                <label className="d-flex">
                  <span>Editor-in-Chief</span>
                  <input
                    className="me-1"
                    type="radio"
                    name="role"
                    value={UserRole.EditorInChief}
                    id="role_chiefeditor"
                    checked={values.role === UserRole.EditorInChief}
                    onChange={handleChange}
                  />
                </label>
              </div>
            </div>
            <ButtonWithLoader isLoading={isRequestBeingMade}>
              Login
            </ButtonWithLoader>
            <Link
              to={"/auth/forgot-password"}
              className="text-center forgotpassword-link"
            >
              <b> Forgot Password?</b>
            </Link>
            <Link to={"/auth/register"} className="mt-1 text-center">
              Don't have an Account yet? <b>Sign up</b>{" "}
            </Link>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
