import React, { useContext, useEffect, useState } from "react";
import { GetEvaluationsList } from "../../endpoints";
import { ArticleReviewStatus, ArticleStatus, UserRole } from "../../Enums";
import Protector from "../../UI/Protector";
import { AuthContext } from "../../store/AuthContext";
import Preloader from "../../UI/Preloader/Preloader";
import CustomizableTable from "../../UI/Tables/CustomizableTable";
import { SettingsContext } from "../../store/SettingsContext";

function PendingEvaluationsRequests() {
  const { userObj } = useContext(AuthContext);
  const [isReady, setIsReady] = useState(false);
  const [tableData, setTableData] = useState(false);
  const { hasArticleTypes, hasSections } = useContext(SettingsContext);

  const inReviewArticleStatus = (status, articleStatusId) => {
    let articleRound;
    if (
      articleStatusId >= ArticleStatus.InReviewR1 &&
      articleStatusId <= ArticleStatus.SubmittedAfterR1
    ) {
      articleRound = "Round 1";
    } else if (
      articleStatusId >= ArticleStatus.InReviewR2 &&
      articleStatusId <= ArticleStatus.SubmittedAfterReviewedR2
    ) {
      articleRound = "Round 2";
    } else if (
      articleStatusId >= ArticleStatus.InReviewR1 &&
      articleStatusId <= ArticleStatus.SubmittedAfterReviewedR3
    ) {
      articleRound = "Round 3";
    }

    if (status === ArticleReviewStatus.Assigned) {
      return `Assigned ${articleRound}`;
    }
    if (status === ArticleReviewStatus.UnderReview) {
      return `Reviewing ${articleRound}`;
    }
    if (status === ArticleReviewStatus.RejectedByReviewer) return "Rejected";
    if (status === ArticleReviewStatus.Pending) return "Pending";
  };

  function getBtnText(id) {
    return "Review";
  }

  const tableHeaders = [
    { value: "Article ID" },
    { value: "Title" },
    ...(hasArticleTypes ? [{ value: "Type" }] : []),
    ...(hasSections ? [{ value: "Section" }] : []),
    { value: "Assigned Date" },
    { value: "Status" },
    { value: "Recommendation" },
  ];

  useEffect(() => {
    if (![UserRole.Reviewer, UserRole.ReviewerBoard].includes(userObj.role))
      return;
    GetEvaluationsList().then((res) => {
      const copiedArray = res.data.evaluations?.map((obj) => ({
        id: {
          type: "text",
          value: obj.article.id,
          classes: "truncateTextToSingleLine",
        },
        title: { type: "text", value: obj.article.title },
        ...(hasArticleTypes
          ? { type: { type: "text", value: obj.article.articleType } }
          : []),
        ...(hasSections
          ? { section: { type: "text", value: obj.article.journalSection } }
          : []),
        assignedDate: {
          type: "text",
          value: obj.created?.substring(0, 10),
        },
        status: {
          type: "text",
          value: inReviewArticleStatus(obj.statusId, obj.article.statusId),
        },
        reviewRanking: {
          type: "recommendation",
          rank: obj.reviewRanking,
        },
        buttonId: {
          type: "link",
          text: getBtnText(obj.statusId),
          link: `/pending-evaluations-requests/${obj.id}`,
        },
      }));
      setTableData(copiedArray?.map((obj) => Object.values(obj)));
      setIsReady(true);
    });
  }, []);

  return (
    <Protector
      isAuthorized={[UserRole.Reviewer, UserRole.ReviewerBoard].includes(
        userObj.role
      )}
    >
      <Preloader isReady={isReady}>
        <h2 className="boardHeader">Pending Evaluations Requests</h2>
        <CustomizableTable tableData={tableData} tableHeaders={tableHeaders} />
      </Preloader>
    </Protector>
  );
}

export default PendingEvaluationsRequests;
