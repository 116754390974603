import { NavLink } from "react-router-dom";
import { journalName, journalLogo } from "../../JournalSettings";
import HamburgerMenu from "../../UI/HamburgerMenu";
import { useContext, useState } from "react";
import "./Navbar.css";
import { AuthContext } from "../../store/AuthContext";
import { UserRole } from "../../Enums";

function Navbar({ isFromLayout = true }) {
  const [showMenu, setShowMenu] = useState(false);
  const { userObj, setUserObj } = useContext(AuthContext);

  function HideMenu() {
    setShowMenu(false);
  }

  function removeToken() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setUserObj({});
  }

  return (
    <div className="navHeader">
      <div className="d-flex justify-between align-items-center main800Background mainNavWrapper">
        <nav className="mainNav d-flex align-items-center">
          <div id="logo">
            {journalLogo ? (
              <NavLink to={"/"} id="navLogo">
                <img src={journalLogo}></img>
              </NavLink>
            ) : (
              <NavLink to={"/"} onClick={HideMenu} id="navLogo">
                {journalName.toUpperCase()}
              </NavLink>
            )}
          </div>

          {isFromLayout && (
            <HamburgerMenu setShowMenu={setShowMenu} showMenu={showMenu} />
          )}
          {Object.keys(userObj).length !== 0 && isFromLayout && (
            <ul
              className={`d-flex align-items-center ${showMenu && "active"}`}
              style={{ flex: 1 }}
            >
              <li className="login">
                <div className="navUserInfo" onClick={HideMenu}>
                  <span className="d-flex align-items-center">
                    <span className="userRole">
                      {userObj.role === UserRole.Editor && "Editor:"}
                      {userObj.role === UserRole.Author && "Author:"}
                      {userObj.role === UserRole.EditorInChief &&
                        "Editor-in-Chief:"}
                      {userObj.role === UserRole.Admin && "Admin: "}
                      {[UserRole.Reviewer, UserRole.ReviewerBoard].includes(
                        userObj.role
                      ) && "Reviewer:"}
                    </span>
                    {![
                      undefined,
                      null,
                      "",
                      "data:image/*;base64,",
                      "data:image/*;base64,undefined",
                    ].includes(userObj.profilePhoto) && (
                      <img
                        src={userObj.profilePhoto}
                        className="profilePhoto mx-1"
                        alt={`${userObj.firstName}'s Profile Photo`}
                      />
                    )}
                  </span>
                  <span
                    className="d-flex align-items-center"
                    style={{ gap: "3px" }}
                  >
                    {userObj.title && <span> {userObj.title}</span>}
                    <span> {userObj.firstName}</span>
                    <span> {userObj.lastName}</span>
                  </span>
                </div>
              </li>
              {userObj.role == UserRole.Author &&
                !userObj.isReviewer &&
                isFromLayout && (
                  <li className="">
                    <NavLink to={"/reviewer-application"} onClick={HideMenu}>
                      Become a Reviewer
                    </NavLink>
                  </li>
                )}

              {isFromLayout && (
                <li className="" onClick={removeToken}>
                  <NavLink to={"/auth/login"} onClick={HideMenu}>
                    Logout
                  </NavLink>
                </li>
              )}
              {/* <li>
              <NavLink to={"/auth/register"} onClick={HideMenu}>
                Change Role
              </NavLink>
            </li> */}
            </ul>
          )}
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
