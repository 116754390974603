import React, { useContext, useEffect, useState, useRef } from "react";
import Protector from "../../UI/Protector";
import { AuthContext } from "../../store/AuthContext";
import { UserRole } from "../../Enums";
import Preloader from "../../UI/Preloader/Preloader";
import Input from "../Inputs/Input";
import Textarea from "../Inputs/Textarea";
import Select from "../Inputs/Select";
import { useNavigate, useParams } from "react-router-dom";
import {
  AssignReviewerToReviewersBoard,
  DeleteReviewer,
  GetReviewerDetailsById,
} from "../../endpoints";
import {
  errorToast,
  successToast,
} from "../../UI/HelperFunctions/HelperFunctions";
import ButtonWithLoader from "../../UI/ButtonWithLoader/ButtonWithLoader";
import DeleteReviewerModal from "../Modals/DeleteReviewerModal";

const ViewReviewer = () => {
  const { userObj } = useContext(AuthContext);
  const [reviewerDetails, setReviewerDetails] = useState();
  const [isReady, setIsReady] = useState(true);
  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);
  const cvDownloadRef = useRef(null);
  const { id } = useParams();
  const [openModal, setOpenModal] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      userObj.role !== UserRole.Editor &&
      userObj.role !== UserRole.EditorInChief
    )
      return;
    GetReviewerDetailsById(id).then((res) => {
      setReviewerDetails(res.data);
      setIsReady(true);
    });
  }, []);

  const handleDelete = () => {
    setIsRequestBeingMade(true);
    DeleteReviewer(id)
      .then((res) => {
        if (res.data.success) {
          successToast(res.data.message);
          navigate("/reviewers");
          toggleModal();
        } else {
          errorToast(res.data.message);
        }
      })
      .finally(() => setIsRequestBeingMade(false));
    toggleModal();
  };

  const toggleModal = () => {
    if (openModal) {
      setOpenModal(false);
      document.body.style.overflow = "unset";
    } else {
      setOpenModal(true);
      document.body.style.overflow = "hidden";
    }
  };

  const handleAssignToReviewerBoard = (e, isAddingRole) => {
    e.preventDefault();
    AssignReviewerToReviewersBoard(id, isAddingRole).then((res) => {
      if (res.data.success) {
        successToast(res.data.message);
        setReviewerDetails((prev) => ({
          ...prev,
          partOfReviewersBoard: !prev.partOfReviewersBoard,
        }));
      } else {
        errorToast(res.data.message);
      }
    });
  };

  return (
    <Protector
      isAuthorized={
        userObj.role === UserRole.EditorInChief ||
        userObj.role === UserRole.Editor
      }
    >
      <Preloader isReady={isReady}>
        <DeleteReviewerModal
          show={openModal}
          onCancel={() => toggleModal()}
          onConfirm={handleDelete}
          name={"reviewer"}
        />
        <h2 className="boardHeader">Reviewer's Details</h2>

        <form className="editProfileForm d-flex flex-column gap15 w-100 p-2">
          <div className="d-flex align-items-start gap10 flex-wrap">
            <div className="inputWrapper">
              <Input
                key={"email"}
                inputProps={{
                  type: "email",
                  name: "email",
                  disabled: true,
                }}
                value={
                  reviewerDetails && reviewerDetails.createdByUser
                    ? reviewerDetails.createdByUser.email
                    : null
                }
                label={"Email Address"}
              />
            </div>
          </div>
          <div className="d-flex align-items-start gap10 flex-wrap">
            <Input
              key={"firstName"}
              inputProps={{
                type: "text",
                name: "firstName",
                disabled: true,
              }}
              value={
                reviewerDetails && reviewerDetails.createdByUser
                  ? reviewerDetails.createdByUser.firstName
                  : null
              }
              label={"First Name"}
            />
            <Input
              key={"middleName"}
              inputProps={{
                type: "text",
                name: "middleName",
                disabled: true,
              }}
              value={
                reviewerDetails && reviewerDetails.createdByUser
                  ? reviewerDetails.createdByUser.middleName
                  : null
              }
              label={"Middle Name"}
            />
            <Input
              key={"lastName"}
              inputProps={{
                type: "text",
                name: "lastName",
                disabled: true,
              }}
              value={
                reviewerDetails && reviewerDetails.createdByUser
                  ? reviewerDetails.createdByUser.lastName
                  : null
              }
              label={"Last Name"}
            />
          </div>
          <div className="d-flex align-items-start gap10 flex-wrap">
            <Input
              key={"title"}
              inputProps={{
                type: "text",
                name: "title",
                disabled: true,
              }}
              value={
                reviewerDetails && reviewerDetails.createdByUser
                  ? reviewerDetails.createdByUser.title
                  : null
              }
              label={"Title"}
            />
            <Input
              key={"universityOrOrganization"}
              inputProps={{
                type: "text",
                name: "universityOrOrganization",
                disabled: true,
              }}
              value={
                reviewerDetails && reviewerDetails.createdByUser
                  ? reviewerDetails.createdByUser.universityOrOrganization
                  : null
              }
              label={"University/Organization"}
            />

            <Input
              key={"department"}
              inputProps={{
                type: "text",
                name: "department",
                disabled: true,
              }}
              value={
                reviewerDetails && reviewerDetails.createdByUser
                  ? reviewerDetails.createdByUser.department
                  : null
              }
              label={"Department"}
            />
          </div>
          <div className="d-flex gap10 flex-wrap">
            <div className="inputWrapper">
              <Input
                key={"addressLine1"}
                inputProps={{
                  type: "text",
                  name: "addressLine1",
                  disabled: true,
                }}
                value={
                  reviewerDetails && reviewerDetails.createdByUser
                    ? reviewerDetails.createdByUser.addressLine1
                    : null
                }
                label={"Address Line 1"}
              />
            </div>
            <div className="inputWrapper">
              <Input
                key={"addressLine2"}
                inputProps={{
                  type: "text",
                  name: "addressLine2",
                  disabled: true,
                }}
                value={
                  reviewerDetails && reviewerDetails.createdByUser
                    ? reviewerDetails.createdByUser.addressLine2
                    : null
                }
                label={"Address Line 2"}
              />
            </div>
          </div>
          <div className="d-flex gap10 flex-wrap">
            <Select
              key={"countryId"}
              inputProps={{
                type: "text",
                title: "countryId",
                disabled: true,
              }}
              defaultValue={
                reviewerDetails && reviewerDetails.createdByUser
                  ? reviewerDetails.createdByUser.country["name"]
                  : null
              }
              label={"Country"}
            />
            <Input
              key={"city"}
              inputProps={{
                type: "text",
                name: "city",
                disabled: true,
              }}
              label={"City"}
              value={
                reviewerDetails && reviewerDetails.createdByUser
                  ? reviewerDetails.createdByUser.city
                  : null
              }
            />
            <Input
              key={"zipCode"}
              inputProps={{
                type: "number",
                name: "zipCode",
                disabled: true,
              }}
              label={"Zip Code"}
              value={
                reviewerDetails && reviewerDetails.createdByUser
                  ? reviewerDetails.createdByUser.zipCode
                  : null
              }
            />
          </div>
          <div className="d-flex gap10 flex-wrap">
            <Input
              key={"phoneNo"}
              inputProps={{
                type: "text",
                name: "phoneNo",
                disabled: true,
              }}
              value={
                reviewerDetails && reviewerDetails.createdByUser
                  ? reviewerDetails.createdByUser.phoneNo
                  : null
              }
              label={"Phone Number"}
            />
            <Input
              key={"website"}
              inputProps={{
                type: "text",
                name: "website",
                disabled: true,
              }}
              value={
                reviewerDetails && reviewerDetails.createdByUser
                  ? reviewerDetails.createdByUser.website
                  : null
              }
              label={"Website"}
            />
          </div>
          <div className="d-flex gap10 flex-wrap">
            <Textarea
              key={"biography"}
              inputProps={{
                type: "text",
                placeholder: "",
                name: "biography",
                disabled: true,
              }}
              value={
                reviewerDetails && reviewerDetails.createdByUser
                  ? reviewerDetails.createdByUser.biography
                  : null
              }
              label={"Biography"}
            />
            <Textarea
              key={"interests"}
              inputProps={{
                type: "text",
                placeholder: "",
                name: "interests",
                disabled: true,
              }}
              value={
                reviewerDetails && reviewerDetails.createdByUser
                  ? reviewerDetails.createdByUser.interests
                  : null
              }
              label={"Interests"}
            />
          </div>

          <div className="d-flex align-items-start gap10 flex-wrap">
            <div className="inputWrapper">
              <label htmlFor="">CV</label>
              <div
                className="d-flex align-items-center justify-content-between form-control"
                style={{ border: "1px solid var(--main05)" }}
              >
                {reviewerDetails?.reviewerCV ? (
                  <>
                    <div className="d-flex">
                      <i className="bi bi-files"></i>
                      <span className="px-3" style={{ color: "black" }}>
                        {reviewerDetails?.reviewerCV?.cvFileName}
                      </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <i
                        className="bi bi-download download"
                        onClick={() => cvDownloadRef.current.click()}
                      ></i>
                    </div>
                  </>
                ) : (
                  <i style={{ fontSize: "12px" }}>
                    This reviewer does not have an uploaded cv
                  </i>
                )}
              </div>
            </div>

            <div className="inputWrapper">
              <div>
                <label htmlFor="">Profile Photo</label>
                <div
                  className=" form-control"
                  style={{ border: "1px solid var(--main05)" }}
                >
                  {reviewerDetails?.createdByUser?.profilePhoto?.split(
                    ","
                  )[1] ? (
                    <img
                      src={`data:image;base64,${
                        reviewerDetails?.createdByUser.profilePhoto?.split(
                          ","
                        )[1]
                      }`}
                      alt="Profile"
                      className="profile-photo"
                      width={50}
                    />
                  ) : (
                    <i style={{ fontSize: "12px" }}>
                      This reviewer does not have a profile photo
                    </i>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex gap10 flex-wrap">
            <div className="inputWrapper">
              <Input
                key={"acceptedBy"}
                inputProps={{
                  type: "text",
                  name: "acceptedBy",
                  disabled: true,
                }}
                value={reviewerDetails && reviewerDetails.acceptedBy}
                label={"Accepted By"}
              />
            </div>
            <div className="inputWrapper">
              <Input
                key={"acceptedOn"}
                inputProps={{
                  type: "text",
                  name: "acceptedOn",
                  disabled: true,
                }}
                value={
                  reviewerDetails &&
                  reviewerDetails.acceptedOn?.substring(0, 10)
                }
                label={"Accepted On"}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end gap10 modal-footer">
            {reviewerDetails && !reviewerDetails?.partOfReviewersBoard && (
              <ButtonWithLoader
                isLoading={isRequestBeingMade}
                onClick={(e) => handleAssignToReviewerBoard(e, true)}
              >
                Assign to Reviewers Board
              </ButtonWithLoader>
            )}
            {reviewerDetails && reviewerDetails?.partOfReviewersBoard && (
              <ButtonWithLoader
                isLoading={isRequestBeingMade}
                extraClasses={["negative"]}
                onClick={(e) => handleAssignToReviewerBoard(e, false)}
              >
                Remove from Reviewers Board
              </ButtonWithLoader>
            )}
            <ButtonWithLoader
              isLoading={isRequestBeingMade}
              extraClasses={["negative"]}
              onClick={(e) => {
                e.preventDefault();
                toggleModal();
              }}
            >
              Delete Reviewer
            </ButtonWithLoader>
          </div>
        </form>

        <a
          ref={cvDownloadRef}
          href={`data:${reviewerDetails?.reviewerCV?.cvFileType};base64,${reviewerDetails?.reviewerCV?.cvFile}`}
          download={reviewerDetails?.reviewerCV?.cvFileName}
          style={{ display: "none" }}
        ></a>
      </Preloader>
    </Protector>
  );
};

export default ViewReviewer;
