import React, { useContext, useEffect, useState } from "react";
import {
  Outlet,
  redirect,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import UserMenu from "../components/SideMenus/UserMenu";
import UserRoleMenu from "../components/SideMenus/UserRoleMenu";
import Footer from "../Footer/Footer";
import { AuthContext } from "../store/AuthContext";
import Endpoints, { GetAppSettingDetails, VerifyLogin } from "../endpoints";
import Preloader from "../UI/Preloader/Preloader";
import HamburgerMenu from "../UI/HamburgerMenu";
import ExpiredTokenModal from "../components/Modals/ExpiredTokenModal";
import SettingsContextProvider from "../store/SettingsContext";

function RootLayout() {
  const userdata = useLoaderData();
  const { setUserObj } = useContext(AuthContext);
  const [isReady, setIsReady] = useState(false);
  const [showMenus, setShowMenus] = useState(false);
  const [showTokenExpiredModal, setShowTokenExpiredModal] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    setUserObj(userdata[0].data);
    setIsReady(true);
  }, []);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
    setShowMenus(false);
  }, [location]);

  const handleSessionExpiredModalConfirm = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setShowTokenExpiredModal(false);
    navigate("auth/login");
  };

  return (
    <>
      <ExpiredTokenModal
        showTokenExpiredModal={showTokenExpiredModal}
        handleClose={handleSessionExpiredModalConfirm}
      />
      <Preloader isReady={isReady} minHeight="100vh">
        <SettingsContextProvider settingsData={userdata[1].data.appSettings}>
          <Endpoints setShowTokenExpiredModal={setShowTokenExpiredModal} />
          <div className="mainWrapper">
            <Navbar />
            <main className="mainContainer card-shadow gap15">
              <div className="menus positionSticky">
                <HamburgerMenu
                  setShowMenu={setShowMenus}
                  showMenu={showMenus}
                />
                <div className={` ${!showMenus ? "active" : ""}`}>
                  <UserMenu />
                  <UserRoleMenu />
                </div>
              </div>
              <div style={{ flex: 1 }} className="card">
                <Outlet />
              </div>
            </main>
            <Footer />
          </div>
        </SettingsContextProvider>
      </Preloader>
    </>
  );
}
export default RootLayout;

export async function loader() {
  let accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    return redirect("/auth/login");
  }
  return await Promise.all([VerifyLogin(), GetAppSettingDetails()])
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return redirect("/auth/login");
    });
}
