import React from "react";
import Input from "../Inputs/Input";
import { ArticleRecommendation } from "../../Enums";

function Ranking({ onChange, reviewRank, canEdit, round }) {
  return (
    <div className="rankingContainer d-flex align-items-center mt-0 flex-wrap">
      <span className="rank">Recommendation:</span>
      <div className="d-flex flex-wrap gap15">
        <Input
          inputProps={{
            name: `reviewRank${round}`,
            type: "radio",
            id: `AcceptInPresentForm${round}`,
            checked: reviewRank === ArticleRecommendation.AcceptInPresentForm,
            disabled: canEdit,
          }}
          value={ArticleRecommendation.AcceptInPresentForm}
          onChange={(e) => onChange(e)}
          label={"Accept in Present Form"}
        />
        <Input
          inputProps={{
            name: `reviewRank${round}`,
            type: "radio",
            id: `AcceptAfterMinorRevision${round}`,
            checked:
              reviewRank === ArticleRecommendation.AcceptAfterMinorRevision,
            disabled: canEdit,
          }}
          value={ArticleRecommendation.AcceptAfterMinorRevision}
          onChange={(e) => onChange(e)}
          label={"Accept afer minor revision"}
        />
        <Input
          inputProps={{
            name: `reviewRank${round}`,
            type: "radio",
            id: `ReconsiderAfterMajorRevision${round}`,
            checked:
              reviewRank === ArticleRecommendation.ReconsiderAfterMajorRevision,
            disabled: canEdit,
          }}
          value={ArticleRecommendation.ReconsiderAfterMajorRevision}
          onChange={(e) => onChange(e)}
          label={"Reconsider after major revision"}
        />
        <Input
          inputProps={{
            name: `reviewRank${round}`,
            type: "radio",
            id: `Reject${round}`,
            checked: reviewRank === ArticleRecommendation.Reject,
            disabled: canEdit,
          }}
          value={ArticleRecommendation.Reject}
          onChange={(e) => onChange(e)}
          label={"Reject"}
        />
        {/* <Input
        inputProps={{
          
          name: `reviewRank${round}`,   
          type: "radio",
          id: `veryStrong${round}`,
          checked:reviewRank=== ArticleRecommendation.VeryStrong,
          disabled:canEdit
        }}
        value= {ArticleRecommendation.VeryStrong}
        onChange={(e) => onChange(e)}
        label={"Very Strong"}
      /> */}
      </div>
    </div>
  );
}

export default Ranking;
